﻿.CatalogExplore {
    &__Card {
        min-height: 604px;
        margin-bottom: 60px;

        &__Details {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid var(--primary);

            &__Content {
                width: max-content;
                max-width: 80%;

                &__Subtitle {
                    font-weight: 600;
                    letter-spacing: 0.131875em;
                    text-transform: uppercase;
                    margin-bottom: 0.6875em;
                    width: max-content;
                    max-width: 100%;
                }

                &__Title {
                    letter-spacing: 0.03125em;
                    margin-bottom: 0.166em;
                    width: max-content;
                    max-width: 100%;
                    line-height: 0.79em;
                }

                &__Description {
                    font-weight: 300;
                    letter-spacing: 0.07142em;
                    line-height: 1.714em;
                    margin-bottom: 2.357em;
                    width: max-content;
                    max-width: 100%;
                }

                &__Link {
                    @include font-size(12px);
                    letter-spacing: 0.1425em;
                    line-height: 2em;
                    display: inline-flex;
                    align-items: center;
                    margin-left: -0.833em;
                    text-transform: uppercase;
                    position: relative;

                    &__Icon {
                        position: relative;
                        overflow: hidden;
                        height: 38px;
                        width: 38px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &__Circle {
                            position: absolute;
                            height: 38px;
                            width: 38px;
                            border: 1px solid;
                            border-radius: 19px;
                            top: 0px;
                            left: 4px;
                        }

                        svg {
                            margin-left: 5px;
                            font-size: 18px;
                        }
                    }

                    &:active,
                    &:focus {
                        cursor: pointer;
                        text-decoration: none;
                    }

                    @media (hover: hover) {
                        &:hover,
                        &:active,
                        &:focus {
                            cursor: pointer;
                            text-decoration: none;
                            .CatalogExplore__Card__Details__Content__Link__Icon {
                                position: static;
                            }

                            .CatalogExplore__Card__Details__Content__Link__Icon__Circle {
                                width: 105%;
                                transition: width 0.3s ease-in-out;
                            }
                        }
                    }
                }
            }
        }

        &__Image {
            display: flex;
            flex-direction: column;
            justify-content: center;
            img {
                max-width: 100%;
                max-height: 100%;
                padding: 0 10px;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        &__Card {
            min-height: 500px;
        }
    }

    @include media-breakpoint-down(md) {
        &__Card {
            min-height: unset;
            max-height: unset;
            &__Details {
                margin-top: 1rem;
                padding: 60px 0px;
                text-align: center;

                &__Content {
                    &__Subtitle {
                        width: 100%;
                        text-align: center;
                        font-size: 14px;
                    }

                    &__Title {
                        width: 100%;
                        font-size: 33px;
                    }

                    &__Description {
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }
}
