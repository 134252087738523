.ProductSearchResults {
    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .ProductSearchBar__bar {
        width: 80%;
        margin: auto;
        margin-bottom: 3em;
    }

    &__products {
        .InfiniteScroll {
            animation: fade-in 0.5s ease-out;
        }

        &__info {
            display: flex;
            width: 100%;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 0px 0.3125em;

            &__pageinfo {
                width: 50%;
            }

            .ProductSearchResultsFilter {
                width: 50%;
                display: flex;
                justify-content: flex-end;
                position: relative;
                animation: fade-in 0.1s ease-out;
                button.dropdown-btn {
                    padding-right: 2em;
                    padding-left: 2em;
                }

                &__dropdown {
                    background: $light; //default dropdown background
                    position: absolute;
                    min-width: 16em;
                    top: 100%;
                    right: 0;
                    z-index: 2;

                    &__filters {
                        &.open {
                            @include branded-scroll-y($dark, var(--primary), 5px);
                        }
                        input {
                            height: 1.2em;
                            width: 1.2em;
                        }
                        label {
                            font-size: inherit;
                        }
                    }
                }
            }

            &__breadcrumb {
                width: 100%;
                margin-top: 0.5em;
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                animation: fade-in 0.3s ease-out;

                &__title {
                    display: flex;
                    align-items: center;
                    margin-right: 0.5em;
                }

                button.reset-filters {
                    padding: 0px;
                }

                .Icon {
                    margin-left: 1em;
                    font-size: inherit;
                }

                button {
                    margin-right: 0.5em;
                    margin-top: 0.5em;
                    border-radius: 2em;
                    padding-left: 1em;
                    padding-right: 1em;
                }
            }
        }
        &__product {
            > div {
                height: 100%;
            }

            img {
                object-fit: contain;
                width: 100%;
                height: 250px;
            }

            a {
                text-transform: uppercase;
                font-size: 1.1875rem;
                font-weight: 600;
                letter-spacing: 0.13157em;
                line-height: 1em;
                margin-bottom: 0.5789em;
                display: block;
            }

            p {
                margin-bottom: 0px;
                cursor: pointer;
            }
        }
        &__quickview {
            padding: 0px 20px;

            &__container {
                position: relative;
            }

            .ProductBadge {
                margin: 0;
                .badge {
                    margin: 0;
                    padding: 0;
                }
            }

            .ProductPrice {
                margin: 0;
                p {
                    font-size: inherit;
                }
            }

            &__header {
                text-transform: uppercase;
            }

            &__close {
                position: absolute;
                top: 0px;
                right: 0px;
            }

            .ProductDetailCard {
                transition: 300ms max-height;
                overflow: hidden;

                &:not(.expanded) {
                    .ProductDetailCard__name h6 {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .notify-link {
                    font-size: 0.75rem;
                    color: $light;
                    text-decoration: underline;

                    &:active,
                    &:focus {
                        text-decoration-color: var(--primary);
                    }
                }

                &__container {
                    padding-bottom: 2em;

                    &__displayonly {
                        transition: height 0.3s ease-in-out;
                        height: 0px;
                        overflow: hidden;

                        &__row {
                            display: flex;
                            align-items: flex-end;
                            &__col {
                                flex: 1 1 0;
                                font-weight: bold;
                                padding-top: 1em;
                                text-transform: uppercase;
                            }

                            &__cell {
                                flex: 1 1 0;
                                line-height: 1em;
                                span {
                                    padding: 0.25em 0;
                                }
                            }
                        }

                        &__toggle {
                            @media (hover: hover) {
                                cursor: pointer;
                            }
                            color: var(--primary);
                        }
                    }
                }

                &:not(:last-child) {
                    border-bottom: 1px solid var(--primary);
                }
                &__name {
                    padding: 2em 0;
                    position: relative;

                    h6 {
                        color: var(--primary);
                        margin: 0;
                        max-width: calc(100% - 3rem);
                    }
                    button {
                        position: absolute;
                        display: flex;
                        align-items: flex-start;
                        right: -1em;
                        top: 0;
                        bottom: 0;
                        padding: 1.5rem 1em 0 1em;
                        font-size: 0.875rem;
                        background: none;
                        border: none;
                        color: var(--primary);
                    }
                }

                &__input {
                    height: 3.5em;
                    .num-input {
                        padding-left: 0 !important;
                    }
                    .num-input + div > div:last-child {
                        padding-right: 0 !important;
                    }
                    > div,
                    > div > div {
                        max-height: 100%;
                    }
                }

                table {
                    table-layout: fixed;
                    max-width: 100%;

                    tr.clickable {
                        cursor: pointer;
                    }

                    th,
                    td {
                        line-height: 1em;
                        vertical-align: bottom;
                        span {
                            padding: 0.25em 0;
                        }
                    }
                    th {
                        font-weight: bold;
                        padding-right: 2em;
                        padding-top: 1em;
                        text-transform: uppercase;
                    }
                    td {
                        font-weight: 200;
                    }
                }
            }

            .ProductListTable {
                display: flex;
                flex-direction: row;

                table {
                    table-layout: fixed;
                    min-width: 100%;
                    thead {
                        th {
                            font-weight: bold;
                            text-transform: uppercase;
                        }
                    }
                    tbody {
                        tr {
                            height: 4.5em;
                            &:nth-child(odd) {
                                background: $dark;
                            }
                        }
                    }
                    th,
                    td {
                        vertical-align: bottom;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        padding: 0.75em 1.5em 0.75em 0.75em;
                    }
                }
                &__head {
                    flex: 1;
                    p {
                        margin: 0;
                        font-size: inherit;
                    }
                    .badge {
                        margin-top: 0;
                        font-size: inherit;
                    }
                }
                &__body {
                    tbody {
                        padding-bottom: 1em;
                    }
                    flex: 1 1 0%;
                    @include branded-scroll-x($dark, var(--primary), 0.75rem);
                }
                &__tail {
                    table tr {
                        padding-left: 1rem;
                    }

                    .notify-link {
                        font-size: inherit;
                        color: inherit;
                        text-decoration: underline;

                        &:active,
                        &:focus {
                            text-decoration-color: var(--primary);
                        }
                    }
                }
            }
        }
    }
}
