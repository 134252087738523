﻿@import '../../../../../Common/src/scss/components/react/ProductSlider.scss';

.ProductSlider {
    text-align: center;

    .CatalogCard {
        &__links {
            flex-direction: column;

            li {
                margin-left: 0px;
            }
        }
    }
}
