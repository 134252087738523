.AccordionDropdown {
    position: relative;

    .btn {
        width: 100%;
        color: inherit;
        background-color: inherit;
        text-transform: none;
        font-size: 1rem;
        padding: 0.5rem 0.7rem;
    }

    .card {
        background: none;
        color: inherit;
        width: 100%;
        position: relative;
        border-radius: 0;

        > .card-header {
            padding: 0;
            background: none;
            color: inherit;
            width: 100%;

            > .btn {
                display: flex;
                align-items: center;
                justify-content: space-between;

                > span,
                > i {
                    display: block;
                }

                &[aria-expanded='true'] {
                    > i {
                        transform: scaleY(-1);
                        -moz-transform: scaleY(-1);
                        -webkit-transform: scaleY(-1);
                        -ms-transform: scaleY(-1);
                    }
                }
            }
        }
        > .drawer {
            width: 100%;

            > .card-body {
                width: 100%;
                padding: 0.5rem 0.7rem;
                padding-top: 0;

                > .btn {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    gap: 0.33em 1em;
                    > div {
                        text-align: left;
                    }
                }
            }
        }
    }
}
