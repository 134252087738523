﻿@import '../../../../../Common/src/scss/components/react/RelatedProducts.scss';

.RelatedProducts {
    &__title {
        @include font-size(14px);
        font-family: var(--primary-font);
        font-weight: $font-weight-bolder;
        letter-spacing: 0px;
        line-height: 16px;
        text-transform: uppercase;
    }

    .ProductPrice {
        p {
            @extend .paragraph-2;
        }
    }
}
