﻿.VideoPlayer {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    &__player {
        position: absolute;
        top: 0;
        left: 0;

        .react-player__preview {
            background-repeat: no-repeat;

            i {
                font-size: 3em;
                color: $white;
            }
        }
    }
}
