.breadcrumb {
    .breadcrumb-item + .breadcrumb-item::before {
        margin: 0px 10px;
    }

    .breadcrumb-item {
        &.active {
            color: inherit;
        }

        font-family: var(--tertiary-font);
        @include font-size(zebcoToBootstrapFontSize(1.6rem));
        letter-spacing: 0.1px;

        a {
            color: var(--secondary);
            text-decoration: underline;

            &:hover {
                font-weight: bold;
                color: var(--secondary);
            }
        }
    }
}
