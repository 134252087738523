.ProductSpecsTable {
    color: var(--secondary);
    table-layout: fixed;
    width: 200px;
    margin-top: 5rem;

    th,
    td {
        width: 200px;
    }

    .product-cell,
    .add-to-cart-cell {
        cursor: pointer;
    }

    .add-to-cart-cell {
        button {
            display: none;
            margin-left: auto;
            margin-right: auto;
        }

        &.selected {
            button {
                display: block;
            }
        }
    }

    //TODO: apply a shadow to the entire column on hover
    /*
    th:not(:first-child):hover {
        box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.1);
    }
    */

    //put a border around the selected column
    th.selected {
        border-top: 2px solid $red;
    }

    td.selected,
    th.selected {
        border-left: 2px solid $red;
        border-right: 2px solid $red;
    }

    tr:last-child td.selected {
        border-bottom: 2px solid $red;
    }
}

.ProductSpecsSingle {
    color: var(--secondary);
}
