﻿@import '../../../../../Common/src/scss/components/react/CheckoutShipment.scss';

.CheckoutShipment {
    .ReadOnlyShipment {
        &__address, &__method {
            .divider {
                border-color: $black;
            }
        }
    }
}
