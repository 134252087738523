.AdvancedSubscribeForm {
    &__Form {
        display: flex;
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: center;
        align-items: center;
    }

    &__Form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 2rem 2rem 0 2rem;

        &-title {
            text-align: center;
            font-size: calc(var(--title-font-size, 1.8rem) * 8 / 10) !important;
            padding-bottom: 0.25rem;
            text-wrap: nowrap;

            @include media-breakpoint-up(md) {
                font-size: var(--title-font-size, 2rem) !important;
                padding-bottom: 0;
            }
        }

        &-body {
            margin-top: 0.5rem;
            margin-bottom: 1rem;
        }

        &__Inputs {
            &-topSection {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr;
                gap: 0.5rem;
                margin-bottom: 0.5rem;

                input {
                    width: 100%;
                }

                @include media-breakpoint-up(md) {
                    grid-template-columns: 1fr 1fr;
                }
            }

            &-bottomSection {
                width: 100%;
                display: grid;
                grid-template-columns: auto 1fr;
                gap: 0.5rem;
                margin-bottom: 0.5rem;

                label {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    font-size: 1.25rem !important;
                    margin-bottom: 0;
                }

                label.birthday-label {
                    justify-content: flex-start;
                    order: 1;

                    @include media-breakpoint-up(md) {
                        justify-content: flex-end;
                    }
                }

                .birthday-container {
                    order: 2;
                }

                span {
                    width: 100%;
                    display: flex;
                    justify-content: stretch;

                    input {
                        width: 100%;
                        text-align: center;
                        padding: 0.25rem 0.7rem !important;
                    }
                }

                .birthday-slash {
                    display: flex;
                    align-items: center;
                    font-size: 1.25rem;
                    margin-bottom: 0;
                    padding: 0 0.25rem;
                }

                .zip-label {
                    order: 3;
                }

                .zip-container {
                    order: 4;
                }

                @include media-breakpoint-up(md) {
                    grid-template-columns: 1fr 2fr 2fr 1fr;

                    .zip-label {
                        order: 4;
                    }

                    .zip-container {
                        order: 3;
                    }
                }
            }

            &-smsConsent {
                display: inline-flex;
                margin: 0.5rem 0;

                .consent-text {
                    font-size: 0.75rem;
                    font-family: var(--tertiary-font);

                    a {
                        margin-left: 0.25rem;
                        text-decoration: underline;
                        font-size: 0.75rem;
                    }
                }
            }
        }

        &__Submit {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 0.5rem;
        }
    }

    .alert-box {
        @include media-breakpoint-up(md) {
            min-height: 7.75rem;
        }

        .alert {
            margin-top: 0.5rem;
        }
    }
}
