.CatalogSimpleGrid {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    width: 100%;

    .InfiniteScroll {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &--stacked {
        .CatalogSimpleGrid__grid[data-row-size='2'] {
            grid-template-columns: 50% 50%;
            width: 100%;
            gap: 1rem;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: 0;
        grid-template-rows: auto;
        grid-auto-rows: auto;

        &__card {
            text-decoration: none !important;
            color: inherit !important;
            display: flex;
            flex-direction: row;
            align-items: stretch;
            height: auto; // var(--grid-col-size, 360px);
            min-height: 270px;
            z-index: 0;
            position: relative;
            padding: 1rem;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            &--rod.CatalogSimpleGrid__grid__card--stacked {
                .GridCard__image {
                    background-size: cover;
                    background-position: center left;
                }
            }

            &--stacked {
                flex-direction: column;

                &.CatalogSimpleGrid__grid__card--rod {
                    .GridCard__image {
                        min-height: 9rem;
                        background-size: contain;
                        background-position: center left;
                        margin-top: 1rem;

                        @include media-breakpoint-down(sm) {
                            min-height: 6rem;
                        }
                    }
                }

                &:not(.CatalogSimpleGrid__grid__card--rod) {
                    max-width: var(--grid-col-size, 360px);
                    margin: 0 auto;

                    @include media-breakpoint-down(xs) {
                        .GridCard__details {
                            padding: 0 1.5rem;
                        }
                        .GridCard__badges {
                            margin-left: 1.5rem;
                        }
                    }

                    .GridCard__image {
                        background-size: contain;
                        background-position: center;
                    }
                }

                .GridCard__image {
                    min-height: 14rem;
                    background-size: contain;
                    background-position: center;

                    &::after {
                        display: none;
                    }
                }
            }

            &:not(.GridCard--stacked) {
                .GridCard__details {
                    flex: 3 3 0;
                }
                .GridCard__image {
                    flex: 2 2 0;
                    background-size: contain;
                    background-position: center right;
                }
            }

            &--selected {
                .GridCard__details__bottom-row .btn::after {
                    z-index: 1;
                    content: '';
                    position: absolute;
                    bottom: calc(-1rem + 1px);
                    left: calc(50% - 2rem);
                    border-top: 1rem solid var(--primary);
                    border-left: 2rem solid transparent;
                    border-right: 2rem solid transparent;
                }
            }

            &__badges {
                position: absolute;
                top: 0.5rem;
                left: 0.5rem;
                z-index: 3;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 0;
                padding: 0;
                > .badge {
                    margin-top: 0 !important;
                    + .badge {
                        margin-left: 0.25rem;
                    }
                }

                @include media-breakpoint-down(md) {
                    position: relative;
                    margin-bottom: 10px;
                }
            }

            &__image {
                position: relative;
                display: block;
                background-repeat: no-repeat;
                margin-right: 0.5rem;
                width: 100%;
                z-index: 1;

                &::after {
                    position: absolute;
                    left: calc(100% - 15%);
                    top: 5%;
                    z-index: 2;
                    content: '';
                    height: 90%;
                    width: 30%;
                    background: white;
                    border-left: 0.2rem solid var(--primary);
                    transform: skew(-15deg, 0);
                }
            }

            &__details {
                position: relative;
                z-index: 3;
                display: flex;
                flex-direction: column;
                align-items: stretch;

                &__description {
                    p > em {
                        color: var(--primary);
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: none;
                    }
                    p > span + em {
                        margin-left: 0.33rem;
                    }
                }

                &__title {
                    margin-top: 0.5rem;
                    > * {
                        line-height: 1em;
                        color: var(--primary);
                        font-size: 2rem;
                    }
                }

                &__bottom-row {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    justify-content: flex-start;

                    &__price.a.b.c {
                        margin-top: 0 !important;
                        margin-right: 1em;
                    }

                    button.btn:not(.btn-read-more) {
                        position: relative;
                        bottom: -0.33em;
                    }
                }

                @include media-breakpoint-down(xs) {
                    &__bottom-row {
                        justify-content: space-between;
                    }
                }
            }
        }

        &[data-row-size='1'] {
            grid-template-columns: 100%;
            width: 100%;
            gap: 1rem;
        }

        &__quickview {
            grid-column: 1 / -1;
            margin: 0 0 0 0 !important;
            animation: 300ms openQuickview ease-in-out;
            &--hide {
                animation: 300ms closeQuickview ease-in-out;
                overflow: hidden;
            }

            @include media-breakpoint-down(lg) {
                margin: 1rem 0 0 0 !important;
            }
            @include media-breakpoint-down(sm) {
                margin: 2rem 0 0 0 !important;
            }

            &__input {
                max-width: 30rem !important;
            }
        }
    }
}

@keyframes openQuickview {
    0% {
        max-height: 0;
        overflow: hidden;
    }
    99% {
        max-height: 100vh;
    }
    100% {
        max-height: none;
        overflow: visible;
    }
}
@keyframes closeQuickview {
    0% {
        max-height: 100vh;
    }
    100% {
        max-height: 0;
    }
}

$_style: '' + var(--grid-col-size, 360px) + ' ';
@for $i from 2 through 10 {
    $_style: $_style + var(--grid-col-size, 360px) + ' ';
    .CatalogSimpleGrid__grid[data-row-size='#{$i}'] {
        grid-template-columns: #{$_style};
        gap: min(4rem, #{$i + 'rem'});
    }
}
