.content_card_learn {
    color: var(--primary);
    padding: 0px 8px;

    &__title {
        text-transform: uppercase;
        letter-spacing: 2.5px;
        font-weight: 600;
    }

    &__subtitle {
        margin-top: 23px;
        margin-bottom: 9px;
        font-weight: bold;
        letter-spacing: 1px;
    }

    &__description {
        font-weight: 300;
        letter-spacing: 1px;
    }

    img {
        width: 100%;
    }

    @include media-breakpoint-down(sm) {
        padding: 0px;

        img {
            height: auto !important;
        }
    }
}
