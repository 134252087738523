﻿@import '../../../../../Common/src/scss/components/react/CatalogCard.scss';

.CatalogCard {
    border: 1px solid $gray-light;

    &:hover {
        box-shadow: 3px 4px 4px 0 rgba(0, 0, 0, 0.3);
    }

    .subheader {
        @include media-breakpoint-down(lg) {
            font-size: 1rem;
        }

        @include font-size(19px);
        margin-top: 0.75em;
        line-height: 1em;
        color: var(--secondary) !important;
        width: 100%;
        text-align: center; // TODO: determine if this should be left or center
    }

    .divider {
        &.extra-margin {
            //extra margins for when there are no marketing badges present.
            margin-top: 32px;
        }

        @extend .mb-3;
    }

    &__flag {
        > span {
            font-weight: 900;
        }
    }

    &__prices {
        justify-content: center;
        color: var(--primary);
        flex-direction: column;
    }

    &__links {
        margin-top: auto;
        justify-content: center;

        li span {
            font-weight: bold;
        }
    }

    &__childNode {
        border: none;

        &:hover {
            box-shadow: none;
        }
    }

    .ProductBadge {
        @extend .d-flex;
        @extend .justify-content-center;
    }

    @include media-breakpoint-down(lg) {
        &__links {
            flex-direction: column;

            &__links {
                margin-left: 0px;
            }
        }
    }

    @include media-breakpoint-down(md) {
        text-align: center;

        &__links {
            flex-direction: column;

            li span {
                @include font-size(14px);
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &__links {
            li span {
                @include font-size(14px);
            }
        }
    }
}
