.VideoGallery {
    .slick-slider {
        height: 100%;
        display: flex;
        width: 100%;
        z-index: 10;

        .slick-list {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            justify-content: center;
        }

        .slick-track {
            display: flex;

            .slick-slide {
                display: flex;
                align-items: stretch;
                justify-content: center;
                flex: 1;
                height: inherit;
                min-height: 200px;
                box-sizing: border-box;
                opacity: 0.2;
                transition: opacity 0.3s;

                &.slick-active {
                    opacity: 1;
                }

                img {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                }
            }

            .slick-slide > div {
                width: 100%;
                display: flex;
                height: 100%;

                .VideoGallery__videoThumb {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-position: center;
                    background-size: cover;
                    min-height: 330px;

                    @include media-breakpoint-down(md) {
                        min-height: 200px;
                    }
                }

                i {
                    font-size: 3rem;
                    color: var(--light);
                }
            }
        }
    }
}
