﻿.RelatedProducts {
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    animation: fadeIn 0.3s ease-out;
    &__product {
        &__image {
            height: 100px;
            width: 133px;
            object-fit: contain;
        }

        .subheader {
            margin-top: 9px;
            @include font-size(16px);
            line-height: 16px;
        }

        .ProductPrice {
            p {
                font-weight: $font-weight-bolder;
            }
        }
    }
}
