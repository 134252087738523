﻿.custombuild__byline {
    font-family: var(--secondary-font);
    font-size: 19px;
    text-transform: uppercase;
}

.custombuild__title {
    font-family: var(--primary-font);
    text-transform: uppercase;
}

.custombuild__subtitle {
    font-family: var(--secondary-font);
    font-size: 19px;
    text-transform: uppercase;
}

.custombuild__steps {
}

.custombuild__tabs {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.custombuild__tabs li {
    float: left;
    border-bottom: 3px solid $gray-secondary;
}

.custombuild__tabs li span {
    display: block;
    font-size: 14px;
    color: $white;
    text-align: center;
    padding-bottom: 4px;
    padding-right: 24px;
    text-decoration: none;
    text-transform: uppercase;
}

.custombuild__tabs li.selected {
    border-bottom: 3px solid var(--primary);
}

.custombuild__tabs li.selected a {
    color: var(--primary);
}

.custombuild__tabs li.active {
    border-bottom: 3px solid var(--primary);
}

.custombuild__tabs li.active a {
    font-weight: bold;
}

.custombuild__steptitle {
    font-family: var(--primary-font);
    font-size: 30px;
}

.custombuild__stepsubtitle {
    font-family: var(--primary-font);
    font-size: 16px;
}

.custombuild__slider {
}

.custombuild__card {
    display: inline-block;
    position: relative;
    height: 195px;
    width: 338px !important;
    margin-left: 10px;
    margin-right: 10px;
}

.custombuild__card label {
    position: relative;
    height: 195px;
    width: 338px !important;
    background-color: var(--primary);
}

.custombuild__card label i {
    display: none;
    position: absolute;
    top: 5px;
    left: 5px;
    color: var(--primary);
}

.custombuild__card .custombuild__cardtitle {
    margin: 0;
    padding: 0;
    font-size: 23px;
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.custombuild__card [type='radio'] {
    /* HIDE RADIO */
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.custombuild__card [type='radio']:checked + img {
    cursor: pointer;
}

.custombuild__card [type='radio']:checked + img {
    opacity: 0.5;
}

.custombuild__card [type='radio']:checked + img + i {
    display: block;
}

.custombuild__next button {
    color: var(--primary);
}

.custombuild__reset a {
    color: var(--primary);
    font-size: 14px;
}

.custombuild__categories {
}

.custombuild__gallerycard {
    position: relative;
    height: 195px;
    width: 195px;
    margin: 10px;
}

.custombuild__gallerycard .custombuild__gallerytitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
}

.custombuild__slider .slick-dots li button:before {
    color: $white !important;
}

.custombuild__slider {
    & .slick-prev {
        left: 25px;
        top: 47%;
        z-index: 1;

        &::before {
            color: var(--primary);
            font-size: 32px;
        }
    }

    & .slick-next {
        right: 42px;
        top: 47%;

        &::before {
            color: var(--primary);
            font-size: 32px;
        }
    }

    @include media-breakpoint-down(md) {
        & .slick-prev {
            left: 5px;
            top: 45%;
        }

        & .slick-next {
            right: 17px;
            top: 45%;
        }
    }
}
