@import '../../../../../Common/src/scss/components/react/ProductSearchResult.scss';

.ProductSearchResult {
    &__bottom-row {
        &__price.a.b.c {
            p {
                font-size: 1.25rem !important;
            }
        }
    }
}
