//Agency Gothic
@font-face {
    font-family: 'AgencyGothicCT-Bold';
    src: url('/Client/Common/assets/fonts/AgencyGothicCT-Bold/AgencyGothicCT-Bold.woff2') format('woff2');
}
@font-face {
    font-family: 'AgencyGothicCT-Condensed';
    src: url('/Client/Common/assets/fonts/AgencyGothicCT-Condensed/AgencyGothicCT-Condensed.woff2') format('woff2');
}

// Campton book
@font-face {
    font-family: 'campton-book';
    font-weight: 700;
    font-style: normal;
    src: url('/Client/Common/Assets/Fonts/Campton/Campton-Bold.otf');
}
@font-face {
    font-family: 'campton-book';
    font-weight: 400;
    font-style: normal;
    src: url('/Client/Common/Assets/Fonts/Campton/Campton-Book.otf');
}
@font-face {
    font-family: 'campton-book';
    font-weight: 400;
    font-style: italic;
    src: url('/Client/Common/Assets/Fonts/Campton/Campton-Bookitalic.otf');
}
@font-face {
    font-family: 'campton-book';
    font-weight: 200;
    src: url('/Client/Common/Assets/Fonts/Campton/Campton-Light.otf');
}
@font-face {
    font-family: 'campton-book';
    font-weight: 200;
    font-style: italic;
    src: url('/Client/Common/Assets/Fonts/Campton/Campton-Lightitalic.otf');
}

// Trade Gothic
@font-face {
    font-family: 'trade-gothic';
    src: url('/Client/Common/assets/fonts/TradeGothic/trade-gothic.eot');
    src: url('/Client/Common/assets/fonts/TradeGothic/trade-gothic.eot') format('eot'),
        url('/Client/Common/assets/fonts/TradeGothic/trade-gothic.woff2') format('woff2'),
        url('/Client/Common/assets/fonts/TradeGothic/trade-gothic.woff') format('woff'),
        url('/Client/Common/assets/fonts/TradeGothic/trade-gothic.ttf') format('truetype');
}
@font-face {
    font-family: 'trade-gothic-condensed';
    src: url('/Client/Common/assets/fonts/TradeGothic/trade-gothic-condensed.eot');
    src: url('/Client/Common/assets/fonts/TradeGothic/trade-gothic-condensed.eot') format('eot'),
        url('/Client/Common/assets/fonts/TradeGothic/trade-gothic-condensed.woff2') format('woff2'),
        url('/Client/Common/assets/fonts/TradeGothic/trade-gothic-condensed.woff') format('woff'),
        url('/Client/Common/assets/fonts/TradeGothic/trade-gothic-condensed.ttf') format('truetype');
}

//Unicod
@font-face {
    font-family: 'unicod-light';
    src: url('/Client/Common/assets/fonts/unicod-light/unicod-light.woff2') format('woff2');
}
@font-face {
    font-family: 'unicod-light-cond';
    src: url('/Client/Common/assets/fonts/unicod-light-cond/unicod-light-cond.woff2') format('woff2');
}

//WinnerSans
@font-face {
    font-family: 'WinnerSans-WideBold';
    src: url('/Client/Common/assets/fonts/WinnerSans/WinnerSans-WideBold.woff2') format('woff2');
    size-adjust: 90%;
}
