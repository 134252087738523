.imageDivider {
    position: relative;

    > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }

    &--mobile {
        display: none;
    }

    @include media-breakpoint-down(sm) {
        &--mobile {
            display: block;
        }

        &--desktop {
            display: none;
        }
    }

    &--overlapTop {
        > img {
            top: 0%;
        }
    }

    &--overlapBottom {
        > img {
            top: 100%;
        }
    }

    &--overlapNeither {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > img {
            position: initial;
            transform: initial;
            top: initial;
            left: initial;
        }
    }
}
