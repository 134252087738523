﻿@import '../../../../../Common/src/scss/components/react/CheckoutSummary.scss';

.CheckoutSummary {
    background: $gray-light;
    color: $dark;

    &__Divider {
        border-color: $dark;
    }

    &__LineItems {
        .card-header {
            background-color: $gray-light;
            border-bottom: none;

            button.btn {
                color: $dark;
            }
        }
    }
}
