﻿.ColorSelector {
    position: relative;

    .btn-toolbar {
        overflow: hidden;
        margin-top: 9px;
        transition: height 0.3s ease;
    }

    button.dropdown {
        position: absolute;
        top: 0px;
        right: -20px;
        height: 36px;
        width: 36px;
        padding: 0px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2) !important;
        background: transparent;
        i {
            font-size: 13px;
            margin-left: 2px;
        }
    }

    button.selected.oos div {
        border: 2px solid $black;
    }

    .btn-group button {
        min-width: 36px;
        > div {
            height: 36px;
            width: 36px;
        }
        padding: 0px;
        margin-bottom: 5px;

        &.disabled {
            opacity: 0.5;
            &::after {
                content: ' ';
                position: absolute;
                left: 0px;
                bottom: 0px;
                width: 49px;
                border-top: 1px solid $black;
                transform-origin: 0% 0%;
                border-radius: 1px;
                transform: rotate(-45deg);
            }
        }
    }

    .btn-group button {
        margin-right: 14px;
    }

    @include media-breakpoint-down(lg) {
        .btn-group button {
            margin-right: 8px;
        }
    }

    @include media-breakpoint-down(sm) {
        .btn-group button {
            min-width: 30px;
            > div {
                height: 30px;
                width: 30px;
            }

            &.disabled::after {
                width: 41px;
            }

            &[aria-controls='dropdown-selector'] {
                height: 30px;
            }
        }

        .btn-toolbar button.dropdown {
            height: 30px;
            width: 30px;
        }

        .btn-group button {
            margin-right: 5px;
        }

        .btn-toolbar {
            padding: 0px 1.5rem;
        }
    }

    i.fa {
        &.fa-chevron-down,
        &.fa-chevron-up {
            font-size: 13px;
            margin-left: 2px;
        }

        &.fa-question {
            margin-bottom: 5px;
        }
    }

    .btn-group button[aria-controls='dropdown-selector'] {
        justify-content: center;
        display: inline-flex;
        align-items: center;
        margin-bottom: 5px;
    }
}
