﻿.advanced__subscription {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;

    p {
        font-size: 1rem;

        @include media-breakpoint-down('sm') {
            font-size: 0.875rem;
        }
    }

    button[type='submit'],
    input[type='email'],
    input[type='phone'] {
        border: none;
    }

    @include media-breakpoint-down('sm') {
        input.form-control,
        button[type='submit'] {
            font-size: 1rem;
        }

        label {
            margin-bottom: 0.5rem;
        }

        button[type='submit'] {
            padding: 0.5rem 1rem;
        }
    }
}
