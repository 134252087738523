﻿.navigation__brands {
    background: $dark;
    color: $gray-ghost;

    > ul {
        list-style-type: none;
        padding-top: .5rem;
        padding-bottom: .5rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 3rem;
        margin: 0;

        @include media-breakpoint-up(lg) {
            justify-content: flex-start;
            padding-left: 9rem;
        }

        > li {
            display: block;
            padding: 0;
            margin: 0;

            > a {
                display: block;
                opacity: .5;
                text-decoration: none !important;
                transition: opacity 350ms;
                margin: 0;
            }

            @media (hover: hover) {
                &:not(.active) > a:hover {
                    cursor: pointer;
                    opacity: 1;
                }
            }

            &.active > a {
                pointer-events: none;
                opacity: 1;
            }
        }
    }
}
