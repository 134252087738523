.CatalogExploreGrid {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    width: 100%;

    .InfiniteScroll {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &__grid {
        display: grid;
        grid-template-columns: 0;
        grid-template-rows: auto;
        grid-auto-rows: auto;

        &__card {
            text-decoration: none !important;
            color: inherit !important;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            height: var(--grid-col-size, 360px);
            z-index: 0;
            position: relative;
            border: 2px solid var(--primary);
            padding: 1rem;

            @media (hover: hover) {
                &:hover {
                    .CatalogExploreGrid__grid__card__image--primary {
                        display: none;
                    }
                    .CatalogExploreGrid__grid__card__image--secondary {
                        display: block;
                    }
                }
            }

            &--selected {
                &::after {
                    z-index: 1;
                    content: '';
                    position: absolute;
                    bottom: calc(-1rem - 1px);
                    left: calc(50% - 1.5rem);
                    border-top: 1rem solid var(--primary);
                    border-left: 2rem solid transparent;
                    border-right: 2rem solid transparent;
                }

                .CatalogExploreGrid__grid__card__bottom-row > button {
                    > .Icon {
                        margin-right: 0.33em;
                        transform: rotate(180deg);
                    }
                }
            }

            &__badges {
                position: absolute;
                top: 0.5rem;
                right: 0.5rem;
                z-index: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 0;
                padding: 0;
                > .badge {
                    margin-top: 0 !important;
                    + .badge {
                        margin-left: 0.25rem;
                    }
                }
            }

            &__image {
                flex: 1 1 0;
                position: relative;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                width: 100%;
                &--primary {
                    display: block;
                }
                &--secondary {
                    display: none;
                }
            }

            &__title {
                margin-top: 0.5rem;
                > * {
                    line-height: 1em;
                    color: var(--primary);
                    font-size: 1.5rem;
                }
            }

            &__bottom-row {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-between;

                &__price.a.b.c {
                    margin-top: 0 !important;
                }

                > button.btn {
                    padding: 0;
                    margin: 0;
                    font-size: 0.875em;
                    color: var(--primary) !important;
                    text-transform: none;
                    font-weight: thin !important;
                    > .Icon {
                        margin-right: 0.33em;
                    }
                }
            }
        }

        &[data-row-size='1'] {
            grid-template-columns: 100%;
            width: 100%;
            gap: 1rem;
        }

        &__quickview {
            grid-column: 1 / -1;
            margin: 0 0 0 0 !important;
            animation: 300ms openQuickview ease-in-out;
            &--hide {
                animation: 300ms closeQuickview ease-in-out;
                overflow: hidden;
            }

            @include media-breakpoint-down(lg) {
                margin: 1rem 0 0 0 !important;
            }
            @include media-breakpoint-down(sm) {
                margin: 2rem 0 0 0 !important;
            }

            &__input {
                max-width: 30rem !important;
            }
        }
    }
}

@keyframes openQuickview {
    0% {
        max-height: 0;
        overflow: hidden;
    }
    99% {
        max-height: 100vh;
    }
    100% {
        max-height: none;
        overflow: visible;
    }
}
@keyframes closeQuickview {
    0% {
        max-height: 100vh;
    }
    100% {
        max-height: 0;
    }
}

$_style: '' + var(--grid-col-size, 360px) + ' ';
@for $i from 2 through 10 {
    $_style: $_style + var(--grid-col-size, 360px) + ' ';
    .CatalogExploreGrid__grid[data-row-size='#{$i}'] {
        grid-template-columns: #{$_style};
        gap: min(3rem, #{$i + 'rem'});
    }
}
