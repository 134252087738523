.SearchTabbedFacet {
    .TabbedContent {
        &__tabs {
            padding-bottom: 0.5rem;
        }

        &__tab {
            font-size: 1.5rem;
            padding: 0.5rem;
            margin: 0.5rem;
            flex-shrink: 0;
            border-top: 0px;
            border-right: 0px;
            border-left: 0px;
            border-bottom: 3px solid;

            @include media-breakpoint-down(md) {
                font-size: 1.2rem;
            }
        }

        &__nav-left > .Icon,
        &__nav-right > .Icon {
            margin-bottom: 1em;
        }
    }
}
