﻿.CheckboxSelector {
    position: relative;
    label.disabled {
        opacity: 0.5;
    }

    .checkbox-group {
        overflow: hidden;
    }

    button.dropdown {
        position: absolute;
        top: 22px;
        right: -20px;
        height: 36px;
        width: 36px;
        padding: 0px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2) !important;
        background: transparent;
        i {
            font-size: 13px;
            margin-left: 2px;
        }
    }
}
