.Stack {
    display: flex;
    flex-direction: column;
    gap: 0px;

    &--direction-row {
        flex-direction: row;
    }

    &--gap {
        @for $i from 1 through 10 {
            &-#{$i} {
                gap: spacing($i);
            }
        }
    }

    &__divider {
        align-self: stretch;
        border-top: 2px solid;
        border-left: none;

        &--direction-row {
            border-top: none;
            border-left: 2px solid;
        }
    }
}
