﻿.ProductTabContent {
    background: $gray-ghost;
    @extend .width__full-vw;
    padding-top: 86px;
    padding-bottom: 91px;

    .nav-tabs,
    .nav-item,
    .nav-link {
        border: none;
    }

    li {
        &:not(:last-child) {
            margin-right: 1.5rem;
        }
        .nav-link {
            padding: 0px;
            padding-bottom: 10px;
            @extend .subheader;
            @include font-size(19);

            &.active {
                background: none;
            }
        }
    }

    .tab-content {
        .ProductDescriptionTab, .ProductFeatures {
            margin-top: 54px;
        }
        
    }

    @include media-breakpoint-down(sm) {
        .tab-content {
            margin-top: 36px;
        }

        ul.nav-tabs {
            overflow-x: scroll;
            overflow-y: hidden;
            flex-wrap: nowrap;
            height: 47px;
            display: -webkit-box;
        }
    }
}
