﻿.ProductSpecifications {
    .ProductTableMobile.Table {
        .table {
            th:not(:first-child),
            td:not(:first-child) {
                min-width: 160px;
            }

            thead th {
                vertical-align: top;

                span {
                    display: block;
                }
            }

            th:not(:first-child),
            td:not(:first-child) {
                text-align: center;
            }

            th {
                .ProductBadge {
                    font-size: 0.67rem;
                }
            }
        }
    }

    .ProductTable.Table {
        padding-top: 7rem;
        .table {
            th:not(:first-child),
            td:not(:first-child) {
                min-width: 140px;
            }

            th:not(:last-child),
            td:not(:last-child) {
                padding-right: 1em;
            }

            thead th {
                vertical-align: bottom;
            }

            th {
                white-space: nowrap;
                position: relative;
                .ProductBadge {
                    font-size: 0.8rem;
                    display: inline-block;

                    & + p {
                        display: inline-block;
                        position: relative;
                        top: 0.1em;
                    }
                }
            }
        }
        .ProductInput {
            margin-top: 0 !important;
        }
    }

    .Table {
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 1em;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px $gray-ghost;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $gray-secondary;
        }

        &::-webkit-scrollbar:vertical {
            display: none;
        }

        .table {
            .subheader {
                @include font-size(19px);
                font-weight: normal;
                letter-spacing: 2px;
            }

            tbody {
                td,
                th {
                    padding: 5px 0px;
                }

                th {
                    font-weight: 400;
                }
            }

            th {
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                z-index: 2;
            }

            th[scope='row'] {
                position: -webkit-sticky;
                position: sticky;
                left: 0;
                z-index: 3;
                vertical-align: middle;
                color: inherit;
                background-color: $gray-ghost;
                min-width: 130px;
            }

            th:not([scope='row']):first-child {
                left: 0;
                z-index: 1;
                background-color: $gray-ghost;
            }
        }
    }
}
