.PureTip {
    position: absolute;
    font-size: 0.875rem;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 5;
    &.disabled {
        pointer-events: none;
    }
    &__arrow {
        border: 0.75em solid $light;
    }
    &__content {
        position: relative;
        user-select: none;
        cursor: pointer;
        color: $dark;
        background: $light;
        padding: 0.5em .5em;
        font-family: var(--secondary-font);
        line-height: 1.1em;
        &__dismiss.SvgIcon.icons {
            transition: 250ms color;
            font-size: 0.75em;
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    &.active {
        display: flex;
    }
    &.top,
    &.bottom {
        min-width: 100%;
        max-width: 100%;
        left: 0;
        .PureTip__arrow {
            border-left-color: transparent !important;
            border-right-color: transparent !important;
        }
    }
    &.top {
        top: 0;
        transform: translateY(-100%);
        flex-direction: column-reverse;
        .PureTip__arrow {
            border-bottom-width: 0 !important;
        }
    }
    &.bottom {
        bottom: 0;
        transform: translateY(100%);
        flex-direction: column;
        .PureTip__arrow {
            border-top-width: 0 !important;
        }
    }
    &.left,
    &.right {
        top: 0;
        min-height: 100%;
        max-height: 100%;
        width: 115%;
        .PureTip__arrow {
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
        }
    }
    &.left {
        left: 0;
        transform: translateX(-100%);
        flex-direction: row-reverse;
        .PureTip__arrow {
            border-right-width: 0 !important;
        }
    }
    &.right {
        right: 0;
        transform: translateX(100%);
        flex-direction: row;
        .PureTip__arrow {
            border-left-width: 0 !important;
        }
    }

    &.danger {
        .PureTip__arrow {
            border-color: $danger;
        }
        .PureTip__content {
            background: $light;
            color: $danger;
        }
    }

    &.warning {
        .PureTip__arrow {
            border-color: $warning;
        }
        .PureTip__content {
            background: $warning;
        }
    }

    &.success {
        .PureTip__arrow {
            border-color: $success;
        }
        .PureTip__content {
            background: $success;
        }
    }

    &.info {
        .PureTip__arrow {
            border-color: $info;
        }
        .PureTip__content {
            background: $info;
        }
    }
}
