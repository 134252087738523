﻿@import '../../../../../Common/src/scss/components/react/ProductView.scss';

.ProductView {
    justify-content: center;

    .divider {
        @extend .mb-2;
    }

    .ProductTitle {
        p {
            font-size: calc(1.325rem + 0.9vw) !important;
            font-family: $special-font;
            text-transform: uppercase !important;
            color: var(--secondary);
            line-height: 44px;
            letter-spacing: -0.5px;
            font-weight: 500;
            margin-bottom: 0.5rem;
        }

        height: auto !important;
    }

    .ColorSelector,
    .RadioSelector,
    .CheckboxSelector,
    .DropdownMenuSelector {
        p {
            font-weight: $font-weight-bolder;
            @extend .paragraph-3;
        }

        .paragraph-3 {
            letter-spacing: 0.0157em;
        }
    }

    @include media-breakpoint-down(sm) {
        .ProductTitle {
            p {
                @include font-size(23px);
                letter-spacing: 0;
                line-height: 22px;
            }
        }
    }

    .ChildDetail {
        overflow-wrap: break-word !important;
        padding: 0px 15px !important;
    }
}
