﻿@import '../../../../../Common/src/scss/components/react/DetailsLinks.scss';

.DetailsLinks {
    .LinkItem,
    .ToolTip {
        @include font-size(zebcoToBootstrapFontSize(1.6rem), true);

        margin-right: unset !important;
    }

    margin-left: 1rem;

    @include media-breakpoint-down(sm) {

        margin-left: 0;
        a {
            margin-top: 15px;

            .mx-3 {
                margin-left: 0 !important;
            }
        }
    }
}
