.HeaderSearch {
    background: $light;
    position: absolute;
    width: 100%;
    z-index: 2;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 0.5rem;

    &.theme-dark {
        background: $dark;

        .HeaderSearch__drawer {
            background: $dark;
            &--link a {
                color: var(--primary);
            }
        }
    }

    &__drawer {
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
        transition: height 0.3s ease-out;
        overflow: hidden;
        background: $light;

        @include media-breakpoint-down(sm) {
            margin: 0;
        }

        .Section {
            padding: 1rem;
            @include media-breakpoint-down(md) {
                padding-left: 0px;
                padding-right: 0px;
            }

            &__content {
                @include media-breakpoint-down(sm) {
                    margin: 0 5px;
                }
            }
        }

        &--link {
            padding-top: 1rem;
            display: flex;
            justify-content: flex-end;
            padding-right: 1rem;
            padding-left: 1rem;
            margin-bottom: 1rem;

            a {
                font-size: 0.875rem;
                letter-spacing: 0.14285em;
                line-height: 1.357em;
            }
        }

        .ResultsGrid {
            border: none;
            grid-template-columns: repeat(auto-fill, minmax(180px, 180px));
            grid-auto-rows: minmax(250px, 1fr);
            justify-content: center;

            &__result {
                border: none;
            }
        }

        .SearchDrawerLinks {
            background-color: transparent;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            .Stack__divider {
                border-color: $dark;
            }

            .PanelSection {
                &__body {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                }
            }

            &__links,
            &__recents {
                &--title {
                    color: $dark;
                    width: 100%;
                    font-size: 0.85rem;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: 700;
                }

                &--link,
                &--href {
                    color: $dark;
                    font-size: 0.8rem;
                    letter-spacing: 1px;
                }
            }

            &.theme-dark {
                .Stack__divider {
                    border-color: color-mix(in srgb, var(--primary), transparent 80%);
                }

                .PanelSection {
                    &__body {
                        display: flex;
                        flex-direction: column;
                    }
                }

                .SearchDrawerLinks__links,
                .SearchDrawerLinks__recents {
                    &--title {
                        color: $light;
                        width: 100%;
                    }

                    &--link,
                    &--href {
                        color: $light;
                    }
                }
            }
        }
    }
}
