.CatalogList {
    .CatalogListCard {
        padding: 30px;
        border: 1px solid var(--primary);
        margin-bottom: 10px;
        position: relative;

        &__Image {
            img {
                width: 100%;
                height: auto;
                object-fit: contain;
                padding: 10px;
            }
            &.top {
                img {
                    height: 18.75rem;
                }
            }

            &.left,
            &.right,
            &.alt {
                img {
                    object-position: top;
                }
            }
        }

        &__Details {
            &__Title {
                display: block;
            }

            table {
                table-layout: fixed;
                max-width: 100%;

                tr.clickable {
                    cursor: pointer;
                }

                th,
                td {
                    line-height: 1em;
                    vertical-align: bottom;
                    span {
                        padding: 0.25em 0;
                    }
                }
                th {
                    font-weight: bold;
                    padding-right: 2em;
                    padding-top: 1em;
                    text-transform: uppercase;
                }
            }

            .ProductListTable {
                border: 1px solid var(--primary);
                &.Grid {
                    .Grid {
                        &__cell {
                            padding: 0.5rem 0;

                            &--header {
                                font-weight: bold;
                                text-transform: uppercase;
                                padding: 0.5rem;
                            }

                            &--sticky-left {
                                &:not(.Grid__cell--header) {
                                    .header-column {
                                        @include media-breakpoint-up(xl) {
                                            max-width: 33vw;
                                        }
                                    }
                                }
                            }

                            .gallery-btn {
                                padding: 0.33rem 0.75rem;
                                align-self: center;
                                border-radius: 10%;
                            }

                            .NotificationSubscription {
                                width: 125px;
                            }

                            .NumberInput-btn {
                                left: unset;
                                margin: 0 1rem 0 0.5rem;
                            }

                            .ProductPrice {
                                padding-right: 0.5rem;
                                align-self: center;
                            }
                        }
                    }
                }
            }

            &__Input {
                height: 3em;
                width: 50%;
                float: left;
            }
        }

        @include media-breakpoint-down(md) {
            .product-link {
                display: inline-block;
                font-size: 0.75rem;
                text-decoration: underline;

                &:active,
                &:focus {
                    text-decoration-color: var(--primary);
                }
            }

            &__Details {
                &__CatalogListDetailsCards {
                    margin-top: 1rem;
                    max-height: 300px;

                    &__Container {
                        padding: 16px 20px;
                        margin-right: 8px;
                    }

                    .ProductDetailCard {
                        &:not(:last-child) {
                            margin-bottom: 60px;
                        }

                        .ProductInput {
                            height: 3em;
                            div.num-input {
                                padding: 0px !important;
                            }

                            input {
                                padding: 0.5rem 0.7rem;
                            }

                            button {
                                padding: 0px;
                            }
                        }

                        &__container__displayonly {
                            transition: height 0.3s ease-in-out;
                            height: 0px;
                            overflow: hidden;

                            &__row {
                                display: flex;
                                align-items: flex-end;
                                &__col {
                                    flex: 1 1 0;
                                    font-weight: bold;
                                    padding-right: 2em;
                                    padding-top: 1em;
                                    text-transform: uppercase;
                                    text-align: left;
                                }

                                &__cell {
                                    flex: 1 1 0;
                                    line-height: 1em;
                                    span {
                                        padding: 0.25em 0;
                                    }
                                }
                            }

                            &__toggle {
                                @media (hover: hover) {
                                    cursor: pointer;
                                }
                                color: var(--primary);
                            }
                        }
                    }
                }

                &__Button {
                    width: 100%;
                }
            }
        }
    }
}
