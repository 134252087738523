﻿.registration__block {
    margin-top: 8%;
    margin-bottom: 8%;

    h5 {
        @include font-size(33);
        font-weight: 900;
    }

    .text__size_lg {
        font-family: var(--tertiary-font);
        letter-spacing: 0.25px;
    }

    button.btn-lg {
        @include font-size(28);
    }

    .form-check {
        padding-left: 0px;
        margin-left: 15px;

        input[type='checkbox'] {
            margin-right: 0;
        }
    }

    .form {
        padding-top: 40px;
        width: 100%;
    }

    .form-control {
        height: 46px;
    }

    &__title {
        &__border {
            background-color: #d2212f;
            height: 3px;
            width: 45px;
            user-select: none;
        }
    }

    @include media-breakpoint-down(sm) {
        p.paragraph-1 {
            @include font-size(16);
        }

        &__title {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        button.btn-lg {
            width: 100%;
        }

        .text__size_lg {
            font-size: 14px;
        }
    }
}
