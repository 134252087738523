﻿.ProductSlider {
    button.ArrowButton {
        &.left-arrow,
        &.right-arrow {
            z-index: 1;
        }

        &:before {
            content: none;
        }

        i {
            font-size: 30px;
            color: $dark;
        }

        height: 46px;
        width: 46px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25) !important;
        background-color: $white;
    }

    .slick-track {
        display: flex !important;
        margin-left: 9px;
    }

    .slick-slide {
        height: inherit !important;

        > div {
            height: 100%;
        }
    }

    .CatalogCard {
        height: 100%;

        img {
            height: 172px !important;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }

        ul {
            margin-bottom: 0px;
        }
    }

    @include media-breakpoint-up(sm) {
        .slick-slide {
            max-width: 226px !important;
        }
    }

    @include media-breakpoint-down(sm) {
        .slick-next {
            right: 0px !important;
        }

        .slick-prev {
            left: 0px !important;
        }
    }
}
