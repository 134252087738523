﻿.carousel {
    label,
    input,
    div.input-checkbox,
    div.input-radio {
        font-family: var(--secondary);
    }

    .specialtyGrid-btn {
        padding: 0.5rem 1rem 0.5rem 1rem;
    }

    .load-btn {
        max-width: 100%;

        @include media-breakpoint-up(md) {
            max-width: 25vw;
        }
    }

    .search {
        .btn {
            font-family: var(--secondary-font);
            font-size: 16px;
        }

        input.form-control {
            font-family: var(--primary-font);
            font-weight: normal;
            background-color: $white !important;
            font-size: 16px !important;
            font-weight: bold !important;
        }

        .input-group-append {
            font-size: 16px;

            .btn {
                margin: 0;
            }
        }
    }

    .title {
        font-size: 3.5rem;
        font-family: var(--primary-font);
        text-transform: uppercase;
        color: #fff;
    }

    .subtitle {
        text-transform: uppercase;
        font-family: var(--secondary-font);
        font-weight: normal;
        font-size: 2.5rem;
        padding-bottom: 10px;
        color: #fff;
    }

    button.slick-next,
    button.slick-prev {
        height: 40px;
        width: 40px;
        &::before {
            font-size: 40px;
            color: var(--primary);
        }
    }

    .slick-next {
        right: 20px;

        &::before {
            transform: rotate(0deg);
        }
    }

    .slick-dots {
        li:only-child {
            display: none;
        }
    }

    .slick-prev {
        left: 20px;
        z-index: 1;

        &::before {
            transform: rotate(0deg);
        }
    }

    .slick-slide {
        margin-right: 10px;
    }

    .slick-list {
        padding: 0 20% 0 0;

        @include media-breakpoint-up(lg) {
            padding: 0 0 0 0;
        }
    }

    &--background-dark {
        .slick-dots li button:before,
        .slick-dots li.slick-active button:before {
            color: $light;
        }

        a {
            color: $light;
        }
    }

    &--arrow-chevron {
        .slick-prev,
        .slick-next {
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;

            /* Any z-index puts this arrow above the adjacent content. */
            z-index: 1;

            /* Slick's theme sets height to 20x20.
            For some reason, FontAwesome's font is double this.
            So we center it by shifting up by 10px, since the
            text is top-aligned. */
            margin-top: -10px;

            &::before {
                font-family: 'Font Awesome 5 Free';
                color: $dark;
            }
        }

        .slick-prev {
            &::before {
                /* fa-chevron-left */
                content: '\f053';
            }
        }

        .slick-next {
            &::before {
                /* fa-chevron-right */
                content: '\f054';
            }
        }
    }

    &--arrow-color {
        &-primary {
            .slick-next:before,
            .slick-prev:before {
                color: var(--primary);
            }
        }

        &-secondary {
            .slick-next:before,
            .slick-prev:before {
                color: var(--secondary);
            }
        }

        &-tertiary {
            .slick-next:before,
            .slick-prev:before {
                color: var(--tertiary) !important;
            }
        }

        &-dark {
            .slick-next:before,
            .slick-prev:before {
                color: $dark;
            }
        }

        &-light {
            .slick-next:before,
            .slick-prev:before {
                color: $light;
            }
        }
    }

    .card {
        position: relative;
        border: 0;
        border-radius: 0px;
        background-color: var(--primary);
        margin-left: 15px;
        margin-right: 15px;

        h5 {
            letter-spacing: 0px;
            line-height: 28px;
            word-spacing: 9999px;
            font-size: 1rem;

            @include media-breakpoint-up(sm) {
                font-size: 1.3rem;
            }

            @include media-breakpoint-up(md) {
                font-size: 1.2rem;
            }

            @include media-breakpoint-up(lg) {
                font-size: 1.3rem;
            }
            @include media-breakpoint-up(xl) {
                font-size: 1.5rem;
            }
        }

        .carousel__header-subtitle {
            font-size: 19px;
        }

        .card-img-top {
            border-radius: 0px;
        }

        @include media-breakpoint-down(sm) {
            margin-left: 5px;
            margin-right: 5px;
        }

        @media (hover: hover) {
            &:hover {
                .card-img-top {
                    opacity: 0.6;
                    transition: 0.5s ease;
                    backface-visibility: hidden;
                }

                .subheader,
                h5 {
                    color: $black !important;
                }
            }
        }

        border: 0;
        background-color: $black;
        margin-left: 5px;
        margin-right: 5px;
        /*height: 278px;*/
        .card-front-text {
            padding: 0;
            background-color: transparent;
            border-top: none;
            margin-top: 21%;
            overflow: hidden;
            height: 100%;
        }

        .title {
            font-size: 3rem;
            font-family: var(--primary-font);
            text-transform: uppercase;
            color: #fff;
            letter-spacing: 2px;
            text-align: center !important;
        }

        .subtitle {
            color: $white;
            font-family: var(--secondary-font);
            font-size: 1.5rem;
            letter-spacing: 2px;
            padding: 10px 0;
            text-align: center !important;
            text-transform: uppercase;
            word-spacing: normal;
        }

        .subtitle img {
            max-width: 60%;
            margin: 0 auto;
            display: block;
        }

        .red-devider {
            margin: 10px auto;
            width: 4rem;
            height: 3px;
            background-color: var(--primary);
        }

        img {
            overflow: hidden;
            max-height: 278px;
            margin: 0 auto;
        }
    }

    .card.card__footer-link {
        position: absolute;
        bottom: 0px;
        padding: 0 1.25rem 1.25rem 1.25rem;
        background-color: transparent;
        border-top: none;
        font-family: var(--primary-font);
        text-transform: uppercase;
        font-weight: normal;

        .card__action-open {
            text-transform: uppercase;
            font-family: var(--secondary-font);
            font-weight: normal;
            font-size: 14px;
            padding-top: 14px;
        }

        .social i {
            font-size: 23px;
        }
    }

    .card.card__back {
        /*z-index: 1;*/
        opacity: 0;
        background: $black;

        .card-header {
            padding: 0;
        }

        .card__text {
            color: $gray-600;
            font-size: 14px;
            font-weight: normal;
            line-height: 19px;
            padding-right: 10px;
            overflow: hidden;
            padding-bottom: 10px;
            max-height: 255px;
        }

        .card__action-close i {
            font-size: 26px;
            cursor: pointer;
            color: $gray-600;
        }
    }

    /* Adds a semi-transparent preview of next/prev items in carousel */
    &--overhang {
        padding-left: 65px;
        padding-right: 65px;

        .slick-prev,
        .slick-next {
            /* Any z-index puts this arrow above the adjacent content. */
            z-index: 1;
        }

        .slick-prev {
            left: -60px !important;
        }

        .slick-next {
            right: -40px !important;
        }

        .slick-list {
            margin-left: -65px;
            padding-left: 65px;
            margin-right: -65px;
            padding-right: 65px;
        }

        .slick-slide {
            transition: filter 300ms, -moz-filter 300ms, -webkit-filter 300ms, opacity 300ms;
            opacity: 1;

            /* This selector identifies "hidden" carousel items. */
            &[aria-hidden='true'] {
                transition: filter 300ms, -moz-filter 300ms, -webkit-filter 300ms, opacity 300ms;
                filter: grayscale(100%);
                opacity: 0.3;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .card {
            .title {
                font-size: 1.5rem !important;
            }

            .subtitle {
                font-size: 0.7rem !important;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .card {
            .title {
                font-size: 1.5rem !important;
            }

            .subtitle {
                font-size: 1rem !important;
            }
        }
    }

    @media only screen and (max-width: 992px) {
        .card {
            .title {
                font-size: 2rem !important;
            }

            .subtitle {
                font-size: 1rem !important;
            }
        }
    }

    .carousel__header {
        position: relative;
        margin-left: 5px;
        font-family: var(--secondary-font);
        @include font-size(23px);
        line-height: 19px;
        letter-spacing: 2px;
        text-transform: uppercase;

        &--style2 {
            > h3::after {
                position: absolute;
                content: '';
                border-bottom: 3px solid var(--primary);
                left: 0;
                top: 100%;
                width: 1em;
            }
        }

        &--style3 {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            h3 {
                font-size: 1.6rem !important;
                margin-right: 0;
            }

            @include media-breakpoint-up(md) {
                flex-wrap: wrap;
                align-items: flex-end;
                flex-direction: row;

                h3 {
                    margin-right: 2rem !important;
                }
            }

            .carousel__header-subtitle {
                text-transform: initial;
                font-size: 1.2rem !important;
                padding-bottom: 0.13rem;
            }

            i.fas {
                font-size: 1rem;
            }
        }
    }

    .carousel__card {
        min-height: 280px;
        position: relative;
        cursor: pointer;

        > * {
            z-index: 2;
        }

        > a {
            text-decoration: none;
        }

        @include media-breakpoint-up(md) {
            min-height: 330px;
        }

        .card-img-top {
            filter: grayscale(1);
        }

        .card-date {
            font-size: 1rem;
            font-family: var(--primary-font);
            text-transform: uppercase;
        }

        .specialty-link {
            font-size: 1.5rem;
            font-family: var(--primary-font);
            text-transform: uppercase;
        }

        .card-title {
            line-height: 0.93em;
        }

        .carousel__card-background {
            transition: opacity 200ms;
            z-index: 1;
            background-position: center;
            background-size: cover;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        .carousel__card-link--disabled {
            display: inline-block; /* needed for pointer-events/touch-action to work */
            pointer-events: none; /* disable clicking on desktop */
            touch-action: none; /* disable touch events on mobile/tablet */
        }

        @media (pointer: fine) {
            &:hover .carousel__card-background {
                opacity: 0.4;
            }
        }
    }

    .carousel__card {
        @media (hover: hover) {
            &:hover {
                background-color: var(--primary);
                opacity: 1;
            }
        }
    }

    .carousel__card.carousel__card--style1 {
        flex-direction: column;

        > a {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            flex: 1 0 0%;
            padding: 1.25rem;
        }
    }

    .carousel__card.carousel__card--style3 {
        justify-content: center;

        @media (hover: hover) {
            &:hover .carousel__card-background {
                opacity: 1;
            }
        }

        > a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1 0 0%;
            padding: 1.25rem;
        }

        .card-title {
            color: $white;
            letter-spacing: -0.63px;
        }

        .subtitle img {
            /*display: inline;*/
            width: auto;
            height: auto;
        }

        .card-img-overlay {
            padding: 10px;
        }
    }

    .carousel__card.carousel__card--style4 {
        background-size: cover;
        background-position: center;

        @media (hover: hover) {
            &:hover {
                background-blend-mode: multiply;
            }
        }

        a {
            min-height: 280px;

            @media (hover: hover) {
                &:hover {
                    text-decoration: none;
                }
            }
        }

        .header {
            padding: 10px;
            background: linear-gradient(to bottom, #000000bb 0%, #00000000 100%);
        }

        /* Hide icon on hidden carousel cards. */
        .carousel__icon {
            display: none;
        }

        /* Show icon on visible carousel cards. */
        &.slick-active .carousel__icon {
            display: block;
        }
    }

    .carousel__card.carousel__card--style5 {
        background-size: cover;
        background-position: center;
        min-height: 150px;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        @media (hover: hover) {
            &:hover {
                background-blend-mode: multiply;
            }
        }

        a {
            flex: 1 0 0%;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: stretch;

            @media (hover: hover) {
                &:hover {
                    text-decoration: none;
                }
            }
        }

        .header {
            flex: 1 0 0%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            h5 {
                font-weight: normal;
                margin: 0;
            }

            h2 {
                line-height: 1em;
                margin: 0;
            }
        }

        /* Hide icon on hidden carousel cards. */
        .carousel__icon {
            display: none;
        }

        /* Show icon on visible carousel cards. */
        &.slick-active .carousel__icon {
            display: block;
        }
    }

    .carousel__icon {
        color: $white;
        font-size: 1.8em;
        margin-right: 1em;
    }

    .carousel__card-title {
        color: $white;
        flex: 1 0 0%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 0.5rem;

        h3 {
            margin: 0;
            font-size: 2rem;
        }

        h4 {
            font-size: 1.2rem;
            font-weight: 100;
        }

        @media only screen and (max-width: 768px) {
            h3 {
                font-size: 1.5rem;
                font-weight: bold;
            }
        }
    }

    .carousel__cardrow {
        padding-top: 90px;
        padding-bottom: 90px;

        .slick-next:before,
        .slick-prev:before {
            font-size: 40px;
        }

        .slick-prev {
            left: -50px;
        }

        button.fa.fa-chevron-left,
        button.fa.fa-chevron-right {
            line-height: 0;
            position: absolute;
            top: 50%;
            display: block;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            background-color: transparent;
            color: var(--primary);
            border: none;
            font-size: 23px;
            z-index: 1;
        }

        button.fa.fa-chevron-right {
            right: -25px;
        }

        button.fa.fa-chevron-left {
            left: -25px;
        }

        @include media-breakpoint-down(sm) {
            button.fa.fa-chevron-right {
                right: -4px;
            }

            button.fa.fa-chevron-left {
                left: -4px;
            }
        }
    }

    &--grid {
        .carousel__header {
            margin-left: $grid-gutter-width * 0.5 + 5px;
        }

        .slick-slide > div + div {
            margin-top: $grid-gutter-width + 10px;
        }
    }

    &__container {
        flex-wrap: nowrap !important;
    }
}

.carousel__card.carousel__card--videoGrid {
    background-size: cover;
    background-position: center;

    @media (hover: hover) {
        &:hover {
            background-blend-mode: multiply;
        }
    }

    a {
        min-height: 280px;
        max-width: 530px;

        @media (hover: hover) {
            &:hover {
                text-decoration: none;
            }
        }
    }

    .header {
        padding: 10px;
        background: linear-gradient(to bottom, #000000bb 0%, #00000000 100%);
    }

    /* Hide icon on hidden carousel cards. */
    .carousel__icon {
        display: none;
    }

    /* Show icon on visible carousel cards. */
    &.slick-active .carousel__icon {
        display: block;
    }
}

.carousel__hero {
    .carousel__hero__button-prev,
    .carousel__hero__button-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: $white;
        color: var(--primary);
        opacity: 0.75;
        border-radius: 50%;
        font-size: 24px;
        width: 42px;
        height: 42px;
        text-align: center;
        border: none;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .carousel__hero__button-prev {
        left: 50px;
        content: '<';
    }

    .carousel__hero__button-next {
        right: 50px;
        content: '>';
    }
}
