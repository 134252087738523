.ProductCompare {
    &__table {
        thead {
            border-bottom: 1px solid $gray-medium;

            th {
                border-top: none;
            }
        }

        td,
        th {
            padding: 0.5rem 1rem;
            width: 200px;

            .btn {
                white-space: nowrap;
            }

            .ProductCompare__table__product-link {
                color: $dark;

                @media (hover: hover) {
                    &:hover {
                        text-decoration: none;
                    }
                }

                @include media-breakpoint-down(sm) {
                    font-size: .8rem;
                }
            }
        }

        th:not(:first-child) {
            min-width: 200px;
        }

        tr {
            td:first-child,
            th:first-child {
                background-color: $gray-light;
                border-right: 1px solid $gray-medium;
            }

            th,
            td {
                border-top: none;
            }

            &:nth-child(2n) {
                td {
                    background-color: $gray-ghost;
                }
            }
        }
    }

    .ProductCompare__table__close {
        position: absolute;
        right: 0;
        top: 0;
    }

    
    @include media-breakpoint-down(sm) {
        font-size: .8rem;
    }
}
