﻿@import '../../../Common/src/scss/pages/_catalog_product.scss';

.catalog__product {
    color: var(--secondary);

    .tab-content {
        .tab-pane {
            padding-top: 15px;
        }
    }

    .yotpo {
        .yotpo-label-container {
            border-top: none !important;
        }
    }
}

    #product-tabs {
        border-bottom: 1px solid $gray-medium;
        padding-bottom: 3px;

        .product-tab-list {
            list-style-type: none;
            @include font-size(14px);

            li {
                display: inline;

                button {
                    border: none;
                    background-color: transparent;
                    font-weight: bold;
                    color: var(--secondary);
                    width: 100%;

                    &:not(.collapsed) {
                        border-bottom: 2px solid var(--secondary);
                    }
                }

                a {
                    color: $gray-secondary;
                    border-radius: 0;

                    &.active {
                        color: $gray-primary;
                        border-bottom: 2px solid $gray-primary;
                    }

                    font-weight: bold;

                    &.active {
                        background-color: transparent;
                    }
                }
            }
        }
    }
