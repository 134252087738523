.VideoPlayerButton {
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 4em;
    color: inherit;

    background-color: $dark;
    border-radius: 100px;

    height: 5rem;
    width: 5rem;
    border: 1px solid $dark;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    &--primary {
        border-color: var(--primary);
    }

    &--secondary {
        border-color: var(--secondary);
    }

    &--dark {
        border-color: $dark;
    }

    &--light {
        border-color: $light;
    }

    > svg {
        margin-left: 0.2rem;
        margin-right: -0.2rem;
    }
}
