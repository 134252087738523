﻿.prostaff {
    &__cardrow {
        padding-top: 90px;
        padding-bottom: 90px;

        .slick-next:before,
        .slick-prev:before {
            font-size: 40px;
            color: var(--primary);
        }

        .slick-prev {
            left: -50px;
        }

        a {
            font-family: var(--tertiary-font);
            @include font-size(16);
            font-weight: bold;
            letter-spacing: 0.25px;
        }

        h6,
        a {
            margin-left: 15px;
        }

        button.fa.fa-chevron-left,
        button.fa.fa-chevron-right {
            line-height: 0;
            position: absolute;
            top: 50%;
            display: block;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            background-color: transparent;
            color: var(--primary);
            border: none;
            font-size: 23px;
            z-index: 1;
        }

        button.fa.fa-chevron-right {
            right: -25px;
        }

        button.fa.fa-chevron-left {
            left: -25px;
        }

        .card__front {
            background-position: top;
            background-size: cover;
        }

        @include media-breakpoint-down(sm) {
            button.fa.fa-chevron-right {
                right: -4px;
            }

            button.fa.fa-chevron-left {
                left: -4px;
            }
        }

        .card {
            border: 0;
            background-color: $black;
            margin-left: 15px;
            margin-right: 15px;
            height: 330px;

            h3 {
                letter-spacing: -0.5px;
                line-height: 0.88em;
                margin-bottom: 0px;
                font-size: 1.5rem;

                @include media-breakpoint-up(sm) {
                    font-size: 2rem;
                }

                @include media-breakpoint-up(md) {
                    font-size: 1.8rem;
                }

                @include media-breakpoint-up(lg) {
                    font-size: 2.2rem;
                }
            }

            .subheader {
                @include font-size(19);
            }

            .bio {
                color: $gray-600;
                font-size: 14px;
                font-weight: normal;
                line-height: 19px;
                padding-right: 10px;
                overflow: hidden;
                padding-bottom: 10px;
                max-height: 280px;
            }

            .card__back {
                opacity: 0;
                background: $black;
            }

            @media (hover: hover) {
                &:hover {
                    .card__front {
                        opacity: 0;
                    }

                    .card__back {
                        opacity: 1;
                    }
                }
            }
        }

        .background__color-primary {
            .slick-next:before,
            .slick-prev:before {
                color: var(--secondary);
            }
        }
    }

    &__cardgrid {
        padding-top: 90px;
        padding-bottom: 90px;

        .dropdown__drawer .list-group-item {
            flex: none !important;
        }

        .search {
            @include media-breakpoint-down(sm) {
                width: 100%;
            }

            i {
                top: 18%;
                left: 25px;
                position: absolute;
            }

            .btn {
                font-size: 16px;
            }

            input.form-control[type='text'] {
                font-weight: normal;
                background-color: #fff !important;
                font-size: 16px !important;
                font-weight: bold !important;
                border-radius: 0px;
                padding-left: 35px;
            }
        }

        .filter .dropdown-menu {
            min-width: 200px;
        }

        .filter,
        .sort {
            .dropdown-menu {
                max-height: 300px;
                overflow: auto;
                transform: translate3d(0px, 35px, 0px) !important;
                padding: 0px;
                border-radius: 0px;
                background-color: $black;
                color: $white;

                li {
                    background-color: inherit;
                    border-radius: 0px;
                }

                input[type='checkbox'] {
                    background: transparent;
                    border: 2px solid $light;
                    height: 20px;
                    min-height: 20px;
                    width: 20px;
                    min-width: 20px;
                    &.light {
                        border-color: $light;
                    }

                    &.dark {
                        border-color: $dark;
                    }

                    &::before {
                        background: var(--primary);
                        left: calc(50% - 4.1px);
                        top: calc(50% - 3.9px);
                    }

                    &:checked,
                    &.checked {
                        background: transparent;
                    }
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
        }

        .sort,
        .filter {
            button {
                background-color: $gray-ghost;
                height: 30px;
                width: auto;
                align-items: center;
                padding: 0px 1rem 0px 1rem;
                &[aria-expanded='true'] {
                    i.fa-chevron-down {
                        transform: scaley(-1);
                    }
                }
                @include media-breakpoint-down(sm) {
                    padding: 0px !important;
                    width: 100%;
                    justify-content: center;
                }
            }
        }

        .sort button {
            padding: 0px 2rem 0px 2rem;
        }

        @include media-breakpoint-down(sm) {
            .filter.border-mobile button {
                border-right: 2px solid $black;
            }
        }

        .filter-by {
            margin-bottom: 30px;

            .btn {
                margin-right: 10px;
            }

            label {
                font-weight: normal;
                font-size: 14px;
                margin-top: 0;
                cursor: pointer;
            }

            label i {
                display: inline !important;
                padding-right: 9px;
            }
        }

        .filters-reset {
            font-weight: bold;
            font-size: 14px;
            cursor: pointer;
        }

        .title {
            text-transform: uppercase;
            font-size: 62px;
            line-height: 62px;
            color: $black;
        }

        .subtitle {
            text-transform: uppercase;
            font-family: var(--secondary-font);
            font-weight: normal;
            font-size: 14px;
            padding-bottom: 10px;
        }

        .slick-next:before,
        .slick-prev:before {
            font-size: 40px;
            color: var(--primary);
        }

        .slick-prev {
            left: -50px;
        }

        .prostaff-card {
            min-height: 250px;
        }

        .card {
            min-height: 100%;
            border: 0;
            background-color: $black;
            margin-left: 15px;
            margin-right: 15px;

            .card__footer-link {
                background-color: transparent;
                border-top: none;

                .card__action-open {
                    text-transform: uppercase;
                    font-family: var(--secondary-font);
                    font-weight: normal;
                    font-size: 14px;
                    cursor: pointer;
                    padding-top: 14px;
                }
            }

            .title {
                font-weight: 900;
                font-size: 30px;
                letter-spacing: 0px;
                line-height: 28px;
                word-spacing: 9999px;
            }

            .subtitle {
                font-size: 14px;
                letter-spacing: 2px;
                line-height: 19px;
            }
            .social {
                padding-bottom: 1rem;
                position: absolute;
                bottom: 0px;
                right: 0px;
                i {
                    font-size: 23px;
                }
            }

            .card__back {
                opacity: 0;
                background: $black;

                .card-header {
                    padding: 0;
                }

                .card__text {
                    color: $gray-600;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 19px;
                    padding-right: 10px;
                    overflow: hidden;
                    padding-bottom: 10px;
                    max-height: 255px;
                }

                .card__action-close i {
                    font-size: 26px;
                    cursor: pointer;
                    color: $gray-600;
                }
            }

            @media (hover: hover) {
                &.card__flip {
                    .card__front {
                        opacity: 0;
                        z-index: 1;
                    }

                    .card__back {
                        opacity: 1;
                        z-index: 2;
                    }
                }

                &:hover {
                    .card__front {
                        opacity: 0;
                    }

                    .card__back {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
