﻿.rmarequestformblock {
    padding-top: 40px;
    padding-bottom: 40px;
}

.RMARequest {
    .divider {
        height: 2px;
        background: $black;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    &__address {
        &__shipment {
            margin-bottom: 1rem;

            p {
                @extend .paragraph-2;
                margin-bottom: 0px;
            }
        }
    }

    .CartTable {
        margin-bottom: 0px;

        .table {
            .tablecell.quantity,
            .tablecell.reason {
                padding: 0px;
                text-align: center;
                float: left;
            }

            .tablecell.reason {
                .ReasonCodeDropdown {
                    button {
                        width: 100%;
                        padding: 5px;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 2px solid $black;

                        > p.text {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 80%;
                            display: inline-block;
                            text-transform: none;
                        }

                        i.fa-chevron-down {
                            font-size: 15px;
                        }
                    }

                    &.show {
                        button {
                            i.fa-chevron-down {
                                transform: scaley(-1);
                            }
                        }
                    }

                    > .dropdown-menu {
                        max-height: 300px;
                        overflow-y: auto;
                        border-radius: 0px;

                        .reason {
                            cursor: pointer;
                            text-align: left;
                            padding: 5px;
                            border-bottom: 1px solid $gray-ghost;

                            &.selected {
                                text-decoration: underline;
                                font-weight: $font-weight-bold;
                            }
                        }
                    }
                }
            }

            .tablecell.price {
                text-align: center;
            }

            .image {
                .overlay {
                    opacity: 0.5;
                    border: 1px solid lightgray;
                }

                .overlay-text {
                    position: absolute;
                    text-transform: uppercase;
                    font-weight: $font-weight-bolder !important;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                }
            }
        }
    }

    .PaymentPreview,
    .ReadOnlyShipment {
        p {
            @extend .paragraph-2;
            margin-bottom: 0px;
        }
    }

    .customer-notes {
        @extend .mt-3;
        width: 100%;

        .count {
            float: right;
            margin-bottom: 5px;
        }

        textarea.note {
            padding: 10px 20px;
            @extend .paragraph-3;
            border-radius: 0px;
            width: 100%;
            resize: none;
            font-family: var(--tertiary-font);
            border: 2px solid $black;
        }
    }
}
