﻿@import '../../../../../Common/src/scss/components/react/MobileCatalogFilters.scss';

$filter-bg-color: $light !default;
$filter-fg-color: $light !default;
$filter-font-family: var(--secondary-font) !default;
$filter-font-size: 15px;

.Mobile.CatalogFilters {
    display: flex;
    margin-bottom: 1rem;
    padding: 0;

    .CatalogFilters__btnFilterMenu {
        display: flex;
        align-items: center;
        padding-bottom: 0.75rem;
        font-family: $filter-font-family;
        font-size: $filter-font-size;
        font-weight: unset;
    }

    .divider {
        min-width: 0.1em;
        padding: 0 1px;
    }

    .FilterDropdown {
        position: relative;
        background: transparent;
        max-height: 100%;
        overflow: visible;

        > button {
            white-space: nowrap;
            padding: 0.5em 1em;
        }

        button > i {
            margin-left: 0.5em;
        }

        &.expanded {
            > button {
                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);

                > i {
                    color: black;
                }

                > span {
                    color: var(--primary);
                }
            }

            > &__button-list {
                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
            }
        }

        &__button-list {
            position: absolute;
            top: 2.25rem;
            z-index: 2;
            background: $filter-bg-color;
            border: 1px solid $dark;
            min-width: 100%;

            label:first-child {
                margin-top: 0.5em;
            }

            label {
                margin: 1em 0;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 1em;

                &:not(.disabled) {
                    cursor: pointer;
                }

                > span {
                    color: $filter-fg-color;
                    white-space: nowrap;
                    user-select: none;
                    font-family: $filter-font-family;
                    @include font-size($filter-font-size);
                }

                > input[type='checkbox'],
                > input[type='radio'] {
                    margin: 0 1em;
                    background: $filter-bg-color;
                    border-color: $filter-fg-color;
                    height: 18px;
                    min-height: 18px;
                    width: 18px;
                    min-width: 18px;

                    &:checked::before {
                        background: $filter-fg-color;
                        height: 6px;
                        width: 6px;
                        left: calc(50% - 3px);
                        top: calc(50% - 3px);
                    }
                }
            }

            button {
                &:last-child {
                    margin-bottom: 0.5em;
                }

                > span {
                    white-space: nowrap;
                    user-select: none;
                    text-transform: none;
                    font-family: $filter-font-family;
                    @include font-size($filter-font-size);
                }

                &.selected > span {
                    text-decoration: underline;
                }
            }
        }

        .btn > span {
            font-size: $filter-font-size;
            font-weight: unset;
        }
    }
}
