﻿@import '../../../../../Common/src/scss/components/react/ProductIncludes.scss';

.ProductIncludesWrapper {
    left: 0;
}

.ProductIncludes {
    thead {
        border-bottom: 2px solid $gray-dark;
    }

    .thumbnail {
        width: 82px;
        height: 49px;
        object-fit: contain;
    }
}
