﻿.AccordionTable {
    .table {
        thead {
            border-bottom: 2px solid var(--primary);

            th {
                padding-left: 0px;
                padding-right: 0px;
            }
        }

        th {
            border: none;
        }

        td {
            padding-top: 2px;
            padding-bottom: 2px;
            vertical-align: middle;
            border: none;
            padding-left: 0px;
            padding-right: 30px;
        }

        tr.card-header {
            border-bottom: 1px solid $black;
        }
    }

    .accordion.light,
    .card-header {
        background: transparent;
        border: none;

        button[aria-expanded='true'] {
            i {
                transform: scaleY(-1);
                -moz-transform: scaleY(-1);
                -webkit-transform: scaleY(-1);
                -ms-transform: scaleY(-1);
            }
        }
    }

    .accordion.light {
        .collapse,
        .collapsing {
            border-bottom: 1px solid $black;
        }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
        width: 32px;
        height: 32px;
        padding: 0px;
        text-align: center;
    }

    img.thumbnail {
        width: 82px;
        height: 49px;
        object-fit: contain;
    }

    input {
        width: 44px;
        text-align: center;
    }
}
