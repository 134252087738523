﻿.CatalogGrid {
    margin-top: 2em;

    .row > .col:not(.CatalogQuickView) {
        z-index: 1;
    }

    .row > .col.CatalogQuickView {
        z-index: 0;
    }

    &__load-more-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2em;

        > button {
            @media (hover: hover) {
                &:hover {
                    @include font-size(16px);
                    font-family: var(--secondary-font);
                    background-color: var(--secondary);
                    color: $light;
                    font-weight: lighter;
                    border-color: var(--secondary);
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:disabled {
                        opacity: 1;
                    }

                    > i {
                        margin-right: 0.5em;
                    }

                    .spinner-border {
                        position: absolute;
                        height: 1rem;
                        width: 1rem;
                        border: 0.2em solid currentColor;
                        border-right-color: transparent;
                        color: $white;
                    }
                }
            }

            @include font-size(16px);
            font-family: var(--secondary-font);
            background-color: var(--secondary);
            color: $light;
            font-weight: lighter;
            border-color: var(--secondary);
            display: flex;
            justify-content: center;
            align-items: center;

            &:disabled {
                opacity: 1;
            }

            > i {
                margin-right: 0.5em;
            }

            .spinner-border {
                position: absolute;
                height: 1rem;
                width: 1rem;
                border: 0.2em solid currentColor;
                border-right-color: transparent;
                color: $white;
            }
        }
    }

    .row > .col-12.CatalogCTA {
        margin-top: 4em;
    }

    a[role='button'],
    a[type='button'] {
        -webkit-appearance: none;
    }

    .text-m {
        display: none;
    }
}

@media (max-width: 991px) {
    .CatalogGrid {
        margin-top: 0.5em;
    }
}
