﻿.ProductFeatures {

     li {
         margin-top: 8px;
         margin-bottom: 8px;
     }

    @include media-breakpoint-down(sm) {
        ul {
            text-align: left;
        }
    }
}
