﻿.bg-yellow {
    background-color: $yellow;
}
.bg-red {
    background-color: $red;
}
.bg-black {
    background-color: $black;
}
.bg-graydark {
    background-color: $gray-dark;
}
.bg-grayprimary {
    background-color: $gray-primary;
}
.bg-graysecondary {
    background-color: $gray-secondary;
}
.bg-grayghost {
    background-color: $gray-ghost;
}
.bg-white {
    background-color: $white;
}
