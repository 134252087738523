﻿.navigation__grid {
    padding-top: 40px;
    padding-bottom: 40px;

    & .dropdown-item {
        min-height: 173px;

        img {
            max-height: 157px;
        }

        .card-img-overlay {
            background: rgba(33, 35, 34, 0.5);
            transition: background 0.3s;
        }

        @media (hover: hover) {
            .card:hover .card-img-overlay {
                background: transparent;
            }

            &:hover {
                background-color: transparent;
                color: var(--primary) !important;
                text-decoration: none;
            }
        }

        &:focus {
            background-color: transparent;
            color: var(--primary) !important;
            text-decoration: none;
        }

        & a {
            text-transform: uppercase;
            font-family: var(--secondary-font);
            color: $white;
        }

        & .navigation__item-noimage {
            background-color: $gray-secondary;
        }

        .card {
            border: none;
        }
    }
}
