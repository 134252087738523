.keyfeaturesblock {
    margin-top: 2rem;
    width: 100vw;

    .KeyFeatures {
        width: 100%;

        &__Header {
            display: flex;
            margin: 0 0 1.25rem 2.5rem;
            text-transform: uppercase;

            @include media-breakpoint-down(xs) {
                margin-left: 0;
                text-wrap: nowrap;
                justify-content: center;
            }
        }

        &__features {
            &__bullet {
                display: flex;
                flex-direction: row;
                align-items: stretch;
                width: calc(100% + 1px);
                flex-wrap: wrap;

                &__text {
                    padding: 0 2rem;
                    width: 44%;
                    display: flex;
                    align-items: center;
                }

                &__image {
                    width: 56%;
                    overflow: hidden;
                    position: relative;
                    //All images are a uniform w/h ratio which we know ahead of time.
                    @include aspect(6, 1);

                    img {
                        position: absolute;
                        top: 0;
                        width: auto;
                        height: 100%;
                        object-fit: contain;
                    }

                    .SlantedBorder {
                        position: absolute;
                        height: 100%;
                        width: 5px;
                        transform: skew(-10.5deg, 0);
                    }

                    @include media-breakpoint-down(md) {
                        left: unset !important;
                        right: unset !important;
                    }
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    margin: auto 0;
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    border-style: solid;
                    border-color: var(--primary);
                    border-width: 0;
                    width: 100%;
                    li {
                        padding: 0;
                        margin: 0;
                    }
                }

                @include media-breakpoint-down(xs) {
                    padding: 0;
                    width: 100vw;
                    &__text {
                        padding: 0 1rem;
                    }
                }

                @include media-breakpoint-down(md) {
                    &__text {
                        width: 100%;
                        margin: 1rem 0;
                    }

                    &__image {
                        width: 770px;

                        img {
                            width: 100%;
                            //All images are a uniform w/h ratio which we know ahead of time.
                            @include aspect(6, 1);
                        }

                        .SlantedBorder {
                            display: none;
                        }
                    }
                }

                @include media-breakpoint-down(sm) {
                    &__image {
                        width: 100%;
                    }
                }

                &:nth-child(even) {
                    flex-direction: row-reverse;

                    ul {
                        border-left-width: 0.25rem;
                        align-items: flex-start;
                        text-align: left;
                        padding-left: 1em;
                    }

                    img {
                        left: 0;

                        @include media-breakpoint-up(md) {
                            clip-path: polygon(3.3% 0, 100% 0, 100% 100%, 0.3% 100%);
                        }

                        @include media-breakpoint-down(md) {
                            left: auto;
                            right: 0;
                        }
                    }

                    .SlantedBorder {
                        left: 1.5%;
                    }
                }

                &:nth-child(odd) {
                    flex-direction: row;

                    ul {
                        border-right-width: 0.25rem;
                        align-items: flex-end;
                        text-align: right;
                        padding-right: 1em;
                    }

                    img {
                        right: 0;

                        @include media-breakpoint-up(md) {
                            clip-path: polygon(0 0, 99.7% 0, 96.7% 100%, 0 100%);
                        }

                        @include media-breakpoint-down(md) {
                            right: auto;
                            left: 0;
                        }
                    }

                    .SlantedBorder {
                        right: 1.5%;
                    }
                }

                &:nth-child(2) {
                    .KeyFeatures__features__bullet__image {
                        left: 3%;
                    }
                }

                &:nth-child(3) {
                    .KeyFeatures__features__bullet__image {
                        right: 3%;
                    }
                }

                &:nth-child(5) {
                    .KeyFeatures__features__bullet__image {
                        right: 6%;
                    }
                }
            }
        }
    }
}
