.navigation__section {
    background-color: $gray-light;

    .nav-item {
        &.selected {
            a {
                font-weight: bold;
            }

            border-left: 5px solid $red;
        }

        &:not(.selected) {
            padding-left: 5px;
        }

        &:hover {
            background-color: $gray-medium;
        }

        a {
            color: var(--secondary);
        }
    }
}
