.contentaccordionblock {
    .desktop_layout {
        display: none;
        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    .mobile_layout {
        display: block;
        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}
