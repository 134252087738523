.articlePage {
    padding-top: 0;
    padding-bottom: 60px;

    img {
        max-width: 100%;
        object-fit: contain;
        height: 100%;
    }

    & .breadcrumb {
        color: $dark;
    }

    & button.fa.fa-chevron-left,
    button.fa.fa-chevron-right {
        line-height: 0;
        position: absolute;
        top: 50%;
        display: block;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        background-color: transparent;
        color: var(--primary);
        border: none;
        font-size: 23px;
        z-index: 1;
    }

    & button.fa.fa-chevron-right {
        right: -25px;
    }

    & button.fa.fa-chevron-left {
        left: -25px;
    }

    @include media-breakpoint-down(sm) {
        & button.fa.fa-chevron-right {
            right: -4px;
        }

        & button.fa.fa-chevron-left {
            left: -4px;
        }

        .articlePage-bodycopy {
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }

    & .slick-next:before,
    & .slick-prev:before {
        font-size: 40px;
        color: var(--primary);
    }

    & .slick-prev {
        left: 8px;
        z-index: 9999999;
    }

    & .slick-next {
        right: 22px;
    }

    & .articlepage__protitle {
        color: $semi-dark;
        font-family: var(--secondary-font);
        font-size: 23px;
        font-weight: 500;
        letter-spacing: 2px;
        line-height: 19px;
        margin-bottom: 20px;
    }

    .date {
        line-height: 50px;
        font-size: 1.5rem;
        font-family: var(--secondary-font);
        text-transform: uppercase;
    }

    & .title {
        color: $dark;
        font-family: var(--primary-font);
        font-size: 3.5rem;
        letter-spacing: -1.6px;
        line-height: 3rem;
        text-align: center;
        text-transform: uppercase;
    }

    .mast-image {
        padding-left: 0.5em;
        padding-right: 0.5em;

        &__image {
            object-fit: contain;
            background-repeat: no-repeat;
            width: 100%;
            background-position: center;
        }
    }

    .articlepage__awards {
        color: $semi-dark;
        font-family: var(--secondary-font);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.88px;
        line-height: 36px;
        text-align: center;
    }

    .main-p {
        font-family: var(--tertiary-font);
        width: 90%;
        margin: 0 auto;
    }

    .m-f .bg-img {
        position: relative;
    }

    .m-f .p-icon {
        position: absolute;
        left: 50%;
        top: 50%;
    }

    .main-p2 {
        letter-spacing: 0.25px;
        line-height: 28px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        color: $dark;
    }

    .m-quote {
        color: $dark;
        font-family: var(--primary-font);
        font-size: 47px;
        font-weight: bold;
        letter-spacing: 0.67px;
        line-height: 60px;
        text-align: right;
        position: relative;
    }

    .m-quote span {
        font-size: 80px;
        position: absolute;
        left: -8px;
    }

    .m-quote:after {
        content: ' ';
        display: block;
        position: absolute;
        height: 5px;
        width: 60px;
        background-color: var(--primary);
        right: 0;
        bottom: -20px;
    }

    .bit-img {
        height: 532px;
    }

    .my-auto {
        margin-top: auto;
        margin-bottom: auto;
    }

    .small-p {
        color: $gray-secondary;
        font-family: var(--tertiary-font);
        font-size: 12px;
        letter-spacing: 0.25px;
        line-height: 20px;
        margin-top: 20px;
    }

    .small-h {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 80px;
        margin-bottom: 20px;
    }

    .big-banner {
        background-size: cover;
        background-repeat: no-repeat;
        padding: 70px 0px 50px;
        background-position-x: initial;
        margin-left: 0;
        margin-right: 0;
    }

    .quote-box {
        position: relative;
        margin-bottom: 30px;
        width: 80%;
    }

    .quote-box:before {
        content: '“';
        display: block;
        position: absolute;
        left: -48px;
        font-size: 109px;
        top: -44px;
        color: var(--primary);
    }

    .quote-box:after {
        content: ' ';
        display: block;
        position: absolute;
        height: 5px;
        width: 60px;
        background-color: var(--primary);
        right: 0;
        bottom: -20px;
    }

    .quote-box h2 {
        color: $light;
        font-family: var(--primary-font);
        font-size: 35px;
        font-weight: bold;
        letter-spacing: 0.67px;
        line-height: 40px;
        margin-bottom: 25px;
    }

    .quote-box p {
        color: $light;
        font-family: var(--tertiary-font);
        font-size: 19px;
        letter-spacing: 0.25px;
        line-height: 28px;
    }

    .big-banner .owl-carousel .owl-nav.disabled {
        display: block;
    }

    .big-banner .owl-carousel .owl-nav button.owl-next,
    .big-banner .owl-carousel .owl-nav button.owl-prev {
        color: inherit;
        border: none;
        padding: 0;
        margin: 0px;
        font: inherit;
        position: absolute;
        top: 45%;
        font-size: 48px;
        line-height: 3px;
        color: $dark;
        border-radius: 0px;
        background: $light;
        width: 44px;
        height: 52px;
        display: block;
        box-shadow: 1px 2px 3px gray;
    }

    .news-Card {
        width: 558px;
        height: 308px;
        border: 8px solid $light;
        padding: 15px;
        text-align: center;
        vertical-align: middle;

        h1 {
            font-size: 21px;
        }

        h6 {
            text-align: left;

            a {
                color: $light;
                font-size: 16px;
            }
        }
    }

    .card-product {
        height: 308px;
        border: 1px solid $dark;
        padding: 15px;
        text-align: center;
        vertical-align: middle;
        background-color: $light;
    }

    .pro-heading {
        font-family: var(--primary-font);
        font-size: 40px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 30px;
        text-transform: uppercase;
        color: $dark;
        position: relative;
        margin-bottom: 40px;
    }

    .pro-heading a {
        font-family: var(--primary-font);
        color: $gray-secondary;
        font-size: 15px;
        font-weight: 100;
        letter-spacing: 2px;
        line-height: 19px;
        float: right;
        padding-top: 6px;
        margin-bottom: 20px;
    }

    .pro-heading:after {
        content: ' ';
        display: block;
        position: absolute;
        height: 5px;
        width: 60px;
        background-color: var(--primary);
        bottom: -20px;
    }

    @media (max-width: 500px) {
        .title {
            font-size: 2rem !important;
        }
    }
}

.articleQuoteBlock {
    padding-top: 30px;
    padding-bottom: 30px;

    .big-banner {
        background-size: cover;
        background-repeat: no-repeat;
        padding: 70px 50px 50px;
        background-position-x: initial;
        margin-left: 0;
        margin-right: 0;
    }

    .quote-box {
        position: relative;
        margin-bottom: 30px;
        width: 80%;
    }

    .quote-box:before {
        content: '“ \A';
        display: block;
        position: absolute;
        left: -48px;
        font-size: 109px;
        top: -44px;
        color: var(--primary);
    }

    .quote-box:after {
        content: ' ';
        display: block;
        position: absolute;
        height: 5px;
        width: 60px;
        background-color: var(--primary);
        right: 0;
        bottom: -20px;
    }

    .quote-box h2 {
        color: $light;
        font-family: var(--primary-font);
        font-size: 35px;
        font-weight: bold;
        letter-spacing: 0.67px;
        line-height: 40px;
        margin-bottom: 25px;
    }

    .quote-box p {
        color: $light;
        font-family: var(--tertiary-font);
        font-size: 19px;
        letter-spacing: 0.25px;
        line-height: 28px;
    }

    & .title {
        color: $light;
        font-family: var(--primary-font);
        font-size: 83px;
        letter-spacing: -2.67px;
        line-height: 75px;
        text-align: left;
        text-transform: uppercase;
    }

    & .articlepage__protitle {
        color: var(--primary);
        font-family: var(--secondary-font);
        font-size: 23px;
        font-weight: 500;
        letter-spacing: 2px;
        line-height: 19px;
        margin-bottom: 20px;
        margin-top: 20px;
        padding-left: 15px;
    }

    & .description {
        color: $light;
        font-family: var(--tertiary-font);
        font-size: 16px;
        letter-spacing: 0.25px;
        line-height: 24px;
    }

    & .btn-lg {
        padding: 22px 45px;
        margin-top: 50px;
        font-family: var(--secondary-font);
        letter-spacing: 1.92px;
        font-weight: normal;
    }
}

.article__card {
    min-height: 250px;

    & .card-img-top {
        filter: grayscale(1);
    }

    .card-date {
        font-size: 1rem;
        font-family: var(--secondary-font);
        text-transform: uppercase;
        padding-bottom: 10px;
    }

    .article-link {
        font-size: 1.5rem;
        font-family: var(--secondary-font);
        text-transform: uppercase;
    }

    .card-title {
        text-transform: initial !important;
        font-size: 1.8rem;
        line-height: 1.9rem;
        margin-bottom: 0.5rem;
        font-family: var(--primary-font);
    }
}
