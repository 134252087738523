.content_card_shop {
    flex-direction: column;
    position: relative;
    padding: 0px 5px;

    &_image_link {
        height: 100% !important;
    }

    &__img {
        width: 100%;
        height: 100%;
    }

    &__btn-container {
        position: absolute;
        bottom: 20px;
        display: flex;
        width: calc(100% - 10px); //Making up for padding used on entire shop card (found above).

        &.left {
            justify-content: left;

            a.btn {
                margin-left: 20px;
            }
        }

        &.center {
            justify-content: center;
        }

        &.right {
            justify-content: right;

            a.btn {
                margin-right: 20px;
            }
        }
    }
}
