// Zebco font size transform
@function zebcoToBootstrapFontSize($zebcoSize) {
    @return calc($zebcoSize * 10 / 16);
}

/*
 ==== Color Palette ==========================
*/
// Override Bootstrap defaults
$red: #da291c;
$yellow: #ffc627;
$yellow-layer-1: rgba(196, 0, 48, 0.2);
$white: #fff;
$black: #000;
$gray-primary: #212529;

$primary: $red;
$primary-layer-1: $yellow-layer-1;
$secondary: #584446;
$tertiary: $gray-primary;
$light: $white;
$dark: $black;
$adventure-card-gray: #aaa;

// Lew's custom colors
// Question,  should we override bootstrap grays?
//   dark -> gray-900
//   primary -> gray-800
//   secondary -> gray-600
//   ghost -> gray-100
$gray-dark: #1d1b1c;
$gray-secondary: #78777a;
$gray-ghost: #f3f6f6;
$semi-dark: #232a2d;
$gray-light: #f2f1f1;
$gray-medium: #d4d4d4;
$checkbox-fill-color: $primary;

$info: $primary;
$success: $light;
$warning: $yellow;
$danger: $red;

$text-highlight: rgba(215, 239, 255, 0.75);

$gray-bg: #f1f2f2;
/*
 ==== Fonts ==============================
*/
$special-font: trade-gothic, serif;
$special-font-condensed: trade-gothic-condensed, serif;

$primary-font: azo-sans-web, sans-serif;
$secondary-font: $primary-font;
$tertiary-font: $primary-font;
$button-font: $special-font;
$font-family-base: $primary-font !default;
$font-weight-bolder: 900 !default;
$font-size: 14px;
$font-size-sm: zebcoToBootstrapFontSize(0.555rem);
$line-height-base: 1.5; //computed from the original Zebco site
$letter-spacing: 1px;
$letter-spacing-1: 1px;
$letter-spacing-2: 2px;
$enable-responsive-font-sizes: true !default;

// Default css variable fonts
:root {
    --primary-font: #{$primary-font};
    --secondary-font: #{$secondary-font};
    --tertiary-font: #{$tertiary-font};
    --font-family-base: #{$primary-font};
    --primary-contrast: #{$light};
    --secondary-contrast: #{$light};
    --checkbox-fill-color: var(--primary);
    --link-color: #{$red};
}

$h1-font-size: zebcoToBootstrapFontSize(4.6rem);
$h2-font-size: zebcoToBootstrapFontSize(3.2rem);
$h3-font-size: zebcoToBootstrapFontSize(2.6rem);
$h4-font-size: zebcoToBootstrapFontSize(2.2rem);
$h5-font-size: zebcoToBootstrapFontSize(2rem);
$h6-font-size: zebcoToBootstrapFontSize(1.8rem);

$h1-mobile-font-size: zebcoToBootstrapFontSize(4.6rem);
$h2-mobile-font-size: zebcoToBootstrapFontSize(3.2rem);
$h3-mobile-font-size: zebcoToBootstrapFontSize(2.6rem);
$h4-mobile-font-size: zebcoToBootstrapFontSize(2.2rem);
$h5-mobile-font-size: zebcoToBootstrapFontSize(1.8rem);
$h6-mobile-font-size: zebcoToBootstrapFontSize(1.6rem);

$headings-font-weight: 900 !default;

$display1-size: 120px;
$display2-size: 90px;
$display3-size: 38px;
$display4-size: 19px;

$display1-weight: 900;
$display2-weight: 900;
$display3-weight: 900;
$display4-weight: 900;

$tooltip-font-size: zebcoToBootstrapFontSize(1.4rem);

/*
 ==== Links  ==============================
*/
$link-color: $red;
//$link-decoration: underline !default;
/*
 ==== Navigation  ==============================
*/
$navbar-dark-active-color: $white;
$navbar-dark-hover-color: $yellow;
$navbar-dark-color: $yellow;

$nav-item-vertical-padding: 0.45rem;
/*
 ==== Buttons  ==============================
*/
$input-btn-font-family: 'trade-gothic', serif;
$btn-font-weight: 600;
$btn-border-radius: 0px;
$btn-border-radius-lg: 0px;
$btn-border-radius-sm: 0px;
$btn-disabled-opacity: 0.5;
$btn-link-disabled-color: $red;
$btn-line-height-lg: 22px;

/*
 ==== Input  ==============================
*/
$input-font-size: 24px !default;
$input-border-color: $black;
$input-border-width: 2px;
$input-border-radius: 0px;
$input-border-radius-lg: 0px;
$input-border-radius-sm: 0px;
/*
 ==== Alerts  ==============================
*/
$alert-border-radius: 0px;
$alert-border-width: 2px !default;
$alert-border-level: 0 !default;
/*
 ==== Forms  ==============================
*/
$enable-validation-icons: false;
$form-feedback-valid-color: $black;
