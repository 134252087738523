.CartListing {
    display: flex;
    flex-direction: column;

    &__checkout {
        margin-top: 2em;
        width: 100%;

        a {
            width: 100%;
        }
    }

    &__alert.alert-warning {
        margin-top: 2.5em;
        cursor: pointer;
    }

    &__total {
        padding-top: 0.875em;

        .price {
            display: flex;

            .amount {
                flex: 1;
                text-align: right;

                &.strike {
                    text-decoration: line-through;
                }
            }
        }
    }

    &__footer {
        font-size: 0.75em;
        width: 100%;
        margin: 0.5rem 0 1rem 0;
        flex: 1 1 0%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
}

.CartListing,
.VariantSelector {
    font-size: 1rem;
    padding: 2rem 3rem !important;

    @include media-breakpoint-down(sm) {
        padding: 1.5rem 1.5rem !important;
    }

    .header {
        position: relative;
        margin-bottom: 1em;
        display: flex;

        .btn-close {
            font-size: 2em;
            transition: 0.15s color ease-in;
            padding: 0;
        }

        h6 {
            width: 100%;
            text-align: center;
        }
    }

    .ProductInput {
        .num-input {
            font-size: 0.875rem !important;
        }
    }

    .FakeBlock {
        background: rgba($light, 0.2);
    }
}

.CartItem {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1.5em 0 2em 0;

    &__gallery {
        position: relative;
        cursor: pointer;
        margin-bottom: 1.35em;

        img {
            min-height: auto !important;
            max-height: none !important;
        }
    }

    .ProductBadge {
        margin-bottom: 0;
        justify-content: start;
    }

    .Badge {
        margin-top: -1em;
    }

    &__name {
        text-transform: uppercase;
        text-decoration: none !important;
        transition: 0.15s color ease-in;
        width: calc(100% - 1.5em);
        display: block;
        word-break: break-word !important;
    }

    &__body {
        display: flex;
    }

    &__col.left {
        width: 4em;
        display: flex;
        flex-direction: column;

        p {
            display: block;
            width: 100%;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0.5em 0;
            margin: 0;
            line-height: 1em;
            color: $light;
            font-size: 0.75em;
            background: $dark;
        }
    }

    &__col.right {
        padding-left: 1.5em;
        flex: 1;

        @include media-breakpoint-down(md) {
            padding-left: 0.5em;
        }
    }

    .btn-del {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0.5em;
        margin-top: 1.15em;
        margin-right: -0.5em;
        transition: 0.15s color ease-in;
    }

    &__price {
        display: flex;
        flex-direction: row;
        margin: 0.5em 0 !important;

        .strike {
            margin-right: 0.75em;

            @include media-breakpoint-down(md) {
                margin-right: 0.35em;
            }
        }

        + .ProductInput {
            margin: 0 !important;
        }
    }

    &__table {
        margin-top: 0.5em;

        th {
            padding-right: 2em;
        }
    }

    &__input,
    &__input.ProductInput {
        height: 2em;
        margin-top: 1em !important;

        .ProductPrice {
            height: 100%;
            margin: 0 !important;
            justify-content: flex-end;
            align-items: center;
        }
    }
}

.InlineCart {
    position: fixed;
    font-size: 1rem;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: none;
    pointer-events: auto;
    z-index: 10;
    background-color: rgba($dark, 0.2);
    transition: 0.5s background-color;

    &.disabled {
        pointer-events: none;
        background-color: rgba($dark, 0);
    }

    .drawer {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: auto;
        width: 30em;
        max-width: 100%;
        transition: transform 0.5s;
        transform: translateX(100%);
        z-index: 11;

        &.open {
            transform: translateX(0);
            pointer-events: auto;
        }

        &:not(.Loader) {
            @include branded-scroll-y($size: 0.75rem);
        }
    }

    &.mobile .drawer {
        width: auto;
        min-width: none;
        left: 0;
    }
}
