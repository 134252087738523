.carousel {
    .carousel__card {
        &.carousel__card--style6 {
            background-color: transparent;

            h3 {
                font-weight: bold;
                @include font-size(zebcoToBootstrapFontSize(2.2rem));
            }

            img {
                width: auto;
                max-height: none;
            }
        }

        &.carousel__card--style7 {
            min-height: 0 !important;

            .carousel__card-transparency {
                background-color: rgba(100, 100, 100, 0);
                transition: background-color 1s;
            }

            .carousel__card-caption {
                height: 100%;

                color: white;

                top: 75%;
                transition: top 1s;

                h5 {
                    color: white;
                    word-spacing: unset;
                    font-family: $special-font;
                    text-transform: uppercase !important;
                }

                .btn {
                    border-color: white;

                    @media (hover: hover) {
                        &:hover {
                            background-color: $white;
                            color: $red;
                        }
                    }
                }
            }

            @media (hover: hover) {
                &:hover {
                    h5 {
                        color: white !important;
                    }
                }
            }
        }

        @media (hover: hover) {
            &:hover {
                &.carousel__card--style7 {
                    .carousel__card-transparency {
                        background-color: rgba(100, 100, 100, 0.5);
                    }

                    .carousel__card-caption {
                        top: 0;
                    }
                }
            }
        }

        &.carousel__card--style7__mobile {
            background-color: transparent;

            .card-img {
                margin-bottom: 1em;
            }

            .card-title {
                font-family: $special-font;
                text-transform: uppercase !important;
                text-align: left;
                font-weight: bold;
                word-spacing: normal;
                @include font-size(28px);
                color: var(--secondary);
            }

            .card-text {
                font-weight: bold;
                color: var(--secondary);
                font: var(--primary-font);
                text-align: left;
                @include font-size(18px);
            }
        }
    }
}

.slick-track {
    display: flex !important;

    > .slick-slide {
        height: inherit;
    }
}
