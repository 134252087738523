@import '../../../../Common/src/scss/blocks/_header_navigation.scss';

.headernavigationslidemenu {
    .header {
        &__user {
            .dropdown-menu {
                .nav-link {
                    font-size: 1rem;
                    color: var(--primary);
                }
            }
        }
    }

    #nav-container {
        .nav-links {
            margin: 0;
            list-style-type: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;

            a {
                font-family: var(--primary-font);
                text-transform: uppercase;
            }

            i {
                .icon-times-outlined {
                    &:hover {
                        filter: drop-shadow(0px 1px 0px) brightness(4);
                    }
                }

                .icon-profile-angle {
                    height: 2em;
                    width: 2em;
                }
            }

            > li:not(.flex-grow-1) {
                .svg-icon {
                    font-size: 2rem !important;
                }
            }
        }

        @include media-breakpoint-down(md) {
            .mobile-nav-link {
                font-family: var(--primary-font);
                font-size: 1.15rem;
                text-transform: uppercase;
                letter-spacing: 0.133em;
                font-weight: 600;
                text-decoration: none !important;

                &.active[href],
                &:active[href] {
                    text-decoration: underline !important;
                    font-weight: bolder;
                }

                &.selected {
                    font-size: 1.75rem;
                }
            }

            .nav-footer {
                > div {
                    &:first-child {
                        border-top: 1px solid var(--primary);
                        padding-top: 1em;
                    }
                }
            }

            .nav-links {
                .icons.icon-logo {
                    max-height: 2.5rem;
                }
            }

            .social {
                a {
                    > span > i {
                        font-size: 1.375rem !important;
                    }
                }
            }

            .terms {
                a,
                span {
                    font-size: 0.75rem;
                    font-family: var(--primary-font);
                    font-weight: 600;
                    letter-spacing: 0.05em;
                }

                a {
                    text-decoration: underline;
                }
            }

            .copyright {
                font-size: 0.875rem;
                font-family: var(--primary-font);
                font-weight: 300;
                letter-spacing: $letter-spacing-1;
            }
        }

        .nav-children {
            h5 {
                font-size: 3rem;
                margin-top: 0.5em;
                font-family: var(--primary-font);
            }

            > ul {
                > li {
                    a {
                        @media (hover: hover) {
                            &:hover {
                                font-weight: bolder;
                            }
                        }
                    }

                    a,
                    button {
                        font-family: var(--primary-font);
                        font-size: 1rem;
                        font-weight: 900;
                        line-height: 2.5em;
                        text-transform: uppercase;
                        letter-spacing: 0.1em;

                        @include media-breakpoint-down(md) {
                            line-height: 1.8em;
                            letter-spacing: 0.0575em;
                        }
                    }
                }
            }

            .nav-childItem-head {
                div {
                    flex-grow: 1;
                }

                i {
                    font-size: 1.25em;
                }
            }

            .nav-childItem-list {
                > li {
                    a {
                        font-family: var(--tertiary-font);
                        font-size: 1rem;
                        font-weight: 400;
                        letter-spacing: 0.08em;

                        @media (hover: hover) {
                            &:hover {
                                font-weight: 700;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

        .MenuCtaPromoBlock {
            a.cursor-pointer,
            a:not(:active):not(.active) {
                text-decoration: none !important;
            }

            .link-list {
                h6 {
                    font-family: var(--secondary-font);
                    font-weight: 400;
                    font-size: 1.5rem;
                    letter-spacing: 0.08em;
                }

                ul {
                    > li {
                        > a {
                            font-family: var(--tertiary-font);
                            font-size: 1rem;
                            letter-spacing: 0.08em;
                        }
                    }
                }
            }

            .mobile-nav-link {
                display: block;
                width: 100%;
            }
        }
    }
}
