﻿.CatalogCTA {
    &.DefaultCatalogCTA {
        border: none !important;
        display: flex;
        flex-direction: column;
        padding: 2em;
        background-color: var(--secondary);

        &.text-color-light {
            color: $light;
        }

        &.text-color-dark {
            color: $dark;
        }

        &.text-color-primary {
            color: var(--primary);
        }

        &.text-color-secondary {
            color: var(--secondary);
        }

        &[role='button'] {
            cursor: pointer;
        }

        > h6 {
            font-family: var(--secondary-font);
            font-weight: normal;
            @include font-size(19px);
            margin-bottom: 0;
        }

        > h3 {
            line-height: 0.9em;
            font-weight: bold;
        }

        > p {
            margin-top: 0.25em;
            font-weight: normal;
        }

        > [role='button'] {
            margin-top: 1.5em;

            > i {
                margin-right: 0.5em;
            }

            > span + i {
                margin-left: 0.5em;
                margin-right: 0;
            }
        }
    }
}
