﻿.DetailsLinks {
    margin-top: 24px;
    position: 'relative';
    justify-content: start;
    margin-left: -15px;

    .LinkItem {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 14px;
        justify-content: center;
    }
}
