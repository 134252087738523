﻿.ProductGallery {
    display: flex;
    flex-direction: column;

    .fit-image-main,
    .fit-image-main img {
        width: 100%;
        max-height: 365px;
        object-fit: contain;
    }

    .fit-image-nav,
    .fit-image-nav.video img,
    .fit-image-nav.spin img {
        height: 70px;
        width: 90px;
        object-fit: contain;
    }

    .slick-slider {
        position: relative;
        .slick-list {
            margin: 0 2rem;
            overflow: hidden;
        }
    }

    .slick-slide {
        box-sizing: border-box;

        button {
            width: 100%;
            display: flex;
        }
    }

    .right-arrow,
    .left-arrow {
        position: absolute;
        top: 2rem;
        border: none;
        background: none;
        opacity: 1 !important;

        i {
            font-size: 30px;
            color: $semi-dark;
        }
    }

    .left-arrow {
        left: 0;
    }

    .right-arrow {
        right: 0;
    }

    .btn {
        padding: 0;
    }

    button.ArrowButton {
        &:before {
            content: none;
        }
    }

    &__Modal {
        .modal-dialog-centered {
            max-height: 80vh;
        }

        .modal-body {
            width: 100%;
            height: 80vh;
            background: transparent;

            @include media-breakpoint-down(md) {
                height: 45vh;
                max-height: 75vh;
                min-height: 40vh;
                display: flex;
                align-items: center;
            }
        }

        &__Image {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }

        .modal-footer {
            .Carousel {
                @include media-breakpoint-up(lg) {
                    max-width: calc(100% - 100px);
                }

                padding: 0px;
            }

            .CarouselItem {
                font-size: 1rem;
                width: 5em;
                height: 5em;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;

                .MediaConverter,
                img {
                    height: 100% !important;
                    width: 100% !important;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 0.67rem;
                }
            }

            > button {
                padding: 0.3em 0.5em;
                height: 2em;
            }
        }
    }

    .img-zoom {
        cursor: pointer;
    }

    .react-player__preview {
        background-size: contain;
    }

    .spin-thumb {
        position: absolute;
        top: 7px;
        right: 5%;

        img {
            height: 20px;
            width: 25px;
            padding: 0px;
            border: none !important;
        }

        @include media-breakpoint-down(sm) {
            right: 15%;

            img {
                height: 40px;
                width: 45px;
            }
        }
    }

    .spin-overlay {
        .sirv-spin-button {
            .fullscreen {
                display: none;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .fit-image-main,
        .fit-image-main img {
            max-height: 50vh;
            min-height: 25vh;
            width: auto;
            margin: 0 auto;
            max-width: 100%;
        }

        .fit-image-nav,
        .fit-image-nav.video img,
        .fit-image-nav.spin img {
            height: 220px;
            width: 100%;
        }

        button.selected img,
        button img {
            border: 2px solid transparent;
        }

        .slick-list {
            margin-right: 0px;
            margin-left: 0px;

            .slick-slide {
                height: inherit !important;
                padding: 0 2px 0 2px;
                width: 100%;

                button {
                    margin-right: 8px;
                    width: 100%;
                }
            }
        }

        .right-arrow,
        .left-arrow {
            display: block;
            top: 45%;
            height: 30px;
            width: 30px;
            font-size: 14px;
            -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25) !important;
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25) !important;
            z-index: 1;
            background: $white;

            i {
                font-size: 14px;
            }
        }

        .right-arrow {
            right: -5px;
        }

        .left-arrow {
            left: -5px;
        }

        .slick-dots {
            li {
                margin: 0px;
            }

            li button:before {
                opacity: 1;
                border-radius: 5px;
                height: 9px;
                width: 9px;
                font-size: 0px;
                margin: 0px 5px;
            }
        }
    }
}
