﻿.alert-custom {
    color: $black;
    font-size: normal;
    font-weight: 400;
    background-color: rgba(237, 237, 237, 0.1) !important;
}

.Loader {
    &.Overlay {
        z-index: 98;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: auto;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff40;
        > div {
            z-index: 99;
        }

        &.fitToContainer {
            position: absolute;
        }
    }
}

.UserMessage {
    position: fixed;
    bottom: 1em;
    left: 1em;
    right: 1em;
    z-index: 100;

    user-select: none;
    opacity: 0;
    transition: opacity 250ms;
    pointer-events: none;
    cursor: pointer;

    &.show {
        opacity: 1;
        pointer-events: auto;
    }

    > .alert {
        text-align: center;
    }
}

.alert-success {
    border-color: $green;
}