@keyframes slidedown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

.ResultsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: minmax(300px, 1fr);
    justify-content: stretch;
    align-items: stretch;

    &__expansion {
        .ProductQuickviewContainer {
            padding-top: .5rem;

            .ProductView {
                padding-top: 1rem;
                padding-bottom: 1rem;

                .ProductGallery {
                    padding-top: 1rem;
                }
            }

            &.Filter {
                border: 1px solid var(--primary);
            }
        }
    }

    &__expansionCell {
        @include media-breakpoint-up(sm) {
            grid-column: 1 / -1;

            display: flex;
            align-items: stretch;
            justify-content: stretch;

            > * {
                flex: 1 1 0px;
            }
        }
    }

    &.gap {
        @for $i from 1 through 10 {
            &--#{$i} {
                gap: spacing($i);
            }
        }
    }

    &__expansion {
        animation-name: slidedown;
        animation-duration: 250ms;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
        width: 100%;

        &-close {
            display: block;
            margin-left: auto;
            margin-right: 0;
            font-size: 1.2rem;
        }
    }
}
