﻿.jumbotron {
    height: 800px;
    align-items: center;
    border-radius: 0;

    &__flatbottom {
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
    }

    &__sub_images {
        padding: 40px;

        a {
            height: 80px;
            width: 120px;
        }

        &_icon {
            display: flex;
            align-items: center;
            padding: 0 20px;
            color: $white;
        }
    }

    @include media-breakpoint-down(md) {
        height: 700px;

        &__sub_images {
            > div > div i {
                display: none;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        height: 600px;

        &__sub_images {
            a {
                height: 50px;
                width: 80px;
            }
        }
    }
}
