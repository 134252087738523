﻿@import '../../../../../Common/src/scss/components/react/ProductInput.scss';

.ProductInput {
    > .row {
        align-items: stretch;
        max-height: 2.65rem;
        min-height: 2.65rem;
    }

    input {
        width: 100%;
        height: 2.625em;
    }

    button {
        height: 100%;
    }
}
