.Badge {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    font-size: 1rem;
    pointer-events: none;
    background: none;
    display: flex;

    &__content {
        background: var(--primary);
        border-radius: 1em;
        color: $dark;
        font-weight: bold;
        font-size: 0.65em;
        font-style: normal;
        padding: 0 0.5em;
        margin: 0;
    }
}

.CartBadge {
    .Badge__content {
        margin-top: -0.25em;
        margin-right: -0.25em;
    }
}

.background__color-primary {
    .Badge__content {
        background: $dark;
        color: $light;
    }
}
