@keyframes linkSpinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes navIn {
    from {
        transform: translateX(0);
        opacity: 0;
    }
    100% {
        transform: translateX(50%);
        opacity: 1;
    }
}
@keyframes navOut {
    from {
        transform: translateX(50%);
        opacity: 1;
    }
    to {
        transform: translateX(0);
        opacity: 0;
    }
}

.HeaderColumns {
    display: grid !important;
    grid-template-columns: 1fr auto 1fr;

    &__center {
        position: relative;
    }
}

.headernavigationslidemenu {
    position: relative;

    &__left-nav,
    &__right-nav {
        transition: gap 200ms;
        z-index: 5;

        gap: var(--header-icon-gap, 1.5rem);

        @include media-breakpoint-down(lg) {
            gap: calc(var(--header-mobile-icon-gap, 1rem) + (var(--header-icon-gap, 1.5rem) - var(--header-mobile-icon-gap, 1rem)) * 0.67);
        }

        @include media-breakpoint-down(md) {
            gap: calc(var(--header-mobile-icon-gap, 1rem) + (var(--header-icon-gap, 1.5rem) - var(--header-mobile-icon-gap, 1rem)) * 0.33);
        }

        @include media-breakpoint-down(sm) {
            gap: calc(var(--header-mobile-icon-gap, 1rem));
        }
    }

    a {
        cursor: pointer;
    }

    #btn-nav-open,
    #btn-nav-close {
        opacity: 1;
        transition: opacity 1s ease;
        &.hidden {
            opacity: 0;
            pointer-events: none;
        }
    }

    > div {
        transition: padding 200ms;
        display: flex;
        flex-direction: column;
        padding: 1rem 9rem;

        @include media-breakpoint-down(lg) {
            padding: 1rem 0rem;
        }
    }

    .nav-icon.SvgIcon {
        transition: font-size 200ms, height 200ms;
        font-size: var(--header-icon-height, 2em);
        height: var(--header-icon-height, 2em);
        max-height: 1em;
        object-fit: contain;
        object-position: center center;

        @include media-breakpoint-down(lg) {
            font-size: calc(var(--header-icon-height, 2em) * 0.9);
            height: calc(var(--header-icon-height, 2em) * 0.9);

            &.icon-cart-angle {
                margin-right: 10px;
            }
        }

        @include media-breakpoint-down(md) {
            font-size: calc(var(--header-icon-height, 2em) * 0.8);
            height: calc(var(--header-icon-height, 2em) * 0.8);

            &.icon-cart-angle {
                margin-right: 10px;
            }
        }

        @include media-breakpoint-down(sm) {
            font-size: calc(var(--header-icon-height, 2em) * 0.7);
            height: calc(var(--header-icon-height, 2em) * 0.7);

            &.icon-cart-angle {
                margin-right: 10px;
            }
        }

        @include media-breakpoint-down(xs) {
            font-size: calc(var(--header-icon-height, 2em) * 0.6);
            height: calc(var(--header-icon-height, 2em) * 0.6);

            &.icon-cart-angle {
                margin-right: 10px;
            }
        }
    }

    .header {
        &__logo {
            display: flex;
            align-items: center;
            pointer-events: none;
            z-index: 5;
            cursor: pointer;
            pointer-events: all;
            padding-left: 1rem;
            padding-right: 1rem;

            &__margin {
                margin-top: 1em;
            }

            .nav-links {
                pointer-events: auto;
            }

            a {
                position: relative;
            }

            .nav-links {
                list-style-type: none;
                padding-left: 0;
                margin-bottom: 0px;
            }

            .icons.icon-logo {
                transition: font-size 200ms, max-height 200ms;
                font-size: var(--header-logo-height, 9rem);
                max-height: var(--header-logo-height, 2em);
                max-width: 100%;
                height: 100vh;

                object-fit: contain;
                object-position: center center;

                @include media-breakpoint-down(md) {
                    font-size: calc(var(--header-logo-height, 9rem) * 7 / 9);
                    max-height: calc(var(--header-logo-height, 9rem) * 7 / 9);
                }

                @include media-breakpoint-down(xs) {
                    font-size: calc(var(--header-logo-height, 9rem) * 5 / 9);
                    max-height: calc(var(--header-logo-height, 9rem) * 5 / 9);
                }
            }
        }

        &__navigation {
            display: flex;
            width: 100%;

            @include media-breakpoint-down(md) {
                padding: 1rem 2rem;
            }

            &__login {
                pointer-events: auto;
            }

            &__search {
                padding: 0;
                pointer-events: auto;

                &.search {
                    @include media-breakpoint-down(md) {
                        display: none !important;
                    }
                }

                &.searchMobile {
                    @include media-breakpoint-up(lg) {
                        display: none !important;
                    }
                }
            }

            &__shopping_cart {
                pointer-events: auto;
                position: relative;
                padding-right: 0.5rem !important;
            }

            .row {
                min-width: 100%;
                padding-top: 0.5em;
            }

            a {
                position: relative;
                padding: 0;
            }

            .nav-links {
                z-index: 5;
                margin: 0px;
                list-style-type: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                pointer-events: none;

                .nav-link {
                    padding: 0;
                    pointer-events: auto;
                }
            }
        }

        &__user {
            pointer-events: auto;
            text-wrap: nowrap;

            &__button {
                background-color: transparent;
                padding: 0;
                display: block !important;

                @include media-breakpoint-down(md) {
                    padding-right: 1em;
                }

                &__greeting {
                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }

                .fa-chevron-down {
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
            }

            .dropdown-menu {
                padding: $nav-item-vertical-padding 0;
                z-index: 5;
                border: 1px solid var(--primary);
                background-color: $gray-dark;
                color: $light;

                @include media-breakpoint-down(md) {
                    transform: translate(-125px, 40px) !important;
                }

                .nav-link {
                    /* Default styling is uppercase. */
                    text-transform: initial;
                    padding-top: $nav-item-vertical-padding;
                    padding-bottom: $nav-item-vertical-padding;
                    background-color: initial;
                    color: $light;
                }

                hr {
                    margin-top: $nav-item-vertical-padding;
                    margin-bottom: $nav-item-vertical-padding;
                    background-color: $light;
                }

                .nav-item {
                    color: var(--primary);

                    .nav-link {
                        background-color: initial;
                        text-decoration: none;
                    }

                    @media (hover: hover) {
                        &:hover .nav-link {
                            text-decoration: underline;
                            font-weight: bold;
                            color: $light;
                        }
                    }
                }
            }
        }
    }

    #nav-container {
        position: absolute;
        z-index: 10;
        width: 200vw;
        left: -100vw;
        padding-right: calc(100vw + 9rem);
        top: 0;
        opacity: 0;
        pointer-events: none;

        .nav-links {
            margin: 0;
            list-style-type: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;

            a {
                font-size: 0.95rem;
                font-weight: bold;
                padding: 1em !important;
                &.selected {
                    text-decoration: underline;
                    text-underline-offset: 2px;
                }
            }

            .nav-item-parent {
                pointer-events: none;
            }

            > li:not(.flex-grow-1) {
                margin-right: 1em;
            }
        }

        @include media-breakpoint-down(lg) {
            bottom: 0;
            position: fixed;
            z-index: 15;
            overflow-x: hidden;
            overflow-y: auto;
            padding-right: calc(100vw + 1rem);
            padding-left: 1rem;

            .mobile-nav-link {
                padding: 0.5em 0;
                margin-top: 0.25em;
            }

            .nav-footer {
                > div {
                    margin-bottom: 1em;
                }
            }

            .social {
                a {
                    margin-right: 1em;
                }
            }

            .terms {
                span {
                    margin-left: 0.5rem;
                }
            }
        }

        .nav-children {
            display: none;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
            max-height: none;
            width: 100%;
            &.selected {
                display: flex;
                @include media-breakpoint-down(md) {
                    max-height: 100% !important;
                    border-bottom: 1px solid var(--primary);
                }
            }

            @include media-breakpoint-down(md) {
                display: flex !important;
                max-height: 0px;
                overflow: hidden;
                transition: max-height 0.5s;
            }

            > ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                margin-top: 0.5em;

                @include media-breakpoint-down(md) {
                    margin: 0;
                    width: 90%;
                }

                > li {
                    position: relative;
                    margin: 0;
                    padding: 5px 0;

                    @include media-breakpoint-down(md) {
                        margin-bottom: 0.5em;
                    }

                    @include media-breakpoint-up(lg) {
                        &:not(:first-child) {
                            border-top: 2px solid var(--primary);
                            margin: 0;
                        }
                    }

                    a {
                        @media (hover: hover) {
                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }

                    a,
                    button {
                        display: flex;
                        height: auto;
                        &:not(.active):not(:active) {
                            text-decoration: none !important;
                        }

                        @include media-breakpoint-down(md) {
                            margin-left: 1em;
                        }

                        + i {
                            display: block;
                            position: absolute;
                            right: 0.33em;
                            top: 0.33em;
                            height: 1em;
                            width: 1em;
                            opacity: 0;
                            animation: linkSpinner 0.5s linear 0s infinite;
                            transform: 0.5s opacity;
                        }

                        &.active,
                        &:active {
                            text-decoration-line: underline;
                            + i {
                                opacity: 1 !important;
                            }
                        }
                    }

                    button {
                        border: none;
                        background: none;
                    }
                }
            }

            .nav-childItem-head {
                div {
                    flex-grow: 1;
                }

                i {
                    align-self: center;
                    color: var(--primary);
                    transform: rotate(-90deg);
                    stroke: var(--primary);
                    stroke-width: 5px;
                    transition: transform 0.4s ease-out;

                    @include media-breakpoint-down(md) {
                        font-size: 1em;
                    }
                }

                &.showChild {
                    i {
                        transform: rotate(0deg);
                    }
                }
            }

            .nav-childItem-list {
                list-style-type: none;
                padding: 0;
                margin: 0;
                display: none;

                > li {
                    position: relative;
                    margin: 0;
                    padding: 5px 0 5px 20px;

                    @include media-breakpoint-down(md) {
                        padding: 10px 0 10px 20px;
                    }

                    a {
                        text-transform: none;

                        @media (hover: hover) {
                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

        .MenuCtaPromoBlock {
            a.cursor-pointer,
            a:not(:active):not(.active) {
                text-decoration: none !important;
            }

            @media (hover: hover) {
                .cursor-pointer:not(:disabled):not([disabled]):not(.disabled) {
                    cursor: pointer;
                }
            }

            .link-list {
                padding-top: 2.5em;

                &.collection-1 {
                    margin-top: 0.75em;
                }

                &.collection-1,
                &.collection-2 {
                    padding: 5px 0px;
                }

                h6 {
                    margin-bottom: 1em;
                }

                ul {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;

                    &.cta-links {
                        padding-left: 20px;
                    }

                    > li {
                        width: 100%;
                        position: relative;
                        padding: 5px 0px;

                        @include media-breakpoint-down(md) {
                            padding: 10px 0px;
                        }

                        > a {
                            + i {
                                display: block;
                                position: absolute;
                                right: 0.33em;
                                top: 0.33em;
                                height: 1em;
                                width: 1em;
                                opacity: 0;
                                animation: linkSpinner 0.5s linear 0s infinite;
                                transform: 0.5s opacity;
                            }

                            &.active,
                            &:active {
                                @include media-breakpoint-down(md) {
                                    text-decoration: underline !important;
                                }
                                + i {
                                    opacity: 1 !important;
                                }
                            }
                        }

                        @media (hover: hover) {
                            cursor: pointer;
                            &:hover > a {
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            .mobile-nav-link {
                display: block;
                width: 100%;
            }

            .promo-image {
                font-size: 1.5rem;
                margin-bottom: 0.5em;
                padding-top: 1em;

                > img {
                    max-width: 100%;
                    min-width: 100%;
                    height: auto;
                }
            }

            .menu-cta-promo-link-group {
                width: 100%;
            }
        }

        &.hidden {
            animation: navOut 0.5s ease-in;
            animation-fill-mode: forwards;
        }
        &.shown {
            animation: navIn 0.5s ease-out;
            animation-fill-mode: forwards;
        }
        &.enabled {
            pointer-events: auto;
        }
    }
}

.navBg__color {
    &-primary,
    &-light,
    &-transparentlight {
        .headernavigationslidemenu {
            #nav-container {
                color: $dark;

                .nav-links {
                    i {
                        color: $dark;
                    }

                    .nav-item-parent {
                        color: $dark;
                    }
                }

                @include media-breakpoint-down(md) {
                    .mobile-nav-link {
                        color: $dark;

                        &.active[href],
                        &:active[href] {
                            text-decoration-color: $gray-dark !important;
                        }

                        &.selected {
                            color: $dark;
                        }
                    }

                    .social {
                        a {
                            > span > i {
                                color: $dark;
                            }
                        }
                    }

                    .terms {
                        a,
                        span {
                            color: $dark;
                        }
                    }

                    .copyright {
                        color: $dark;
                    }
                }

                .nav-children {
                    > ul {
                        > li {
                            a,
                            button {
                                color: $dark;
                            }
                        }
                    }
                }

                .MenuCtaPromoBlock {
                    a {
                        color: $dark;
                    }

                    .link-list {
                        ul {
                            > li {
                                > a {
                                    color: $dark;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.navBg__color {
    &-secondary,
    &-tertiary,
    &-dark {
        .headernavigationslidemenu {
            #nav-container {
                color: $light;

                .nav-links {
                    i {
                        color: var(--primary);
                    }

                    .nav-item-parent {
                        color: var(--primary);
                    }
                }

                @include media-breakpoint-down(md) {
                    .mobile-nav-link {
                        color: $light;

                        &.active[href],
                        &:active[href] {
                            text-decoration-color: $gray-light !important;
                        }

                        &.selected {
                            color: var(--primary);
                        }
                    }

                    .social {
                        a {
                            > span > i {
                                color: $light;
                            }
                        }
                    }

                    .terms {
                        a,
                        span {
                            color: $light;
                        }
                    }

                    .copyright {
                        color: $light;
                    }
                }

                .nav-children {
                    > ul {
                        > li {
                            a,
                            button {
                                color: $light;
                            }

                            @include media-breakpoint-up(lg) {
                                &:not(:first-child) {
                                    border-top: 2px solid var(--primary);
                                }
                            }
                        }
                    }
                }

                .MenuCtaPromoBlock {
                    a {
                        color: $light;
                    }

                    .link-list {
                        ul {
                            > li {
                                > a {
                                    color: $light;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.navBg__color-primary {
    .headernavigationslidemenu {
        #nav-container {
            background: var(--primary);

            .nav-children {
                > ul {
                    > li {
                        @include media-breakpoint-up(lg) {
                            &:not(:first-child) {
                                border-top: 2px solid $dark;
                            }
                        }
                    }
                }
            }
        }
    }
}

.navBg__color-secondary {
    .headernavigationslidemenu {
        #nav-container {
            background: var(--secondary);
        }
    }
}

.navBg__color-tertiary {
    .headernavigationslidemenu {
        #nav-container {
            background: var(--tertiary);
        }
    }
}

.navBg__color-light {
    .headernavigationslidemenu {
        #nav-container {
            background: $light;

            .nav-children {
                > ul {
                    > li {
                        @include media-breakpoint-up(lg) {
                            &:not(:first-child) {
                                border-top: 2px solid var(--primary);
                            }
                        }
                    }
                }
            }
        }
    }
}

.navBg__color-dark {
    .headernavigationslidemenu {
        #nav-container {
            background: $dark;
        }
    }
}

.headernavigationslidemenu {
    .header__user__button {
        &-primary {
            color: var(--primary);
        }

        &-secondary {
            color: var(--secondary);
        }

        &-tertiary {
            color: var(--tertiary);
        }

        &-light {
            color: $light;
        }

        &-dark {
            color: $dark;
        }
    }
}
