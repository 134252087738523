﻿.SearchBar {
    border: 2px solid;
    width: 100%;
    display: flex;
    padding: 0px;
    margin-bottom: .5em;

    input.SearchBar__input {
        border: none !important;
        outline: 0;
        width: 0;
        line-height: 2rem;
        padding: 0 0.75rem !important;
        width: 100%;
        height: 2.5rem;
        border-radius: 0px;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
            -webkit-appearance: none;
            -moz-appearance: none;
        }

        &::-ms-clear,
        &::-ms-reveal {
            display: none;
            width: 0;
            height: 0;
        }

        &:not([type='checkbox']):not([type='radio']):not([type='submit']):focus {
            border: none !important;
            box-shadow: none !important;
        }
    }

    &__icon {
        cursor: pointer;
        padding: 0px 0.75rem;
        font-size: 1rem;

        .fa-spinner {
            animation: linkSpinner 0.5s linear 0s infinite;
            margin-right: 0.5em;
        }
    }
}
