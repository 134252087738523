﻿.OrderHistory {
    .Table {
        overflow-y: auto;
    }

    &__input {
        border-top: none;
        border-right: none;
        border-left: none;
    }

    .Table .disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    form {
        height: 40px;
    }

    .AccordionTable {
        tbody {
            td {
                padding-right: 20px;

                &:last-child {
                    padding-right: 0.3rem;
                }
            }
        }

        @include media-breakpoint-down('md') {
            tbody {
                td {
                    .btn-sm {
                        padding: 7px 20px;
                    }

                    a.tracking {
                        font-family: var(--tertiary-font);
                    }
                }
            }
        }
    }
}
