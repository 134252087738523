﻿ul.border-left {
    border-left: 2px solid $dark !important;
}

ul.ul-lg {
    font-family: var(--tertiary-font);
    @include font-size(16);
    letter-spacing: 0.25px;
    line-height: 24px;
}

ul.ul-sm {
    font-family: var(--tertiary-font);
    @include font-size(14);
    letter-spacing: 0.29px;
    line-height: 20px;
}
