.NotificationSubscription {
    position: relative;

    &.floating {
        position: absolute;
        right: 0;
        top: -3rem;
        z-index: 2;
    }

    &.bg-none > .NotificationSubscription__notify-link {
        background: none !important;
    }

    &__notify-link {
        pointer-events: auto;
        font-size: 0.75rem;
        text-decoration: underline;
        @include font-size(0.75rem);
        font-weight: 600;
        letter-spacing: 0.03125rem;
        text-transform: none;
    }

    &__popup {
        z-index: 2;
        padding: 1em;

        .Field {
            margin-top: 0px;
            input {
                width: 100%;
                padding: 0.5rem !important;

                &:disabled {
                    opacity: 0.8;
                }
            }

            small {
                margin-bottom: 0.5em;
            }
        }

        &__close {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            button {
                padding: 0px;
                float: right;
            }
        }

        &__title {
            line-height: 1em;
        }

        &__message {
            margin-bottom: 1em;
            animation: fade-in 0.3s ease-out;

            &.error {
                color: $warning;
            }
        }

        &__recaptcha {
            font-size: 0.75em;
            margin-bottom: 0px;
        }

        &__submit {
            margin-bottom: 1em;
        }
    }
}
