﻿.user__menu {
    @include media-breakpoint-down(md) {
        .sub-nav {
            .navbar-toggler {
                float: right !important;
                left: auto;
                padding: 0px;
            }

            .content {
                padding-top: 20px !important;

                h4 {
                    text-transform: none;
                    padding-bottom: 22px;
                    margin-bottom: 0px;
                }

                .user__menu__links {
                    padding: 0px;
                    margin-bottom: 0px;

                    li {
                        padding: 15px 0px;

                        a {
                            @include font-size(21px);
                            text-transform: uppercase;
                            letter-spacing: 2px;
                            font-family: var(--secondary-font);
                        }
                    }
                }

                ul {
                    a.nav-link {
                        @include font-size(16px);
                        text-transform: none;
                        padding: 14px 0px !important;
                    }
                }

                .socials {
                    p {
                        margin-top: 6px;
                    }

                    i.fab,
                    i.far,
                    i.fa {
                        font-size: 30px;
                    }
                }

                .contact {
                    margin-top: 36px;

                    a {
                        @include font-size(30px);
                        font-weight: $font-weight-bolder;
                    }
                }
            }
        }
    }
}
