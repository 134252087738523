﻿.CheckoutPayment {
    .ReadOnlyPayment {
        p {
            @extend .paragraph-3;
        }
    }

    .PayPal {
        &__Buttons {
            max-height: 3em;
            overflow: hidden;
        }
    }

    .CheckoutSection__body .dropdown {
        .card-header {
            padding: 0px;
            background: transparent;

            .btn {
                @include font-size(16px);
                text-transform: none;
                font-weight: $font-weight-bolder;
                padding: 0.5rem 0.7rem;
            }

            button[aria-expanded='true'] {
                i {
                    transform: scaleY(-1);
                    -moz-transform: scaleY(-1);
                    -webkit-transform: scaleY(-1);
                    -ms-transform: scaleY(-1);
                    font-size: 1em;
                }
            }
        }

        .drawer {
            max-height: 10em;
            overflow-y: auto;

            .btn {
                text-transform: none;
                font-weight: bold;
                img,
                .icon {
                    height: 50px;
                    width: 65px;

                    &.generic {
                        width: 68px;
                        height: 44px;
                    }
                }
            }
        }

        .accordion {
            border: 2px solid var(--secondary);
        }
    }
}
