﻿.background__color-primary,
.background-color-primary {
    background-color: var(--primary) !important;
}

.background__color-secondary,
.background-color-secondary {
    background-color: var(--secondary) !important;
}

.background__color-tertiary,
.background-color-tertiary {
    background-color: var(--tertiary) !important;
}

.background__color-light,
.background-color-light {
    background-color: $light !important;
}

.background__color-dark,
.background-color-dark {
    background-color: $dark !important;
}

.background__color-gray-dark,
.background-color-gray-dark {
    background-color: $gray-dark !important;
}

.background__color-gray-secondary,
.background-color-gray-secondary {
    background-color: $gray-secondary !important;
}

.background__color-gray-ghost,
.background-color-gray-ghost {
    background-color: $gray-ghost !important;
}

.background__color-gray-medium,
.background-color-gray-medium {
    background-color: $gray-medium !important;
}
