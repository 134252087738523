.YotpoCustomGalleryHeader {
    margin-bottom: 2em;
    margin-top: 2em;

    &-title {
        font-size: $h3-font-size;
        font-weight: 600;
        font-family: var(--primary-font);
        padding: 0 0.2em;
        padding-bottom: 0.15em;
        margin: 0;
    }

    &-subtitle {
        margin: 0;
        margin-top: 0.5em;
        font-weight: $font-weight-bold;
        font-size: $font-size-sm;
        font-family: var(--tertiary-font);

        i {
            margin-left: 0.2em;
        }
    }
}
