.PagingButtons {
    margin: 0;
    height: 3em;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.btn {
        z-index: 2;
        font-size: inherit;
        width: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $dark;
        justify-content: space-between;
        position: absolute;
        > i {
            font-size: 3rem;
        }

        &.left {
            margin-right: 3em;
            left: 0;
        }

        &.right {
            right: 0;
            margin-left: 3em;
        }

        &[disabled],
        &:disabled,
        &.disabled {
            opacity: 0;
        }

        @media (max-width: 390px) {
            &.left {
                margin-right: 0.5em;
            }

            &.right {
                margin-left: 0.5em;
            }
        }
    }
}
