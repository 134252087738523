﻿@import '../../../../../Common/src/scss/components/react/CartTable.scss';

.CartTable {
    .table {
        .tablehead {
            > div {
                > span {
                    text-transform: none;
                    @include font-size(zebcoToBootstrapFontSize(1.8rem));
                }
            }
        }
    }

    .quickview {
        width: 100%;

        .btn {
            span {
                color: $white;
            }
        }
    }

    .EditProductView {
        &__submit-btns {
            @include media-breakpoint-up(sm) {
                div {
                    flex-grow: unset !important;
                }
            }
        }
    }
}
