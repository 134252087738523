﻿.ProductVideo {
    min-height: 500px;
    color: $white;
    background-color: $gray-secondary;

    h3 {
        text-decoration: none;
    }

    .subheader {
        color: var(--primary);
    }

    i {
        font-size: 60px;
        color: $white;
    }

    .close {
        position: absolute;
        top: 0;
        right: -40px;
        color: $white;

        .fa.fa-times {
            font-size: 30px;
        }
    }

    @include media-breakpoint-down(sm) {
        .close {
            right: 0;
            top: -60px;
        }
    }
}
