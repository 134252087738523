﻿.ProductBadge {
    .badge {
        background: var(--primary);
        border-radius: 0px;
        font-family: var(--primary-font);
        text-transform: uppercase;
        font-size: 1em;
        color: var(--secondary);
    }
}
