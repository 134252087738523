.ContentSearchResult {
    border: 2px solid $gray-secondary;
    text-decoration: none !important;
    color: inherit !important;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: var(--grid-col-size, 360px);
    z-index: 0;
    position: relative;
    padding: 1rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: flex-end;

    &--type-video {
        .ContentSearchResult__playbtn {
            position: absolute;
            z-index: 2;
            font-size: 5rem;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: $light;
        }

        .ContentSearchResult__title {
            z-index: 2;
        }

        .ContentSearchResult--gradientOverlay {
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
            pointer-events: none;
        }

        @media (hover: hover) {
            &:hover {
                .ContentSearchResult__playbtn {
                    color: var(--primary);
                }
            }
        }
    }

    &__backdrop {
        display: none;
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        background: rgba($dark, 0.5);
    }

    @media (hover: hover) {
        &:hover {
            .ContentSearchResult__backdrop {
                display: block;
            }

            .ContentSearchResult__excerpt {
                display: block;
            }
        }
    }

    &__excerpt {
        display: none;
        flex: 1;
        overflow: hidden;
    }

    &--selected {
        &::after {
            z-index: 1;
            content: '';
            position: absolute;
            bottom: calc(-1rem - 1px);
            left: calc(50% - 2rem);
            border-top: 1rem solid var(--primary);
            border-left: 2rem solid transparent;
            border-right: 2rem solid transparent;
        }
    }

    &__badges {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        z-index: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
        padding: 0;
        > .badge {
            margin-top: 0 !important;
            + .badge {
                margin-left: 0.25rem;
            }
        }
    }

    &__image {
        flex: 1 1 0;
        position: relative;
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
    }

    &__title {
        margin-top: 0.5rem;
        > * {
            line-height: 1em;
            font-size: 1.4rem;
        }
    }

    &__bottom-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;

        &__price.a.b.c {
            margin-top: 0 !important;
            margin-right: 1em;
        }

        button.btn:not(.btn-read-more) {
            position: relative;
            bottom: -0.33em;
        }

        &__social-links {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            a {
                &:not(:first-child) {
                    margin-left: 1rem;
                }
                > .Icon {
                    font-size: 2.25rem;
                    color: $light;
                }
            }
        }
    }

    &__fake {
        height: 100%;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: end;
        gap: 0.5rem;
        position: relative;
        opacity: 1;
        @include animate-flicker-light();

        &--image {
            padding: inherit;
            position: absolute;
            width: 100%;
            height: 100%;
            border: none;
            background: $gray-ghost;
        }

        &--title {
            width: 300px;
            height: 50px;
            background-color: $gray-ghost;
            box-shadow: inset 0 -2em 3em $gray-ghost, 0 0 0 2px $gray-ghost, 0.1em 0.1em 0.5em rgb(0 0 0 / 10%);
            margin-left: 1rem;
            border-radius: 2px;
            z-index: 1;
        }

        &--button {
            width: 100px;
            height: 40px;
            background-color: $gray-ghost;
            box-shadow: inset 0 -2em 3em $gray-ghost, 0 0 0 2px $gray-ghost, 0.1em 0.1em 0.5em rgb(0 0 0 / 10%);
            margin-left: 1rem;
            margin-bottom: 1rem;
            z-index: 1;
            border-radius: 2px;
        }
    }
}
