.notificationsubscriptionblock {
    .block-subscription {
        padding-bottom: 0.75rem;

        .row-subscription {
            border: 1px solid var(--primary);
            align-items: center;
            padding: 5px;

            .item-subscription {
                padding: unset;
                @extend .col-md-10;
            }

            .NotificationSubscriptionUnsubscribeButton {
                padding-left: unset;

                @include media-breakpoint-down(sm) {
                    max-width: unset;
                    width: 100%;

                    button {
                        width: 100%;
                    }
                }
            }
        }
    }

    .modal-footer {
        border-radius: 0;
    }
}
