.SearchSortDropdown {
    &__value {
        padding: 0.5em;
    }

    &__button,
    .Dropdown {
        &__button {
            padding: 0.5em 2em;
            min-width: 170px;
        }

        &__drawer.float {
            background-color: $white;
            border-radius: 5px;
            border-color: $gray-ghost;
            z-index: 2;
        }
    }
}
