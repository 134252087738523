.AddressVerificationDrawer {
    &__ListItem {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 1rem;
        margin: 1rem;
        margin-bottom: 0;
        border: 1px solid $dark;
        background-color: rgba($dark, 0.1);
        transition: background-color 250ms ease-in-out;

        &.dotted {
            border-style: dotted;
        }

        @media (hover: hover) {
            &:hover {
                background-color: rgba(0, 0, 0, 0);
            }
        }

        > .icon {
            margin-right: 1rem;
            &.green {
                color: green;
            }
            &.red {
                color: $red;
            }
        }
        > .address {
            flex: 1 0 0%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
    }
    .Drawer__slider {
        padding: 1rem;
    }
}
