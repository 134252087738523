﻿.productProstaffQuoteBlock {
    h5 {
        text-transform: none;
    }

    .text__color-light {
        color: $white;
    }

    .text__color-dark {
        color: $black;
    }

    .text__color-primary {
        color: var(--primary);
    }

    .text__color-secondary {
        color: var(--secondary);
    }

    .text__color-tertiary {
        color: var(--tertiary);
    }

    .underline {
        width: 15%;
        height: 2px;
        background: var(--primary);

        &.left {
            margin-right: 85%;
        }

        &.right {
            margin-left: 85%;
        }

        &.center {
            margin: auto;
        }
    }

    .subheader {
        color: var(--primary);
    }

    div.quote {
        font-family: var(--tertiary-font);
    }

    div.quote:not(.quote-box--no-quote)::before {
        content: '“ \a ';
        display: block;
        position: absolute;
        font-size: 87px;
        top: -55px;
        color: var(--primary);
    }

    i {
        color: var(--primary);

        &.fa-quote-left {
            font-size: 35px;
        }
    }

    .content-links {
        .content-link p {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            color: var(--primary);
            a {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }

            i {
                font-size: 1em;
                margin-right: 5px;
            }
        }
    }
}
