img.anchor-left-top {
    object-position: left top !important;
}
img.anchor-left-middle {
    object-position: left center !important;
}
img.anchor-left-top {
    object-position: left bottom !important;
}
img.anchor-center-top {
    object-position: center top !important;
}
img.anchor-center-middle {
    object-position: center center !important;
}
img.anchor-center-top {
    object-position: center bottom !important;
}
img.anchor-right-top {
    object-position: right top !important;
}
img.anchor-right-middle {
    object-position: right center !important;
}
img.anchor-right-top {
    object-position: right bottom !important;
}
