﻿.SearchResults {
    .Drawer {
        z-index: 51;
        .MobileFiltersMenu__list {
            overflow-y: auto;
        }
    }

    .NoSlider {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 1rem 0;
        position: relative;
    }

    .heading-backdrop {
        height: 8.5rem;
        position: absolute;

        @include media-breakpoint-down(sm) {
            height: 10rem;
        }
    }

    .extra-container {
        position: relative;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .filter-drawer-link {
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }
    }

    .SearchBar {
        position: relative;
    }

    &__left-panel {
        padding: 10%;
        background: $gray-light;

        .SearchFacets {
            padding-bottom: 20px;
            border-bottom: 2px solid lightgray;
            margin-bottom: 30px;

            .FacetFilter {
                padding: 10px 0px;

                label {
                    display: flex;
                    align-items: center;
                }

                .subheader {
                    color: $semi-dark;
                    @include font-size(19px);
                    cursor: pointer;
                    line-height: 0.9em;
                }

                input[type='checkbox'],
                input[type='radio'],
                div.input-checkbox,
                div.input-radio {
                    height: 18px;
                    min-height: 18px;
                    width: 18px;
                    min-width: 18px;
                    background: transparent;
                }

                button.title {
                    width: 100%;
                    padding: 5px 0px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &[aria-expanded='true'] {
                        .fa.fa-chevron-down {
                            transform: scaley(-1);
                        }
                    }
                }

                .dropdown-drawer {
                    padding-top: 10px;
                }

                label {
                    span.subheader {
                        font-weight: 400;
                        @include font-size(1rem);
                        font-weight: 400;
                    }
                }
            }
        }

        .SearchLinks {
            &__link {
                .subheader {
                    color: $semi-dark;
                    @include font-size(1rem);
                    cursor: pointer;
                    font-weight: 400;

                    &.header {
                        cursor: auto;
                        @include font-size(19px);
                        line-height: inherit !important;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .desktop_button {
        button {
            @extend .btn-sm;
            border: 1px solid $gray-light;
        }
    }

    .SortDropdown {
        button[aria-expanded='true'] {
            i.fa.fa-chevron-down {
                transform: scaley(-1);
            }
        }
    }

    .SearchResultTabs {
        .nav-link.active {
            border-bottom: 2px solid var(--primary);
        }

        .mobile_buttons {
            &.container,
            .row {
                height: 34px;
            }

            border: 1px solid $gray-light;

            .border-right {
                border-right: 1px solid $gray-light !important;
            }

            button {
                padding: 0px;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .slick-slider {
            div {
                &:focus {
                    outline: 0;
                }
            }

            .slick-track {
                margin: 0px;
                display: flex !important;

                .slick-slide {
                    border-color: transparent;
                }
            }

            button.left-arrow {
                display: none !important;
            }

            button.right-arrow {
                right: 0px;
                height: 30px;
                width: 30px;
                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25) !important;
                background: $white;
                display: flex !important;
                justify-content: center;
                align-items: center;

                &.slick-disabled {
                    visibility: hidden;
                }

                &:before {
                    content: none;
                }

                i {
                    font-size: 13px;
                    color: $semi-dark;
                }
            }
        }

        .slick-track {
            .slick-slide .tab-nav-item {
                margin: 1rem;
            }
            .slick-slide:first-child .tab-nav-item {
                margin-left: 0;
            }
            .slick-slide:last-child .tab-nav-item {
                margin-right: 0;
            }
        }

        .nav-tabs {
            border-bottom: none;

            .tab-nav-item.hidden {
                visibility: hidden;
            }

            a {
                text-decoration: none;

                @media (hover: hover) {
                    &:hover {
                        cursor: pointer;
                    }
                }

                &.active {
                    border-bottom: 3px solid var(--primary);
                }

                .Icon {
                    margin-top: -2px;
                }
            }
        }

        &__content__result {
            margin-bottom: 32px;

            a.subheader {
                @media (hover: hover) {
                    &:hover {
                        text-decoration: none;
                    }
                }

                span.subheader {
                    @include font-size(19px);
                    text-decoration: underline;
                }

                span.type {
                    @include font-size(15px);
                    line-height: 10px;
                    letter-spacing: 0.5px;
                    border: 1px solid $black;
                    padding-top: 2px;
                    padding-left: 3px;
                    padding-right: 3px;
                }
            }

            .excerpt {
                color: $gray-secondary;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                max-height: 65px;

                p {
                    @include font-size(1rem);
                }
            }

            img {
                height: 150px;
                object-fit: contain;
                cursor: pointer;
            }
        }

        .SearchResultItem {
            .img-overlay {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                background: $black;
                height: 30px;
                width: 30px;
                border-radius: 15px;
                cursor: pointer;

                .far.fa-play-circle {
                    font-size: 33px;
                    color: $white;
                    transform: translateX(-2px);
                }
            }
        }

        .SearchResultProduct {
            .CatalogCard {
                img {
                    height: 209px;
                    width: 209px;
                }

                .subheader {
                    text-align: center;
                }
            }

            &__CrossSite {
                &__Site {
                    height: 100px;
                    width: 150px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }

                    &.selected {
                        border-bottom: 2px solid var(--primary);
                    }
                }
            }
        }

        .loadMore {
            @include font-size(19px);
            font-weight: 500;
            letter-spacing: 0.5px;
            line-height: 16px;

            .spinner-border {
                position: absolute;
                height: 1rem;
                width: 1rem;
                border: 0.2em solid currentColor;
                border-right-color: transparent;
                color: $white;
                left: 49%;
                margin-top: 3px;
            }
        }

        @include media-breakpoint-up(md) {
            .CatalogQuery {
                margin: 0 -15px;
            }
        }
    }

    img.ProductRecommendations__Product {
        a {
            width: 100%;
        }

        &__image {
            width: 100%;
            height: 220px;
        }
    }

    @include media-breakpoint-down(lg) {
        .SearchResultTabs {
            .SortDropdown {
                .dropdown-menu {
                    left: -15px !important;
                    width: 100%;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .SearchLinks {
            &__link {
                .subheader {
                    color: $semi-dark;
                    @include font-size(14px);

                    &.header {
                        @include font-size(1rem);
                    }
                }
            }
        }

        .SearchResultTabs {
            border-bottom: 2px solid $black;

            .SearchResultTabs__content__result {
                margin-bottom: 20px;

                img {
                    height: 100px;
                }
            }

            .excerpt {
                max-height: 55px;

                p {
                    @include font-size(14px);
                }
            }
        }
    }
}
