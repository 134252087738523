﻿body {
    font-family: var(--font-family-base);
}

strong {
    font-weight: $font-weight-bold;
}

span.searchHighlight {
    background: $text-highlight;
}

a.disabled,
a[disabled] {
    opacity: 0.5 !important;
    pointer-events: none;
}

@media (hover: hover) {
    a.wysiwyg-link:hover {
        text-decoration: underline;
        color: #0000ee;
    }
}

a.wysiwyg-link,
a.wysiwyg-link:visited {
    text-decoration: underline;
    color: #0000ee;
}

.display,
h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
    font-weight: $font-weight-bolder;
    font-family: var(--primary-font);
}

.btn > span {
    font-family: var(--secondary-font);
}

p,
ul,
.font__family-tertiary {
    font-family: var(--tertiary-font);
    letter-spacing: 0.25px;
}

.subheader {
    font-family: var(--secondary-font);
    text-transform: uppercase;
}

.text__color-primary,
.color-primary {
    color: var(--primary) !important;
}

.text__color-secondary,
.color-secondary {
    color: var(--secondary) !important;
}

.text__color-tertiary,
.color-tertiary {
    color: var(--tertiary) !important;
}

.text__color-light,
.color-light {
    color: $light !important;
}

.text__color-dark,
.color-dark {
    color: $dark !important;
}

.text__color-gray-dark,
.color-gray-dark {
    color: $gray-dark !important;
}

.text__color-gray-secondary,
.color-gray-secondary {
    color: $gray-secondary !important;
}

.text__color-gray-ghost,
.color-gray-ghost {
    color: $gray-ghost !important;
}

.text__color-gray-medium,
.color-gray-medium {
    color: $gray-medium !important;
}

.text__size_sm {
    @include font-size($font-size-sm);
}

.text__size_lg {
    @include font-size(1rem);
}

p.light-sm {
    font-size: 12px !important;
    color: $gray-secondary;
    font-weight: normal !important;
}

@include media-breakpoint-down('md') {
    h1 {
        @include font-size($h1-mobile-font-size);
    }

    h2 {
        @include font-size($h2-mobile-font-size);
    }

    h3 {
        @include font-size($h3-mobile-font-size);
    }

    h4 {
        @include font-size($h4-mobile-font-size);
    }

    h5 {
        @include font-size($h5-mobile-font-size);
    }

    h6 {
        @include font-size($h6-mobile-font-size);
    }
}
