﻿.ProductGridView {
    &__title {
        color: var(--primary);
        margin: 0 !important;
        margin-top: 2rem !important;
        @include media-breakpoint-up(md) {
            margin-left: 15px !important;
        }
        padding: 0 !important;
        text-transform: uppercase;
    }
}
