﻿@import '../../../../../Common/src/scss/components/react/ChildrenSelector.scss';

.ChildrenSelector {
    .btn {
        color: $red;
        &.selected {
            background-color: $red !important;
            color: $white;
        }
    }

    .btn-disabled {
        pointer-events: auto;

        strong {
            opacity: 0.5;
        }
    }

    button[aria-controls='dropdown-selector'] {
        margin-left: 0;
    }

    &__option {
        width: 100%;
    }
}
