.banner__block {
    .banner__buttons {
        .btn {
            border-color: $white;
            color: $white;

            &:hover {
                color: $red;
                background-color: $white;
            }
        }
    }
}
