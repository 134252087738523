﻿.CatalogQuery {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    flex-direction: column;

    &.Mobile {
        margin-top: 0.5em;
        .CatalogQuery__filters {
            flex-direction: column;
        }
        .CatalogQuery__filter {
            margin-left: 0;
            margin-top: 0.5em;
            padding: 0.25em 0.5em;
        }

        .btn-clear-all {
            padding-top: 0.5em;
        }
    }

    > section {
        display: flex;
        align-items: center;
    }

    &__filters {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    }

    &__label {
        margin-right: 1em;
        font-family: var(--tertiary-font);
        @include font-size(16px);
        min-width: 8%;
    }

    &__filter {
        border-radius: 0.5em;
        padding: 0.5em 0.75em;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: white;
        background: black;
        @include font-size(14px);
        margin-right: 5px;
        margin-top: 5px;

        > .btn-clear {
            margin-right: 0.75em;
            cursor: pointer;
        }
    }

    .btn-clear-all {
        min-width: 16%;
        padding: 0px;
        > span {
            font-family: var(--tertiary-font);
            @include font-size(14px);
            font-weight: bold;
            letter-spacing: 0.3px;
            text-transform: none;
        }
    }
}
