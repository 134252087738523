﻿.customerrewardsactivityblock {
    & .btn {
        @include media-breakpoint-down('sm') {
            width: 100%;
        }
    }

    div > .btn {
        margin-bottom: 1rem;
    }
}
