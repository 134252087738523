$filter-bg-color: var(--secondary) !default;
$filter-fg-color: $light !default;
$filter-font-family: var(--secondary-font) !default;
$filter-font-size: 14px;

.Dropdown {
    display: flex;

    &__container {
        position: relative;
        min-width: 100px;
    }

    &__drawer {
        overflow: hidden;
        width: 100%;

        &.float {
            display: none;
            position: absolute;
            top: 100%;
            left: 0px;

            &.open {
                display: block;
                z-index: 2;
            }
        }

        &.relative {
            transition: height 0.3s ease-out;
        }

        > div {
            padding: 5px 0px; //default padding fro the drawer (nice to have for storybook)
            flex-direction: column;
            display: flex;
        }
    }

    &__drawer,
    &__button {
        border: 1px solid;
    }

    &__button {
        display: flex;
        align-items: center;

        i {
            margin-left: .5em;
        }
    }
}

.FilterDropdown {

    &.isOpen {
        background: $filter-bg-color;
        
        >.Dropdown__button {
            color: var(--primary);
            border: none;
            
            > i {
                color: $filter-bg-color;
            }
        }

        .Dropdown__drawer {
            overflow: visible;
            border: none;
            background-color: $filter-bg-color;
            position: absolute;

            > div {
                overflow-y: visible;
                height: auto;
            }
        }
    }

    .Dropdown__button {
        white-space: nowrap;
        padding: 0.5em 1em;
        width: 100%;
        text-align: left;
    }
}
