﻿.prostaffQuoteBlock {
    padding-top: 30px;
    padding-bottom: 30px;

    .big-banner {
        background-size: cover;
        background-repeat: no-repeat;
        padding-top: 70px;
        padding-bottom: 70px;
        margin-left: 0;
        margin-right: 0;
    }

    .quote-box {
        position: relative;
        margin-bottom: 49px;

        @include media-breakpoint-up(md) {
            width: 80%;
        }

        &__color-light,
        .text__color-light {
            color: $light !important;
            &:before {
                color: $light !important;
            }

            &:after {
                background-color: $light;
            }

            &.underline {
                background-color: $light;
            }
        }

        &__color-dark,
        .text__color-dark {
            color: $dark !important;
            &:before {
                color: $dark !important;
            }

            &:after {
                background-color: $dark;
            }

            &.underline {
                background-color: $dark;
            }
        }

        &__color-primary,
        .text__color-primary {
            color: var(--primary) !important;
            &:before {
                color: var(--primary) !important;
            }

            &:after {
                background-color: var(--primary);
            }

            &.underline {
                background-color: var(--primary);
            }
        }

        &__color-secondary,
        .text__color-secondary {
            color: var(--secondary) !important;
            &:before {
                color: var(--secondary) !important;
            }

            &:after {
                background-color: var(--secondary);
            }

            &.underline {
                background-color: var(--secondary);
            }
        }

        &__color-tertiary,
        .text__color-tertiary {
            color: var(--tertiary) !important;
            &:before {
                color: var(--tertiary) !important;
            }

            &:after {
                background-color: var(--tertiary);
            }

            &.underline {
                background-color: var(--tertiary);
            }
        }

        &:not(.quote-box--no-quote)::before {
            content: '“ \A';
            display: block;
            position: absolute;
            left: -7px;
            font-size: 149px;
            top: -90px;
        }

        .underline {
            width: 40px;
            height: 2px;
            margin-left: 5px;
            display: inline-flex;
        }

        h2 {
            font-family: var(--primary-font);
            font-size: 35px;
            font-weight: bold;
            letter-spacing: 0.67px;
            line-height: 40px;
            margin-bottom: 25px;
        }

        p {
            @include font-size(19px);
            letter-spacing: 0.25px;
            line-height: 28px;
            margin-bottom: 0px;
            display: inline;
        }
    }

    &__title {
        color: #fff;
        font-family: var(--primary-font);
        @include font-size(83px);
        line-height: 75px;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 12px;
    }

    &__protitle {
        font-family: var(--secondary-font);
        @include font-size(23px);
        font-weight: 500;
        letter-spacing: 2px;
        line-height: 19px;
        margin-bottom: 12px;
        margin-top: 12px;
    }

    &__bottom {
        margin-top: 51px;
        &__description {
            color: $light;
            font-family: var(--tertiary-font);
            font-size: 16px;
            letter-spacing: 0.25px;
            line-height: 24px;
        }

        .btn-lg {
            padding: 22px 45px;
            letter-spacing: 1.92px;
            font-weight: normal;
        }
    }

    @include media-breakpoint-down('md') {
        p,
        div,
        h1,
        h2,
        h5,
        div.quote-box {
            &.mobile__text__color-light {
                &::before {
                    color: $light !important;
                }
                color: $light !important;
                &.underline {
                    background-color: $light !important;
                }
            }

            &.mobile__text__color-dark {
                &::before {
                    color: $dark !important;
                }
                color: $dark !important;
                &.underline {
                    background-color: $dark !important;
                }
            }

            &.mobile__text__color-primary {
                &::before {
                    color: var(--primary) !important;
                }
                color: var(--primary) !important;
                &.underline {
                    background-color: var(--primary) !important;
                }
            }

            &.mobile__text__color-secondary {
                &::before {
                    color: var(--secondary) !important;
                }
                color: var(--secondary) !important;
                &.underline {
                    background-color: var(--secondary) !important;
                }
            }

            &.mobile__text__color-tertiary {
                &::before {
                    color: var(--tertiary) !important;
                }
                color: var(--tertiary) !important;
                &.underline {
                    background-color: var(--tertiary) !important;
                }
            }
        }

        .big-banner {
            background-image: unset !important;
            height: auto !important;
            padding-top: 80px;
            padding-bottom: 80px;
        }

        .quote-box {
            margin-bottom: 21px !important;
        }

        .quote-box p,
        &__protitle {
            font-size: 16px;
            line-height: 24px;
        }

        &__title {
            font-size: 38px;
            margin-bottom: 14px;
            line-height: 34px;
        }

        .big-banner_mobile {
            background-size: contain;
            height: auto;
        }

        &__bottom {
            margin-top: 12px;
        }
    }
}
