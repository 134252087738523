.DropdownMenuSelector {
    .dropdown-menu.show {
        border-radius: 0px;
        max-height: $line-height-base * 6em;
        overflow-y: auto;

        a.selected {
            background: $gray-ghost;
        }
    }

    .btn {
        background: $gray-ghost;
        border: none;
    }

    .btn.btn-primary {
        background: var(--primary);
    }
}
