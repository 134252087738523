.Spinner {
    user-select: none;
    display: inline-block;
    position: relative;
    width: 8em;
    height: 8em;
    div {
        display: inline-block;
        position: absolute;
        left: 0.8em;
        width: 1.6em;
    }
    div:nth-child(1) {
        left: 0.8em;
        animation-delay: -0.24s;
    }
    div:nth-child(2) {
        left: 3.2em;
        animation-delay: -0.12s;
    }
    div:nth-child(3) {
        left: 5.6em;
        animation-delay: 0;
    }
    .bar {
        background-color: var(--primary);
    }
}
