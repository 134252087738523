.Form__MainBody {
    .Form__Element {
        .FormTextbox__Input,
        .FormSelection select {
            display: block;
            width: 100%;
        }

        textarea.FormTextbox__Input {
            height: 10rem;
            padding: 10px;
        }

        .Form__Element__Caption {
            @include font-size(zebcoToBootstrapFontSize(1.8rem), true);
        }
    }
}

input.form-control {
    font-size: 1rem;
}
