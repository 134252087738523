﻿.social {
    &__link {
        line-height: 35px;
        display: inline-flex;
        align-items: center;
        &:not(:first-child) {
            margin-left: 8px;
        }
    }

    &__icon {
        font-size: 30px !important;
        color: $white;
    }
}

@include media-breakpoint-down('xs') {
    .social {
        &__link {
            &:not(:first-child) {
                margin-left: 1px;
            }
        }
    }
}