.TabbedContent {
    display: flex;
    gap: spacing(2);

    &__nav {
        &-right.btn,
        &-left.btn {
            padding: 0px;
            position: absolute;
            height: 100%;
            display: flex;
            align-items: center;
        }

        &-left.btn {
            left: -15px;
        }

        &-right.btn {
            left: 100%;
        }
    }

    &--layout {
        &-vertical {
            flex-direction: row;
        }

        &-horizontal {
            flex-direction: column;
        }
    }

    &__tab {
        &.btn {
            padding: 1rem;
        }
    }

    &__tabs {
        display: flex;
        padding-bottom: 0.5rem;
        flex-wrap: wrap;

        &--container {
            position: relative;
        }

        &.tabs {
            &--gap {
                @for $i from 1 through 10 {
                    &-#{$i} {
                        gap: spacing($i);
                    }
                }
            }

            &--layout {
                &-vertical {
                    flex-direction: column;
                }

                &-horizontal {
                    flex-direction: row;
                }
            }

            &--scrollable {
                flex-wrap: nowrap;
                @include branded-scroll-x(none, $gray-ghost, 0.5rem);
            }
        }
    }

    &__contents {
        @keyframes fade-in {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }

        > * {
            animation: fade-in 0.5s ease-out;
        }
    }
}
