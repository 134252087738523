﻿.ButtonSelector {
    .button-group {
        height: 2.25em;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;

        @include media-breakpoint-down(md) {
            justify-content: center;
        }
        button.selector-btn {
            @include media-breakpoint-down(md) {
                font-size: 12px !important;
            }
            font-size: 14px !important;
            max-width: 6.67em;
            min-width: 6.67em;
            flex-shrink: 1;
            border-width: 2px !important;
            border-style: solid !important;
            display: flex;
            align-items: center;
            justify-content: center;
            &.selected {
                border-right-width: 2px !important;

                + button.selector-btn {
                    border-left-width: 0 !important;
                }
            }

            &:not(:last-child):not(.selected) {
                border-right-width: 0 !important;
            }
        }
    }
}
