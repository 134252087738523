﻿.ProductBuyInBulk {

    .ProductInput {
        justify-content: center;
        align-content: center;
        margin-top: 0 !important;
    }

    .bibProdIn {
        display: flex;
        gap: 50px;
    }

    .bibInputContainer {
        width: 44px;
        align-content: center !important;
    }

    .bibBtnContainer {
        justify-content: center !important;
        padding-top: 4px !important;
        padding-bottom: 4px !important;
        height: 100% !important; 
    }

    .bibAddCartbtn {
        align-content: center !important;
        justify-content: center !important;
        margin-top: auto !important;
        height: 100% !important;
        align-items: center !important;
    }
}
