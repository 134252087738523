﻿.commonproduct {
    .card {
        width: 175px;
        float: left;
        /* background-size: cover; */
        height: 300px;
        margin-right: 10px;
        padding: 10px 10px;
        font-size: 14px;
        font-weight: 600;
    }

    .card.slick-slide img {
        display: block;
        text-align: center;
        margin: auto;
        width: 100%;
        height: 140px;
    }

    .card h1 {
        color: #000000;
        font-size: 13px;
        letter-spacing: 0.45px;
        line-height: 20px;
        font-weight: 600;
        border-top: 1px solid #eee;
        margin-top: 14px;
        padding: 10px 0 0 0;
    }

    .card .buy-buttton a {
        font-size: 10px;
        font-weight: 400;
        text-transform: uppercase;
    }

    .prostaffPage .slick-next:before,
    .prostaffPage .slick-prev:before {
        font-size: 40px;
        color: #ffc627;
        position: relative;
        left: 12px;
        z-index: 999999999;
    }

    button.slick-next.slick-arrow.slick-disabled {
        position: absolute;
        right: 36px;
    }

    .slick-next {
        right: 36px;
    }
}
