﻿.alert {
    &:not(.popup-alert) {
        background: transparent;
    }

    font-family: var(--tertiary-font);
    @include font-size(16);
    letter-spacing: 0.25px;
    line-height: 24px;
}
