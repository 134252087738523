﻿.DropdownSelector {
    position: relative;
    padding: 0;

    > button p {
        @extend .paragraph-3;
        font-weight: $font-weight-bolder;
        letter-spacing: 0.0157em;
    }

    .btn {
        padding: 0px;
        background: transparent;
        text-transform: none;
    }

    .collapse {
        button.dropdown {
            opacity: 0;
        }

        &.show {
            button.dropdown {
                transition: opacity 0.4 ease;
                opacity: 1;
            }
        }
    }

    .collapsing {
        position: initial;

        button.dropdown {
            opacity: 0;
            transition: opacity 0.3s ease;
        }
    }

    button.dropdown {
        height: 36px;
        max-width: 36px;
        padding: 0px !important;
        margin: 0px !important;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2) !important;
        background: transparent !important;

        i {
            font-size: 13px;
            margin: 0px 0px 0px 2px !important;
        }
    }

    .btn-toolbar {
        overflow: hidden;

        .btn-group {
            .btn.show-all {
                box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2) !important;
                background: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                max-width: 50px;
            }

            button {
                flex: 0 0 auto;
                padding: 10px 15px;
                margin-right: 12px;
                background: $gray-ghost;
                border: none;
                max-width: 100%;

                &.disabled {
                    opacity: 0.5;
                }

                &:last-child {
                    margin-right: 0px;
                }

                > p {
                    font-family: var(--secondary-font) !important;
                    @include font-size(16px);
                    letter-spacing: 1.5px;
                    margin-bottom: 0px;
                }

                &.selected {
                    background-color: var(--primary);

                    &.oos {
                        background-color: $gray-ghost;
                        border: solid 2px $black;
                        padding: 8px 13px; //adjusting for additional margins from border.
                        color: $dark;
                    }
                }
            }
        }
    }

    .fa {
        font-size: 13px;

        &:last-child {
            margin-left: 20px;
        }

        &:first-child {
            margin-right: 20px;
        }
    }

    @include media-breakpoint-down(sm) {
        .btn-group {
            justify-content: center;

            button {
                margin-right: 6px;
            }
        }
    }
}
