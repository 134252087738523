@import '../../../../../Common/src/scss/components/react/AddressVerificationDrawer.scss';

.AddressVerificationDrawer {
    color: $dark;

    &__ListItem {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 1rem;
        margin: 1rem 0 !important;
        margin-bottom: 0;
        border: 1px solid $dark;
        background-color: rgba($dark, 0.1);
        transition: background-color 250ms ease-in-out;

        &.dotted {
            border-style: dotted;
        }

        @media (hover: hover) {
            &:hover {
                background-color: rgba(0, 0, 0, 0);
            }
        }

        > .icon {
            margin-right: 1rem;

            &.green {
                color: green;
            }

            &.red {
                color: $red;
            }
        }

        > .address {
            flex: 1 0 0%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
    }

    .Drawer__slider {
        padding: 2rem !important;

        &.anchor-right,
        &.anchor-left {
            max-width: 100vw !important;
            min-width: auto !important;
            width: 29rem !important;
        }

        h2, h3, h3, h4, h5, h6 {
            font-weight: bolder;
            font-family: var(--primary-font);
            font-size: 2.4em;

            @include media-breakpoint-down(md) {
                font-size: 2em;
            }
        }
    }
}