@import '../../../../../Common/src/scss/components/react/InlineCart.scss';

.InlineCart {
    z-index: 75;
}

.CartListing {
    background-color: $light;

    &__total {
        font-family: var(--tertiary-font);
        font-weight: bold;
        letter-spacing: 1px;
        border-top: 1px solid $dark;

        .price {
            font-size: 1.2em;
            .amount {
                letter-spacing: 0.05em;
                &.strike {
                    font-weight: 300;
                }
            }
        }

        .info {
            font-size: 0.875em;
            font-weight: 400;
        }
    }

    &__checkout + .CartItemList {
        margin-top: 1rem;
        border-top: 1px solid $dark;
    }
}

.CartListing,
.VariantSelector {
    background: $light;

    .header {
        .btn-close {
            @media (hover: hover) {
                &:hover {
                    color: var(--primary);
                }
            }
        }
        h6 {
            font-family: var(--primary-font);
            font-size: 2.5em;
            font-weight: bold;
            letter-spacing: 0.0375em;
        }
    }

    input.NumberInput {
        border-color: $dark !important;
        color: $dark !important;
        &:not([disabled]):focus {
            border-color: $dark !important;
            color: $dark !important;
        }
    }

    .alert {
        font-weight: 200;
    }
    .alert-danger {
        font-size: 0.875rem;
    }
}

.CartItem {
    &:not(:first-child) {
        border-top: 1px solid $dark;
    }

    .ProductBadge {
        .badge {
            font-family: var(--tertiary-font);
            letter-spacing: 1px;
            font-weight: bold;
            font-size: 0.625rem;
            border: none;
            padding: 0.25em !important;
            &:first-child {
                padding-left: 0;
                padding-bottom: 0;
            }
        }
    }

    &__name {
        color: $dark;
        -webkit-text-fill-color: $dark;
        font-family: var(--tertiary-font);
        font-weight: bold;
        font-size: 1.1em;
        letter-spacing: 0.0625em;
        width: calc(100% - 1.67em);

        &:not([disabled]) {
            @media (hover: hover) {
                &:hover {
                    color: var(--primary);
                }
            }
        }

        &.disabled {
            opacity: 1 !important;
        }
    }

    &__col.left {
        p {
            font-family: var(--tertiary-font);
            font-weight: 300;
            font-size: 0.75em;
        }
    }

    .btn-del {
        font-size: 1.1em;
        margin-top: 0;
        top: 1em;

        @media (hover: hover) {
            &:hover {
                color: var(--primary);
            }
        }
    }

    &__price {
        .strike {
            font-weight: 100;
        }
        p {
            font-family: var(--tertiary-font);
            font-size: 1em;
            font-weight: 300;
            letter-spacing: 0.05em;
        }
        .reduced-price {
            color: red !important;
        }
    }

    &__additional-info {
        display: flex;
        justify-content: flex-end;
        p {
            font-family: var(--secondary-font);
            font-size: 0.875em;
            letter-spacing: 0.07em;
            padding-top: 1em;
        }
    }

    &__table {
        margin-top: 0.5em;
        th,
        td {
            font-family: var(--tertiary-font);
            font-size: 0.875em;
            font-weight: 300;
            letter-spacing: 0.07em;
            line-height: 1.5em;
        }
    }

    .ProductGallery {
        &.disabled {
            pointer-events: none;
        }
    }
}

.InlineCart.dark,
.MultiPageCheckout__summary.dark {
    .CartListing__total {
        color: var(--primary);
        border-top: 1px solid color-mix(in srgb, var(--primary), transparent 80%);

        .price {
            .amount {
                color: var(--primary);
            }
        }
    }

    .CartListing__footer {
        color: $light;
    }

    .CartListing__checkout + .CartItemList {
        border-top: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
    }
}

.InlineCart.dark > .CartListing,
.InlineCart.dark > .VariantSelector,
.MultiPageCheckout__summary.dark > .CartListing,
.MultiPageCheckout__summary.dark > .VariantSelector {
    background: #1d1b1c;

    .header {
        .btn-close {
            color: $light;

            @media (hover: hover) {
                &:hover {
                    color: var(--primary);
                }
            }
        }

        h6 {
            color: $light;
        }
    }

    input.NumberInput {
        background-color: rgba($gray-ghost, 0.1);
        border-color: color-mix(in srgb, var(--primary), transparent 80%) !important;
        color: $light !important;

        &:not([disabled]):focus {
            border-color: var(--primary) !important;
            color: $light !important;
        }
    }

    .NumberInput-btn {
        color: $light;
    }

    .alert {
        color: $light;
    }
}

.InlineCart.dark,
.MultiPageCheckout__summary.dark {
    .CartItem:not(:first-child) {
        border-top: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
    }

    .ProductBadge {
        .badge {
            color: var(--secondary);
        }
    }

    .CartItem__name {
        color: var(--primary);
        -webkit-text-fill-color: var(--primary);

        &:not([disabled]) {
            @media (hover: hover) {
                &:hover {
                    color: $light;
                }
            }
        }
    }

    .CartItem__col.left {
        p {
            color: var(--primary);
            background: $dark;
        }
    }

    .btn-del {
        color: $light;

        @media (hover: hover) {
            &:hover {
                color: var(--primary);
            }
        }
    }

    .CartItem__price {
        .strike {
            font-weight: 100;
        }
        p {
            color: var(--primary);
        }
    }

    .CartItem__additional-info {
        p {
            color: var(--primary);
        }
    }

    .CartItem__table {
        th,
        td {
            color: var(--primary);
        }
        th {
            color: $light;
        }
    }
}
