@import '../../../../../Common/src/scss/components/react/SearchSortDropdown.scss';

.SearchSortDropdown {
    &__value,
    &__button,
    .Dropdown__button {
        letter-spacing: 1.5px;
        font-size: 1rem;
    }
}
