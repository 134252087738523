.LocationSearch {
    display: flex;
    align-items: stretch;
    position: relative;
    z-index: 1;
    font-size: 1rem;

    .locateBtn + input {
        padding-left: 2.5em !important;
    }

    input {
        padding-left: 0.67em !important;
        padding-right: 2.5em !important;
        width: 30em !important;
        text-overflow: ellipsis;
        background-color: $white;
        font-family: var(--primary-font);
        font-size: 1rem;
        font-weight: 300 !important;
    }

    button.searchBtn {
        padding-left: 0.5em !important;
        padding-right: 0.5em !important;
        background: $white;
        position: absolute;
        top: 2px;
        bottom: 2px;

        &.locateBtn {
            left: 2px;
        }
        &.clearBtn {
            right: 2px;
        }
    }
}

.StoreLocator {
    overflow: hidden;
    height: 100%;
    max-height: 100%;
    display: flex;
    width: 100%;
    align-items: stretch;
    position: relative;

    &__list {
        width: 100%;

        @include media-breakpoint-up('lg') {
            min-width: 30em;
            max-width: 30em;
        }

        .LocatorList {
            display: flex;
            flex-direction: column;
            background: $gray-dark;

            .LocationSearch {
                margin: 1em auto;
            }

            &__filters {
                padding: 0 2em 1em 2em;

                p {
                    margin: 0;
                }
                h2 {
                    font-size: 2em;
                    color: $white;
                }
                &__current-location {
                    display: flex;
                    button {
                        color: $white;
                        padding: 0.25em 0.75em;
                    }
                    p {
                        color: $white;
                        flex: 1 1 0%;
                        line-height: 1.67em;
                    }
                }

                &__radius-select {
                    margin-bottom: 1em;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    h6 {
                        font-weight: 200;
                        color: $white;
                        padding: 0;
                        margin: 0;
                    }
                    &__button {
                        @extend .btn, .btn-sm;
                        padding: 0.5em 1em;
                        text-transform: none;

                        &--active {
                            @extend .btn-primary;
                        }

                        &--inactive {
                            @extend .btn-dark;
                        }
                    }
                }

                &__radius-clear {
                    display: flex;
                    align-items: center;
                    color: $light;
                    stroke: $light;
                    font-size: 0.8rem;
                    text-transform: uppercase;
                    font-weight: bolder;

                    svg.Icon {
                        margin: 0 0.5rem 0 0.3rem;
                    }

                    &:hover {
                        color: $gray-medium;
                        stroke: $gray-medium;
                    }
                }
            }
            &__divider {
                background: $gray-secondary;
                padding: 1em 2em;
                h4 {
                    margin: 0;
                    color: $white;
                    font-size: 1.2em;
                    font-weight: 600;
                }
            }
            &__locations {
                position: relative;
                flex: 1 1 0%;
                overflow-x: hidden;
                overflow-y: scroll;
                background: $gray-secondary;
                > ul {
                    margin: 0;
                    padding-inline-start: 0;
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    > li {
                        padding: 1em 2em;
                        &.no-results {
                            color: $gray-ghost;
                            font-style: italic;
                        }
                        &:not(.no-results) {
                            cursor: pointer;
                            display: flex;
                            flex-direction: row;
                            border-bottom: 1px solid $gray-ghost;
                            display: flex;
                            flex-direction: row;
                            background: $white;

                            > .marker-icon {
                                display: flex;
                                flex-direction: row;
                                align-items: flex-start;
                                justify-content: center;
                                padding-right: 1em;
                                > i {
                                    font-size: 2em;
                                }
                            }
                            > .details {
                                flex: 1 1 0%;
                                overflow-wrap: anywhere;
                                h4 {
                                    font-size: 1.2em;
                                }
                                p {
                                    margin: 0;

                                    @media (max-width: 576px) {
                                        font-size: 0.7rem;
                                    }
                                }
                                .badge {
                                    font-size: 0.9rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__map {
        flex: 2 2 0%;

        .LocatorMap {
            .ResearchBar {
                position: absolute;
                z-index: 1;
                bottom: 1.5em;
                left: 2px;
                right: initial;
                width: auto;
                margin: 0;
                > .alert {
                    margin: 0 !important;
                }
            }

            .LocationSearch {
                position: absolute;
                left: 2px;
                top: 10px;
            }

            .ClusterPin {
                cursor: pointer;
                border-radius: 50%;
                font-size: 1em !important;
                border: none;
                background: var(--primary);
                color: var(--secondary);

                & .pointCount {
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: center;
                    font-weight: 600;
                }

                &.small {
                    height: 35px;
                    width: 35px;
                }

                &.medium {
                    height: 40px;
                    width: 40px;
                }

                &.large {
                    height: 45px;
                    width: 45px;
                }
            }
        }
    }
}
