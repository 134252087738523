﻿@import '../../../../../Common/src/scss/components/react/SearchResults.scss';

.search_results_heading {
    font-family: $special-font;
    color: var(--secondary);
    text-transform: uppercase !important;
}

.SearchResults {
    .SearchBar {
        display: none;
    }

    .SortDropdown {
        button {
            font-size: 0.825rem !important;
        }

        .dropdown-menu {
            .btn-sm {
                font-size: 0.825rem !important;
            }
        }
    }

    &__left-panel {
        .SearchFacets {
            .FacetFilter {
                label {
                    margin: 0.75rem 0;
                }

                button {
                    &.subheader {
                        font-family: var(--primary-font);
                    }
                }
            }
        }
    }

    .SearchResultProduct {
        .CatalogCard {
            &__links {
                flex-direction: column;
                li {
                    margin-left: 0px;
                }
            }
        }
    }

    .subheader {
        text-transform: uppercase !important;
    }

    .CatalogCompare {
        padding: 0 !important;
    }

    .slick-slider {
        visibility: hidden;
    }
}
