@import '../../../../../Common/src/scss/components/react/PureTip.scss';

.PureTip {
    font-size: 0.875rem;

    &.danger {
        .PureTip__content {
            border: 1px solid $danger;
            background: $light;
            color: $danger;
        }
    }
    &.success {
        .PureTip__arrow {
            border-color: $dark;
        }
        .PureTip__content {
            background: $success;
            border: 1px solid $dark
        }
    }
}
