﻿.ProductGridCell {
    padding: 0 !important;
    margin: 0 !important;

    &__container {
        padding: 1rem;
        margin: 1rem;
        border: 1px solid var(--primary);
        position: relative;
    }

    &__price {
        margin-top: 1rem;
    }

    &__input {
        height: 2em !important;
        position: relative;

        .num-input {
            input {
                padding: 0px 1rem !important;
            }
        }

        &.oos {
            .btn {
                height: 100%;
                padding: 0;
            }
        }
    }

    &__name {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        height: 3em;
    }

    &__name,
    &__price > * {
        color: var(--primary);
        text-align: left !important;
    }

    &__top-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: -0.25rem;
        justify-content: flex-end;
        height: 1.5rem;

        > .ProductBadge {
            justify-content: flex-start !important;
            display: flex;
            flex-direction: row;
            flex: 1 1 0;
            margin-bottom: 0;
            margin-top: 0;
            margin-right: 1rem;

            .badge {
                margin: 0 !important;
                margin-right: 0.25rem !important;
            }
        }
    }
}
