﻿.newsletter__subscription {
    background-color: $gray-dark;
    padding-top: 20px;
    padding-bottom: 20px;

    .alert-custom,
    .alert-danger {
        color: $white !important;
        background-color: $gray-dark !important;
        border-color: var(--primary) !important;
    }

    label {
        color: $white !important;
    }

    p {
        color: $white !important;
    }

    button[type='submit'],
    input[type='email'],
    input[type='phone'] {
        max-height: 40px;
        border: none;
    }

    button[type='submit'] {
        padding: 14px 17px;
        max-width: 158px;
    }

    input[type='email'],
    input[type='phone'] {
        max-width: 337px;
    }

    input[type='email']::-webkit-input-placeholder,
    input[type='phone']::-webkit-input-placeholder {
        @include font-size(16);
    }

    input[type='email']::-moz-placeholder,
    input[type='phone']::-moz-placeholder {
        @include font-size(16);
    }

    input[type='email']:-moz-placeholder,
    input[type='phone']:-moz-placeholder {
        @include font-size(16);
    }

    input[type='email']:-ms-input-placeholder,
    input[type='phone']:-ms-input-placeholder {
        @include font-size(16);
    }

    @include media-breakpoint-down('sm') {
        .subheader,
        input.form-control,
        button[type='submit'] {
            font-size: 16px;
        }

        label {
            margin-bottom: 0.5rem;
        }

        button[type='submit'],
        input[type='email'],
        input[type='phone'] {
            height: 50px;
        }

        button[type='submit'] {
            padding: 8px 17px;
        }

        input[type='email']::-webkit-input-placeholder,
        input[type='phone']::-webkit-input-placeholder {
            @include font-size(12);
        }

        input[type='email']::-moz-placeholder,
        input[type='phone']::-moz-placeholder {
            @include font-size(12);
        }

        input[type='email']:-moz-placeholder,
        input[type='phone']:-moz-placeholder {
            @include font-size(12);
        }

        input[type='email']:-ms-input-placeholder,
        input[type='phone']:-ms-input-placeholder {
            @include font-size(12);
        }
    }
}
