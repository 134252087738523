.adventure-panels {
    .adventure-panels__overlay {
        z-index: $zindex-fixed;
        background-color: $gray-light;
    }

    .adventure-card-title {
        @include font-size(zebcoToBootstrapFontSize(2.1rem));
        font-weight: 900;
        a {
            color: var(--secondary);
        }
    }

    .adventure-card-subtitle {
        color: $adventure-card-gray;
    }

    .adventure-card-body {
        background-color: $white;
    }

    .adventure-panels__heading {
        h2 {
            @include font-size(zebcoToBootstrapFontSize(3.2rem));
            text-transform: uppercase;
            border-bottom: none;
        }

        p {
            @include font-size(zebcoToBootstrapFontSize(1.8rem));
        }
    }
}
