﻿.OrderDetail {
    .divider {
        height: 2px;
        background: $black;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    &__address {
        &__shipment {
            margin-bottom: 1rem;

            p {
                @extend .paragraph-2;
                margin-bottom: 0px;
            }

            a.tracking {
                font-family: var(--tertiary-font);
            }
        }
    }

    .CartTable {
        margin-bottom: 0px;
    }

    .PaymentPreview,
    .ReadOnlyShipment {
        p {
            @extend .paragraph-2;
            margin-bottom: 0px;
        }
    }

    .OrderShipmentStatus {
        padding: 8px 0px 0px 8px !important;
        font-weight: bold;
    }
}
