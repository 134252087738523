﻿.menucta__links {
    margin: 0;
    padding: 0;
    overflow: hidden;

    .menulink__item {
        a {
            color: black !important;

            @media (hover: hover) {
                &:hover {
                    color: var(--primary) !important;
                    text-decoration: none;
                }
            }

            &:focus {
                color: var(--primary) !important;
                text-decoration: none;
            }
        }

        .title {
            font-family: var(--secondary-font);
            font-size: 19px;
            letter-spacing: 2px;
            line-height: 19px;
        }

        .subtitle {
            font-size: 14px;
            letter-spacing: 0.29px;
            line-height: 20px;
        }

        > a {
            width: 100%;
        }

        > a > div.container {
            padding: 0px;
        }

        .text-left > .title {
            white-space: normal;
        }
    }
}

.menucta__horizontal {
    margin-bottom: 20px;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;

    .menucta__links {
        margin: 0;
        padding: 0;
        justify-content: flex-start;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 0px 15px;

        .menulink__item {
            flex: 0 0 25%;
            justify-content: center;
            display: flex;
            padding: 10px 0px;

            .menulink_image {
                text-align: center;
                display: flex;
                align-items: center;

                > img {
                    margin: auto;
                }
            }
        }
    }
}

.menucta__vertical {
    padding: 0px;
    margin-top: 40px;

    .menucta__links {
        list-style-type: none;
        margin-top: 10px;

        .menulink__item {
            margin-top: 15px;
            margin-bottom: 25px;

            .menulink_image {
                text-align: center;

                > img {
                    margin: auto;
                }
            }
        }
    }
}

/* .menucta__action { } */
