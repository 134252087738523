.SearchBlock {
    padding: 0px 1rem 0px 1rem; //Used to provide default padding to SearchBlock while implemented in 100vw

    .searchLoader {
        position: absolute;
        z-index: 9999;
        top: 6rem;
    }

    .heading-backdrop {
        height: 11.5rem;
        position: absolute;
        z-index: -1;
        @include media-breakpoint-up(md) {
            height: 8.7rem;
        }
    }

    .ResultsGrid {
        border: unset; // reverting default
        justify-content: center;

        .ProductListView {
            padding-left: 0;
            padding-right: 0;
        }

        &__result {
            border: none; //reverting border style to style on Card types instead
        }

        grid-template-columns: repeat(auto-fill, minmax(300px, 360px));
        grid-auto-rows: minmax(300px, max-content);
    }

    .SearchBar__bar {
        margin-top: 10px;
    }

    // Used for center result items with max width applies
    > div.Section__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0.5em;
    }

    // Writing containing widths while infinite scroll takes up 100vw by default
    .InfiniteScroll {
        max-width: 100%;

        @include media-breakpoint-up(xl) {
            max-width: 1600px;
        }
    }

    &__quickview {
        grid-column: 1 / -1;
        margin: 0 0 0 0 !important;
        animation: 300ms openQuickview ease-in-out;
        &--hide {
            animation: 300ms closeQuickview ease-in-out;
            overflow: hidden;
        }

        @include media-breakpoint-down(lg) {
            margin: 1rem 0 0 0 !important;
        }
        @include media-breakpoint-down(sm) {
            margin: 2rem 0 0 0 !important;
        }

        &__input {
            max-width: 30rem !important;
        }
    }

    &__navigation {
        min-height: 133px;

        @include media-breakpoint-down(md) {
            min-height: 169px;
        }

        .Dropdown__button {
            svg.Icon {
                stroke: $black;
                stroke-width: 4px;
            }
        }

        .Section {
            margin: 1rem 0 1rem 0 !important; // overriding default nested section margin
            &__sidebarLeft {
                padding-top: 0.5rem;
                padding-right: 0.5rem;

                @include media-breakpoint-up(lg) {
                    padding-right: 3rem;
                }
            }

            &__sidebarRight {
                padding-left: 0;

                > div {
                    display: flex;
                    justify-content: space-between;
                }

                @include media-breakpoint-up(sm) {
                    padding-left: 0.5rem;
                }

                @include media-breakpoint-up(md) {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                }

                @include media-breakpoint-up(lg) {
                    padding-left: 1rem;
                }
            }
        }
    }

    .SearchLinks {
        display: flex;
        flex-direction: column;

        &__Link {
            flex-grow: 1;
            margin-bottom: 10px;
        }
    }

    .ContentDisplay {
        @include media-breakpoint-down(sm) {
            margin-top: 1rem;
        }
    }

    &.theme-dark {
        .heading-space {
            background-color: $dark;
        }

        .heading-backdrop {
            background-color: $dark;
        }

        .SearchBlock__navigation {
            color: $light !important;

            .Dropdown {
                &__button {
                    svg.Icon {
                        color: var(--primary);
                        stroke: var(--primary);
                    }
                }
                &__drawer {
                    .SearchSortDropdown__value {
                        color: $light !important;
                    }
                }
            }

            .TabbedContent__tabs {
                button {
                    color: $light !important;

                    &.selected {
                        color: var(--primary) !important;
                    }

                    i.Icon {
                        color: $light !important;
                    }
                }
                &::-webkit-scrollbar-thumb {
                    background-color: $gray-primary !important;
                }
            }

            .SearchFiltersMenu {
                background: $gray-dark !important;
                color: $light !important;

                &__Open {
                    color: $light !important;

                    svg.Icon {
                        color: var(--primary);
                        stroke: var(--primary);
                    }
                }

                &__Header {
                    &__Close {
                        i.Icon {
                            color: $light !important;
                        }
                    }
                }

                .Dropdown__button {
                    color: var(--primary) !important;
                }

                .Dropdown__drawer {
                    input[type='checkbox'] {
                        border-color: $light;

                        &::before {
                            background: var(--primary);
                        }
                    }
                }

                .Stack__divider {
                    border-top-color: $gray-ghost !important;
                }

                .SearchLinks {
                    &__Link {
                        color: $light !important;
                    }
                }
            }
        }

        .SearchPagingSummary {
            background-color: $dark;
            p {
                color: $light;
            }
        }
    }
}

.heading-backdrop.mast-section {
    height: 8rem;
    position: absolute;
    z-index: -1;
    &.dark {
        background-color: $dark;
    }
}

.heading-space {
    height: 4rem !important;
    &.dark {
        color: $light;
    }
}

h2.dark {
    color: $light;
}
