@import '../../../../../Common/src/scss/components/react/LanguageSelector.scss';

.LanguageSelector {
    button.dropdown-toggle {
        padding-left: 0;
        padding-right: 0;

        i.fas {
            @include font-size(zebcoToBootstrapFontSize(2.5rem));
            color: var(--secondary);
        }
    }

    &.account-settings {
        button.dropdown-toggle {
            i,
            span {
                color: $dark;
            }
        }
    }
}
