.locationsearchblock {
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: center;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }
    
    
    &__container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 30px;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }

    &__header {
        font-family: var(--secondary-font);
        @include font-size(12px);
        color: $gray-dark;
        width: auto;
    }

    &__searchbox {
        display: flex;
        gap: 15px;
    }

    &__body {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }

    &__input-container {
        display: flex;
        border: 2px solid black;
    }
}

