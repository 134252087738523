.CatalogDetailGrid {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    width: 100%;

    .InfiniteScroll {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &__grid {
        display: grid;
        grid-template-columns: 0;
        grid-template-rows: auto;
        grid-auto-rows: auto;

        &__card {
            text-decoration: none !important;
            color: inherit !important;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            height: auto;
            z-index: 0;
            position: relative;
            border: 2px solid var(--primary);
            padding: 1rem;

            &--selected {
                &::after {
                    z-index: 1;
                    content: '';
                    position: absolute;
                    bottom: calc(-1rem - 1px);
                    left: calc(50% - 1.5rem);
                    border-top: 1rem solid var(--primary);
                    border-left: 2rem solid transparent;
                    border-right: 2rem solid transparent;
                }

                .CatalogDetailGrid__grid__card__bottom-row > button {
                    > .Icon {
                        margin-right: 0.33em;
                        transform: rotate(180deg);
                    }
                }
            }

            &__badges {
                position: absolute;
                top: 0.5rem;
                left: 0.5rem;
                z-index: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 0;
                padding: 0;
                > .badge {
                    margin-top: 0 !important;
                    + .badge {
                        margin-left: 0.25rem;
                    }
                }
            }

            &__image {
                flex: 1 1 0;
                position: relative;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                width: 100%;
                min-height: calc(var(--grid-col-size, 400px) * 0.8);
                max-height: calc(var(--grid-col-size, 400px) * 0.8);
            }

            &__title {
                margin-top: 0.5rem;
                > * {
                    line-height: 1em;
                    color: var(--primary);
                    font-size: 1.5rem;
                }
            }

            &__details {
                width: 100%;
                padding: 0 !important;
                margin: 0 !important;
                border: none !important;
                flex: 1 0 0;

                &__table {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }

                &__input {
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                    flex: 1 1 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    margin-top: auto !important;

                    > .row > div > div {
                        padding: 0 !important;
                    }
                }

                .ProductListTable__head {
                    flex: 1 1 0;
                }

                .num-input {
                    width: 6rem !important;
                }
            }
        }

        &[data-row-size='1'] {
            grid-template-columns: 100%;
            width: 100%;
            gap: 1rem;
        }
    }
}

$_style: '' + var(--grid-col-size, 360px) + ' ';
@for $i from 2 through 10 {
    $_style: $_style + var(--grid-col-size, 360px) + ' ';
    .CatalogDetailGrid__grid[data-row-size='#{$i}'] {
        grid-template-columns: #{$_style};
        gap: min(3rem, #{$i + 'rem'});
    }
}
