﻿.ProductDescriptionTab {
    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

.ProductDescriptionTab__Description {
    font-family: var(--tertiary-font);
    font-size: 1rem;

    a {
        color: blue;
        text-decoration: underline;
    }
}
.Content_features {
    text-decoration: underline;
    text-decoration-color: $primary;
    text-decoration-thickness: 3px;
    font-weight: bolder;
    text-underline-offset: 3px;
    text-underline-position: under;
    padding: 15px;
    margin: 8px;
    letter-spacing: 1px;
    margin-left: -1.2rem;
}
