﻿.Mobile.CatalogFilters {
    align-items: center;
    justify-content: space-evenly;

    &__paging {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .divider {
        width: 0.1rem;
        height: 47px;
        background: $gray-primary;
    }

    &__sort {
        display: flex;
        flex-direction: row;
    }
}
