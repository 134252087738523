﻿.yotpo.yotpo-main-widget {
    &.yotpo-main-widget-questions,
    &.yotpo-main-widget-reviews {
        .standalone-bottomline {
            display: none;
        }
    }

    .yotpo-review-wrapper > .content-review,
    .yotpo-review-wrapper > .content-review > .rest-content-collapsed {
        font-family: var(--primary-font) !important;
        font-size: $font-size !important;
        letter-spacing: $letter-spacing !important;
        line-height: 1.25rem !important;
    }

    .yotpo-footer {
        display: flex !important;

        .connect-wrapper.visible {
            display: block !important;
        }

        .yotpo-or {
            margin-top: 0 !important;
            text-align: start !important;
            align-self: center;
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        @include media-breakpoint-down(sm) {
            
            .socialize-wrapper {
                flex-direction: column;
                margin-bottom: 1rem;

                .yotpo-or {
                    align-self: start;
                }
            }
        }
    }
}

