.accordion {
    .accordion-item {
        border-bottom: 1px solid $gray-medium;
        .accordion-header {
            @include font-size(1.6rem);

            button {
                width: 100%;
                background-color: transparent;
                border: none;
                color: var(--primary);
                font-weight: bold;
                text-align: left;

                &::after {
                    float: right;
                    content: '\2192';
                    transition: transform 0.5s;
                }

                &.collapsed {
                    &::after {
                        transform: rotate(0deg);
                    }
                }

                &:not(.collapsed) {
                    &::after {
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }
}
