﻿@import '../../../Common/src/scss/components/_inputs.scss';

.num-input {
    min-height: 2.5rem;
    display: flex;
    align-items: stretch;
    position: relative; /* without this, the number input buttons are not aligned properly */
}
.NumberInput {
    box-sizing: border-box;
    background: $light;
    margin: 0;
    width: 100%;
    height: auto !important;
    min-height: 2rem;
    font-size: inherit !important;
    text-align: center;

    &.disabled,
    &:disabled,
    &[disabled] {
        background: none;
        color: $gray-medium !important;
        pointer-events: none;
    }
}

input + .NumberInput-btn {
    right: 0;
    left: auto;
}

.NumberInput-btn {
    max-height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    padding: 0;
    margin: 0;
    width: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    height: auto;
    font-size: 0.8em;
    color: $dark;

    &.disabled,
    &:disabled,
    &[disabled] {
        opacity: 0.33;
        pointer-events: none;
    }

    input:not([type='checkbox']):not([type='radio']):not([type='submit']),
    textarea,
    select {
        @include font-size(zebcoToBootstrapFontSize(1.6rem));
        font-weight: normal;
        color: var(--secondary);
        border: 1px solid $gray-medium;

        &:focus {
            border: 1px solid $gray-medium;
            box-shadow: 0 0 3px 1px #00699d;
        }
    }

    .form__single-input {
        input:not([type='checkbox']):not([type='radio']):not([type='submit']),
        textarea,
        select {
            border: none;
        }
    }

    $radio-size: 16px;
    $radio-inner-size: 6px;

    input[type='radio'],
    input[type='checkbox'] {
        width: $radio-size;
        min-width: $radio-size;
        height: $radio-size;
        min-height: $radio-size;
        vertical-align: middle;

        &:checked {
            &::before {
            }
        }

        &::before {
            width: $radio-inner-size;
            height: $radio-inner-size;
            left: calc(50% - #{$radio-inner-size * 0.5});
            top: calc(50% - #{$radio-inner-size * 0.5});
        }
    }

    option {
        @include font-size(zebcoToBootstrapFontSize(1.6rem));
    }

    .form__single-input {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid var(--secondary);

        input {
            flex-grow: 1;
            border: none !important;
            background-color: transparent;
        }

        button {
            border: none;
            background-color: transparent;
        }
    }
}
