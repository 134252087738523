﻿.article {
    &__cardrow {
        &__link {
            position: relative;
            padding-top: 7px; /* shift down to line up with oddly sized social icons */

            letter-spacing: 2px;
            font-size: 1.1875rem;
            font-weight: bold;
        }

        &__card {
            position: relative;
        }

        &__card-background {
            background-size: cover;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &__content {
            min-height: 400px;
        }

        &__footer {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
        }

        .slick {
            &-slide {
                transition: all 300ms ease;
                height: 400px;

                & .desc {
                    opacity: 0;

                    & > * {
                        transition: all 900ms ease;
                    }

                    & h2 {
                        position: relative;
                        left: 50px;
                        line-height: 1;
                    }

                    & p {
                        position: relative;
                        top: 50px;
                        opacity: 0;
                    }

                    & img {
                        width: 100%;
                    }
                }
            }

            &-prev:before,
            &-next:before {
                color: black;
            }
        }
        .background__color-primary & {
            & .slick-next:before,
            & .slick-prev:before {
                color: var(--secondary);
            }
        }
        & .slick-next:before,
        & .slick-prev:before {
            font-size: 40px;
            color: var(--primary);
        }

        & .slick-prev {
            left: -50px;
        }

        & button.fa.fa-chevron-left,
        button.fa.fa-chevron-right {
            line-height: 0;
            position: absolute;
            top: 50%;
            display: block;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            background-color: transparent;
            color: var(--primary);
            border: none;
            font-size: 23px;
            z-index: 1;
        }

        & button.fa.fa-chevron-right {
            right: -25px;
        }

        & button.fa.fa-chevron-left {
            left: -25px;
        }

        @include media-breakpoint-down(sm) {
            min-width: 100vw !important;
            max-width: 100vw !important;
            position: relative;
            left: calc(-1 * (100vw - 100%) / 2);
            padding-left: 0 !important;

            & button.fa.fa-chevron-right {
                right: -4px;
            }

            & button.fa.fa-chevron-left {
                left: -4px;
            }
        }

        label,
        input,
        div.input-checkbox,
        div.input-radio {
            font-family: var(--primary-font);
        }

        .card {
            min-height: 100%;
            border: 0;
            margin-left: 5px;
            margin-right: 5px;
            padding-left: 5px;
            padding-right: 5px;

            & .subtitle {
                font-size: 14px;
                letter-spacing: 2px;
                line-height: 19px;
            }

            & .social i {
                color: #fff;
                font-size: 23px;
            }

            & .card__footer-link {
                font-family: var(--secondary-font);
                text-transform: uppercase;
                font-weight: bold;
                padding-right: 10px;
                flex: 1 1 1px;
                /*& .card__action-open {
                    text-transform: uppercase;
                    font-family: var(--secondary-font);
                    font-weight: normal;
                    font-size: 14px;
                    cursor: pointer;
                    padding-top: 14px;
                }*/
            }

            & .card__back {
                /*z-index: 1;*/
                opacity: 0;
                background: $black;

                & .card-header {
                    padding: 0;
                }

                & .card__text {
                    color: $gray-600;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 19px;
                    padding-right: 10px;
                    overflow: hidden;
                    padding-bottom: 10px;
                    max-height: 255px;
                }

                & .card__action-close i {
                    font-size: 26px;
                    cursor: pointer;
                    color: $gray-600;
                }
            }
        }
    }

    &__cardgrid {
        padding-top: 15px;
        padding-bottom: 15px;

        & input[type='checkbox']:checked::before {
            background-color: transparent !important;
        }

        .dropdown__drawer .list-group-item {
            flex: none !important;
        }

        .articleGrid-btn {
            padding: 0.5rem 1rem 0.5rem 1rem;
        }

        .load-btn {
            max-width: 100%;

            @include media-breakpoint-up(md) {
                max-width: 25vw;
            }
        }

        & .search {
            i {
                top: 18%;
                left: 25px;
                position: absolute;
            }

            & .btn {
                font-size: 16px;
            }

            & input.form-control[type='text'] {
                font-weight: normal;
                background-color: $white !important;
                font-size: 16px !important;
                font-weight: bold !important;
                border-radius: 0px;
                padding-left: 35px;
            }
        }

        & .filter {
            & .btn {
                @include font-size(16px);
                margin: 0;
            }
        }

        & .sort,
        .filter {
            & .btn {
                background-color: $gray-ghost;
                &[aria-expanded='true'] {
                    i.fa-chevron-down {
                        transform: scaley(-1);
                    }
                }
            }
        }

        .filter-by {
            .btn-dark {
                pointer-events: none !important;
                .remove-filter {
                    pointer-events: auto !important;
                }
            }
        }

        .label {
            margin-right: 1em;
            font-family: var(--tertiary-font);
            @include font-size(16px);
            min-width: 8%;
        }

        .filters-row {
            margin-bottom: 20px;

            .btn {
                margin-right: 10px;
                border-radius: 0.5em !important;
                margin-bottom: 10px;
            }

            i {
                margin-right: 0.75em;
            }

            & label {
                font-weight: normal;
                font-size: 14px;
                margin-top: 0;
                cursor: pointer;
            }

            & label i {
                display: inline !important;
                padding-right: 9px;
            }
        }

        & .filters-reset {
            font-weight: bold;
            font-size: 14px;
            cursor: pointer;
        }

        & .title {
            text-transform: uppercase;
            font-size: 62px;
            line-height: 62px;
            color: $black;
        }

        & .subtitle {
            text-transform: uppercase;
            font-family: var(--primary-font);
            font-weight: normal;
            font-size: 14px;
            padding-bottom: 10px;
        }

        & .slick-next:before,
        & .slick-prev:before {
            font-size: 40px;
            color: var(--primary);
        }

        & .slick-prev {
            left: -50px;
        }

        & .article__card {
            min-height: 250px;
        }

        label,
        input,
        div.input-checkbox,
        div.input-radio {
            font-family: var(--primary-font);
        }

        .slick-slide {
            margin-right: 25px;
        }

        .card {
            min-height: 100%;
            border: 0;
            background-color: $black;
            margin-left: 15px;
            margin-right: 15px;

            & .card-front-text {
                padding: 0;
                background-color: transparent;
                border-top: none;
                overflow: hidden;
            }

            & .title {
                font-weight: 900;
                font-size: 30px;
                letter-spacing: 0px;
                line-height: 28px;
                word-spacing: 9999px;
            }

            & .subtitle {
                font-size: 14px;
                letter-spacing: 2px;
                line-height: 19px;
            }

            & .social {
                padding-bottom: 1rem;
                position: absolute;
                bottom: 0px;
                right: 0px;
                & i {
                    font-size: 23px;
                }
            }

            & .card__footer-link {
                background-color: transparent;
                border-top: none;
                font-family: var(--secondary-font);
                text-transform: uppercase;
                font-weight: normal;
                color: var(--primary);
                letter-spacing: 2px;
                @include font-size(19px);

                & .card__action-open {
                    text-transform: uppercase;
                    font-family: var(--primary-font);
                    font-weight: normal;
                    font-size: 14px;
                    cursor: pointer;
                    padding-top: 14px;
                }
            }

            & .card__back {
                opacity: 0;
                background: $black;

                & .card-header {
                    padding: 0;
                }

                & .card__text {
                    color: $gray-600;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 19px;
                    padding-right: 10px;
                    overflow: hidden;
                    padding-bottom: 10px;
                    max-height: 193px;
                }

                & .card__action-close i {
                    font-size: 26px;
                    cursor: pointer;
                    color: $gray-600;
                }
            }

            @media (hover: hover) {
                &.card__flip {
                    .card__front {
                        opacity: 0;
                        z-index: 1;
                    }

                    .card__back {
                        opacity: 1;
                        z-index: 2;
                    }
                }

                &:hover {
                    .card__front {
                        opacity: 0;
                    }

                    .card__back {
                        opacity: 1;
                    }
                }
            }
        }

        .article__card {
            min-height: 250px;

            @include media-breakpoint-up(md) {
                min-height: 340px;
            }

            & .card-img-top {
                filter: grayscale(1);
            }

            .card-date {
                font-size: 1rem;
                font-family: var(--primary-font);
                text-transform: uppercase;
            }

            .article-link {
                font-size: 1.5rem;
                font-family: var(--primary-font);
                text-transform: uppercase;
            }

            .card-title {
                line-height: 0.93em;
            }
        }
    }
}
