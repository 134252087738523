﻿@import '../../../../../Common/src/scss/components/react/ProductRating.scss';

.ProductRating {
    .far,
    .fas {
        &.fa-star {
            color: var(--secondary);
        }
    }

    .yotpo-display-wrapper {
        .standalone-bottomline {
            .yotpo-bottomline {
                justify-content: space-between;
                @include media-breakpoint-up(md) {
                    min-width: 100%;
                }

                @include media-breakpoint-down(md) {
                    flex-wrap: wrap;
                }

                .yotpo-stars {
                    padding-right: 0.625rem;
                }

                .rating-star {
                    color: var(--secondary);
                }

                .text-m {
                    color: var(--secondary) !important;
                    font-family: var(--tertiary-font);
                    font-size: 1rem;
                    font-style: inherit;
                    padding-right: 0.625rem;
                }
            }
        }
    }
}
