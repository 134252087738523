﻿.ProductRating {
    .fas,
    .far {
        &.fa-star {
            font-size: 14px;
            margin-right: 6px;
        }
    }

    .writeReview {
        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__BottomLine {
        justify-content: start !important;

        &__Stars {
            @include media-breakpoint-down(md) {
                width: 100%;
            }

            .yotpo-stars {
                padding-right: 5px !important;
                @include media-breakpoint-down(md) {
                    padding-right: 0;
                    width: 100%;
                }
            }

            .text-m {
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }

        &__Link {
            @include media-breakpoint-down(md) {
                width: 100%;
            }

            .reviewCount {
                display: none;
                @include media-breakpoint-down(md) {
                    display: inline-block;
                }
            }
        }
    }

    .reviewSeparator {
        padding: 0 5px 0 5px;
    }
}
