﻿@import '../../../../../Common/src/scss/components/react/CheckoutPage.scss';

.CheckoutPage {
    h1 {
        justify-content: center;
    }

    iframe:not(.gpay-card-info-iframe) {
        border-top: 1px solid $gray-medium;
    }
}
