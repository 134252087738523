﻿@import '../../../../../Common/src/scss/components/react/CatalogList.scss';

.CatalogList {
    .CatalogListCard {
        border: 1px solid var(--primary);

        .ProductBadge {
            margin-bottom: 0.25em;
            .badge {
                font-family: var(--secondary-font);
                letter-spacing: 0.1em;
                font-weight: bold;
                font-size: 0.725rem;
                border: none;
                color: $dark;
                background: var(--primary);
                padding: 0.25em 0.25em 0 0.25em;
            }
        }

        .ProductPrice {
            p {
                font-size: inherit;
                font-family: var(--tertiary-font);
                font-weight: 400;
                letter-spacing: 0.1em;
            }
        }

        &__Details {
            &__Title {
                font-family: var(--primary-font);
                font-size: 1.2rem;
                font-weight: bold;
                letter-spacing: 0.1em;

                .btn {
                    color: var(--secondary);
                }
            }

            table {
                th,
                td {
                    font-size: 0.725rem;
                    letter-spacing: 0.1em;
                }
                th {
                    font-family: var(--secondary-font);
                }
                td {
                    font-family: var(--tertiary-font);
                }
            }

            .ProductListTable {
                &.Grid {
                    @include branded-scroll-x($gray-light, var(--primary), 0.75rem);

                    .Grid {
                        &__cell {
                            font-family: var(--tertiary-font);
                            font-size: 0.85rem;

                            &--even {
                                &:not(.Grid__cell--header) {
                                    background-color: $light;
                                }
                            }

                            &--header {
                                font-family: var(--secondary-font);
                                letter-spacing: 0.1em;
                            }

                            &--odd {
                                &:not(.Grid__cell--header) {
                                    background-color: $gray-light;
                                }
                            }

                            .gallery-btn {
                                font-size: 1.25rem;
                            }
                        }
                    }
                }
            }

            &__Input.ProductInput {
                font-size: 0.825rem;
                button {
                    font-size: 0.825rem;
                }
            }
        }

        @include media-breakpoint-down(md) {
            &__Details {
                &__CatalogListDetailsCards {
                    @include branded-scroll-y($gray-ghost, var(--primary), 0.3rem);
                    .ProductInput {
                        margin-top: 0px;
                    }

                    table {
                        margin-bottom: 1rem;

                        td {
                            text-align: left !important;
                        }

                        th {
                            font-weight: bold;
                            padding-right: 2em;
                            padding-top: 0.25em;
                            text-transform: uppercase;
                            vertical-align: top;
                        }
                        td {
                            padding-top: 0.25em;
                        }
                    }

                    .notify-link {
                        margin-bottom: 0.5rem;
                    }

                    .ProductDetailCard {
                        .ProductInput {
                            font-size: 0.75rem;
                            button.btn {
                                font-size: 0.75rem;
                            }
                        }
                    }

                    @include branded-scroll-y($gray-ghost, var(--primary), 0.3rem);
                }
            }
        }

        @include media-breakpoint-down(sm) {
            &__Details {
                &__CatalogListDetailsCards {
                    .ProductDetailCard {
                        .ProductInput {
                            input {
                                padding: 0.5rem 0.7rem !important;
                            }

                            button {
                                &.btn-add {
                                    right: 0;
                                }
                                &.btn-sub {
                                    left: 0;
                                }

                                &:hover {
                                    color: inherit;
                                }
                            }
                        }

                        &__container__displayonly {
                            &__toggle {
                                th {
                                    font-weight: normal !important;
                                }
                            }
                            &__row {
                                &__col {
                                    font-family: var(--secondary-font);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
