.CatalogCompare {
    padding-left: 30px;
    padding-right: 30px;

    &.CatalogCompare__medium-padding {
        padding-left: 15px;
        padding-right: 15px;
    }

    .CatalogCompare__title {
        font-weight: bold;
        text-transform: uppercase;
        @include font-size(24px);

        @include media-breakpoint-down(sm) {
            @include font-size(20px);
        }
    }

    .CatalogCompare__list {
        list-style-type: none;
        padding-left: 0;

        .CatalogCompare__delete-button {
            border: none;
            background-color: transparent;
        }
    }
}
