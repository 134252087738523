﻿.PromoCode,
.GiftCardGateway {
    .subheader {
        font-size: 19px;
        letter-spacing: 2px;
        line-height: 19px;
    }

    .btn {
        letter-spacing: 2px;
        padding: 0.5rem 0.7rem;
    }

    .applied {
        border: 2px solid var(--secondary);

        > div > div {
            white-space: nowrap;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
        }

        &.container {
            padding-right: 14px;
        }

        &__label {
            background: var(--secondary);
            height: 100%;
            width: 100%;

            i {
                font-size: 14px;
            }
        }

        .value {
            text-transform: uppercase;
            font-family: var(--tertiary-font);
            letter-spacing: 0.1px;
            font-weight: $font-weight-bolder;
        }
    }

    .dropdown {
        .card-header {
            padding: 0px;
            background: transparent;

            .btn {
                @include font-size(16px);
                text-transform: none;
                font-weight: $font-weight-bolder;
            }

            button[aria-expanded='true'] {
                i {
                    transform: scaleY(-1);
                    -moz-transform: scaleY(-1);
                    -webkit-transform: scaleY(-1);
                    -ms-transform: scaleY(-1);
                    font-size: 1em;
                }
            }
        }

        .drawer {
            max-height: 100px;
            overflow-y: auto;
        }

        border: 2px solid var(--secondary);
    }
}
