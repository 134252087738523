﻿.order__lookup {
    padding-top: 20px;
    padding-bottom: 20px;

    .not-found {
        padding: 20px;
    }

    form {
        width: 100%;
    }

    input.email,
    input.orderNumber {
        padding-top: 16px;
        padding-bottom: 16px;
        max-height: 52px; //setting in order to match button sizes which was a comment bassed off the pr for this code
    }

    & input::-webkit-input-placeholder {
        @include font-size(16);
    }

    & input::-moz-placeholder {
        @include font-size(16);
    }

    & input:-moz-placeholder {
        @include font-size(16);
    }

    & input:-ms-input-placeholder {
        @include font-size(16);
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
    .OrderDetail {
        animation: fadeIn 0.5s ease-out;
    }

    @include media-breakpoint-down('sm') {
        form {
            div {
                margin-bottom: 8px;
            }
        }
        button[type='submit'] {
            line-height: 16px;
        }

        & label {
            margin-bottom: 0.5rem;
        }

        & input::-webkit-input-placeholder {
            @include font-size(14);
        }

        & input::-moz-placeholder {
            @include font-size(14);
        }

        & input:-moz-placeholder {
            @include font-size(14);
        }

        & input:-ms-input-placeholder {
            @include font-size(14);
        }
    }
}
