.SearchLoadMore {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;

    &__Button {
        min-width: 160px;
        @extend .btn-sm;
        .Icon {
            margin-right: 0.8rem;
        }

        .spinner-border {
            height: 1rem;
            width: 1rem;
        }
    }
}
