﻿.ChildrenSelector {
    i.fa {
        transform: rotate(0deg);
    }

    button.btn {
        text-transform: none;
        text-align: left;

        &.selected {
            background: var(--primary);
        }
    }

    div.collapse {
        .child-list {
            max-height: 200px;
            overflow-y: scroll;
            overflow-x: hidden;

            button {
                opacity: 0;
                transition: opacity 100ms ease;
            }
        }
    }

    div.collapsing {
        .child-list {
            button {
                opacity: 0;
                transition: opacity 100ms ease;
            }
        }
    }

    div.collapse.show {
        .child-list {
            button {
                opacity: 1;
                transition: opacity 100ms ease;
            }
        }
    }

    .child-list > button {
        padding: 10px 40px;
        margin-bottom: 10px;
        background: $gray-ghost;
    }

    button[aria-controls='dropdown-selector'] {
        padding-top: 20px;
        padding-bottom: 20px;

        @media (min-width: 768px) {
            margin-left: -40px;
        }

        i {
            margin-left: 20px;
            font-size: 14px;
        }

        p {
            font-weight: $font-weight-bolder;
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        //background: $gray-ghost;
        border-radius: 2.5px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $gray-secondary;
        border-radius: 2.5px;
        transition: background 0.8s;
    }

    @include media-breakpoint-down(sm) {
        div.collapse.show {
            .child-list {
                max-height: 150px;
            }
        }

        .child-list button {
            padding: 5px 20px;
            margin-bottom: 5px;
            background: $gray-ghost;
        }
    }
}
