﻿.ProductInput {
    // remove arrows from number input:
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }

    input {
        text-align: center;
    }

    .num-input {
        .NumberInput-btn.btn-sub {
            left: 0.25rem;
        }
        .NumberInput-btn.btn-add {
            right: 0.25rem;
        }

        input.NumberInput {
            padding: .5rem .7rem;
        }

        &.pl-1 {
            .NumberInput-btn.btn-sub {
                left: 0.5rem;
            }
        }
        &.pr-1 {
            .NumberInput-btn.btn-add {
                right: 0.5rem;
            }
        }
        &.px-1 {
            .NumberInput-btn.btn-sub {
                left: 0.5rem;
            }
            .NumberInput-btn.btn-add {
                right: 0.5rem;
            }
        }

        &.pl-2 {
            .NumberInput-btn.btn-sub {
                left: 0.75rem;
            }
        }
        &.pr-2 {
            .NumberInput-btn.btn-add {
                right: 0.75rem;
            }
        }
        &.px-2 {
            .NumberInput-btn.btn-sub {
                left: 0.75rem;
            }
            .NumberInput-btn.btn-add {
                right: 0.75rem;
            }
        }

        &.pl-3 {
            .NumberInput-btn.btn-sub {
                left: 1.25rem;
            }
        }
        &.pr-3 {
            .NumberInput-btn.btn-add {
                right: 1.25rem;
            }
        }
        &.px-3 {
            .NumberInput-btn.btn-sub {
                left: 1.25rem;
            }
            .NumberInput-btn.btn-add {
                right: 1.25rem;
            }
        }
    }

    button.btn-primary {
        padding: 0;
    }

    button.buy-now {
        margin-right: 12px;
        border: 2px solid transparent;
    }

    button.buy-now:active,
    button.buy-now:focus {
        border: 2px solid $black;
    }

    button.buy-now:disabled {
        border: 2px solid transparent;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 1rem;
    }
}
