﻿.catalog-node {
    margin-bottom: 0px !important;

    ul.nav-tabs {
        padding: 20px 0px;
        justify-content: center;
        border-bottom: none;

        > li {
            padding: 0px 30px;

            a.nav-link {
                background-color: transparent;
                border: none;
                opacity: 0.5;

                &.active {
                    opacity: 1;
                    text-decoration: underline;
                    border: none;
                }
            }
        }
    }

    &__mast {
        height: 250px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: var(--secondary);
        color: $light;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        h1,
        h4 {
            display: block;
        }

        h4 {
            @include font-size(19px);
            font-family: var(--secondary-font);
            font-weight: 200;
            letter-spacing: 2px;
        }
    }

    @include media-breakpoint-down(sm) {
        h1 {
            font-size: 1.5rem;
            letter-spacing: -0.5px;
            line-height: 1.5rem;
            text-align: center;
        }
    }
}

@media (min-width: 1250px) {
    .catalog-node .container {
        max-width: 1250px;
    }
}
@media (max-width: 767px) {
    .catalog-node .container {
        max-width: 100vw;
    }
}
