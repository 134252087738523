.articlePage {
    .title {
        @include font-size(zebcoToBootstrapFontSize(8rem));
        line-height: 1.2;
        color: var(--secondary);
        font-family: $special-font-condensed;
    }

    .description {
        color: var(--secondary);
    }

    h2 {
        font-family: $special-font-condensed;
        color: var(--secondary);
    }

    p {
        color: var(--secondary);
    }
}
