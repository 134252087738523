.mast__section {
    h1 {
        color: $white;
    }
}

//overrides specifically for the mast on a PLP
.catalog-node .mast__section {
    color: var(--secondary);

    &__image {
        background-color: transparent;
    }

    &__text {
        &-title {
            color: var(--secondary);
        }

        &-subtitle {
            @include font-size(zebcoToBootstrapFontSize(1.6rem));
            font-weight: normal;
            line-height: 1.5;
            letter-spacing: normal;
        }
    }
}
