﻿.login__block {
    margin-top: 8%;
    margin-bottom: 8%;

    &__title__border {
        background-color: #d2212f;
        height: 3px;
        width: 45px;
        user-select: none;
        display: none;
    }

    .text__size_lg,
    .text__size_sm {
        font-family: var(--tertiary-font);
        letter-spacing: 0.25px;
    }

    .text__size_lg a {
        font-weight: bold;
    }

    &__instructions {
        margin-top: 9px;
    }

    .login__form {
        margin-top: 1rem !important;

        &__forgot {
            margin-top: 16px !important;
        }
    }

    &__title-row {
        h3 {
            line-height: 0.8em;
        }

        margin-bottom: 3rem !important;
    }

    .btn-lg {
        padding: 22px 2.5em;
    }

    a.btn-lg {
        margin-top: 34px !important;
    }

    .spacercolumn__first {
        border-right: 2px solid $black;
    }

    .register {
        padding-left: 18px;
    }

    .form {
        width: 100%;
    }

    .form-check {
        display: flex;
        align-items: center;
        padding-left: 0px;
    }

    @include media-breakpoint-down(sm) {
        &__title {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .spacercolumn__first,
        .spacercolumn__second {
            border-right: 0px;
            height: 2px;
            background: $black;
        }

        a.register {
            @include font-size(20px);
        }

        a.btn-lg {
            width: 100%;
        }

        button.btn-lg,
        a.btn-lg {
            padding-bottom: 14px;
            padding-top: 14px;
        }
    }
}
