.SearchFacet {
    &.Dropdown {
        .Dropdown__container {
            width: 100%;
        }
        .Dropdown__button,
        .Dropdown__drawer {
            border: none;
        }

        .Dropdown__button {
            padding: 0.625rem 0 0.625rem 0;
            min-width: 150px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }

    &.SearchDropdownFacet {
        .SearchDropdownFacet__option {
            padding-left: 0px;
            padding-right: 0px;
            text-align: left;

            &.selected {
                text-decoration: underline;
            }
        }
    }

    &.SearchCheckboxFacet,
    &.SearchRadioFacet {
        .SearchRadioFacet__option,
        .SearchCheckboxFacet__option {
            margin: 5px 0px;
            display: flex;
            align-items: center;
        }

        .form-check {
            &-input {
                height: 18px;
                min-height: 18px;
                width: 18px;
                min-width: 18px;
            }

            &-label {
                line-height: 1rem;
            }
        }
    }
}
