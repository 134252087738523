.Grid {
    position: relative;
    display: grid;
    overflow-x: auto;
    width: 100%;
    text-wrap: nowrap;

    .Row {
        display: contents;
    }

    &__cell {
        &--odd {
            &:not(.Grid__cell--interrupter) {
                color: var(--grid-odd-color);
                background: var(--grid-odd-background);
            }
        }

        &--even {
            &:not(.Grid__cell--header):not(.Grid__cell--interrupter) {
                color: var(--grid-even-color);
                background: var(--grid-even-background);
            }
        }

        &--header {
            position: sticky;
            z-index: 1;
            background-color: var(--primary);
            top: 0;
        }

        &--sticky-left {
            &:not(.Grid__cell--interrupter) {
                position: sticky;
                z-index: 2;
                left: 0;
            }
        }

        &--sticky-right {
            &:not(.Grid__cell--interrupter) {
                position: sticky;
                z-index: 2;
                right: 0;
            }
        }

        &--interrupter {
            text-wrap: wrap;
        }
    }
}
