.ProductSearchResult {
    border: 2px solid $gray-secondary;
    text-decoration: none !important;
    color: inherit !important;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: var(--grid-col-size, 360px);
    z-index: 0;
    position: relative;
    padding: 1rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: flex-end;

    &--selected {
        &::after {
            z-index: 1;
            content: '';
            position: absolute;
            bottom: calc(-1rem - 1px);
            left: calc(50% - 2rem);
            border-top: 1rem solid var(--primary);
            border-left: 2rem solid transparent;
            border-right: 2rem solid transparent;
        }
    }

    &__image {
        flex: 1 1 0;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
        min-height: 0px;
        width: 100%;

        & > img {
            object-fit: contain;
            max-height: 100%;
            max-width: 100%;
        }
    }

    &__title {
        margin-top: 0.5rem;
        > * {
            line-height: 1em;
            font-size: 1.5rem;
        }
    }

    &__bottom-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;

        &__price.a.b.c {
            margin-top: 0 !important;
            margin-right: 1em;
        }

        button.btn:not(.btn-read-more) {
            position: relative;
            bottom: -0.33em;
        }

        &__social-links {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            a {
                &:not(:first-child) {
                    margin-left: 1rem;
                }
                > .Icon {
                    font-size: 2.25rem;
                    color: $light;
                }
            }
        }
    }

    &__badges {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        z-index: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
        padding: 0;
        > .badge {
            margin-top: 0 !important;
            + .badge {
                margin-left: 0.25rem;
            }
        }
    }

    &__fake {
        height: 100%;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: end;
        gap: 0.5rem;
        position: relative;
        opacity: 1;
        @include animate-flicker-light();

        &--image {
            padding: inherit;
            position: absolute;
            width: 100%;
            height: 100%;
            border: none;
            background: $gray-ghost;
        }

        &--title {
            width: 300px;
            height: 50px;
            background-color: $gray-ghost;
            box-shadow: inset 0 -2em 3em $gray-ghost, 0 0 0 2px $gray-ghost, 0.1em 0.1em 0.5em rgb(0 0 0 / 10%);
            margin-left: 1rem;
            border-radius: 2px;
            z-index: 1;
        }

        &--button {
            width: 100px;
            height: 40px;
            background-color: $gray-ghost;
            box-shadow: inset 0 -2em 3em $gray-ghost, 0 0 0 2px $gray-ghost, 0.1em 0.1em 0.5em rgb(0 0 0 / 10%);
            margin-left: 1rem;
            margin-bottom: 1rem;
            z-index: 1;
            border-radius: 2px;
        }
    }
}
