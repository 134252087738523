﻿.accountsettingsblock {
    & .btn {
        @include media-breakpoint-down('sm') {
            width: 100%; 
        }
    }
}

.LanguageSelector.account-settings {
    width: 48%;

    .dropdown-toggle.btn-sm.form-control {
        width: 100%;
        padding: 0.5rem 0.7rem;

        .fa-chevron-down {
            position: absolute;
            right: 0.7rem;
        }
    }
}

@include media-breakpoint-down(sm) {
    .LanguageSelector.account-settings {
        width: 100%;
    }
}
