﻿@mixin outline-text($outline-color, $fill-color, $stroke-width: 1px) {
    -webkit-text-fill-color: $fill-color;
    -webkit-text-stroke-width: $stroke-width;
    -webkit-text-stroke-color: $outline-color;
}

@mixin branded-scroll-y($background: none, $foreground: var(--primary), $size: 1rem) {
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 0;

    &::-webkit-scrollbar:horizontal {
        display: none;
    }

    &::-webkit-scrollbar {
        width: $size;
    }

    &::-webkit-scrollbar-track {
        background-color: $background;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $foreground;
    }
}

@mixin branded-scroll-x($background: none, $foreground: var(--primary), $size: 1rem) {
    overflow-y: hidden;
    overflow-x: auto;
    padding-bottom: 0;

    &::-webkit-scrollbar:vertical {
        display: none;
    }

    &::-webkit-scrollbar {
        height: $size;
    }

    &::-webkit-scrollbar-track {
        background-color: $background;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $foreground;
    }
}

@mixin branded-scroll-xy($background: none, $foreground: var(--primary), $size: 1rem) {
    overflow-x: auto;
    overflow-y: auto;
    padding-bottom: 0;
    padding-right: 0;

    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    &::-webkit-scrollbar-track {
        background-color: $background;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $foreground;
    }
}

@mixin animate-flicker-light() {
    @keyframes flicker-light {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.3;
        }
        100% {
            opacity: 1;
        }
    }
    animation: flicker-light 2s linear infinite;
}

@mixin animate-flicker-dark() {
    @keyframes flicker-dark {
        0% {
            opacity: 0.5;
        }
        50% {
            opacity: 0.3;
        }
        100% {
            opacity: 0.5;
        }
    }
    animation: flicker-dark 2s linear infinite;
}

@mixin aspect($width: 16, $height: 9) {
    aspect-ratio: calc($width / $height);

    @supports not (aspect-ratio: calc($width / $height)) {
        &::before {
            content: '';
            float: left;
            padding-top: calc((#{$height} / #{$width}) * 100%);
        }

        &::after {
            content: '';
            display: block;
            clear: both;
        }
    }
}
