﻿.youtubecarouselsearchblock {
    .search {
        i {
            top: 18%;
            left: 25px;
            position: absolute;
        }

        & .btn {
            font-size: 16px;
        }

        & input.form-control[type='text'] {
            font-weight: normal;
            background-color: $white !important;
            font-size: 16px !important;
            font-weight: bold !important;
            border-radius: 0px;
            padding-left: 35px;
        }
    }
}

.youtubecarouselblock {
    .carousel {
        &--arrow-color {
            &-primary {
                .slick-next:before,
                .slick-prev:before {
                    color: var(--primary);
                }
            }

            &-secondary {
                .slick-next:before,
                .slick-prev:before {
                    color: var(--secondary);
                }
            }

            &-tertiary {
                .slick-next:before,
                .slick-prev:before {
                    color: var(--tertiary);
                }
            }

            &-dark {
                .slick-next:before,
                .slick-prev:before {
                    color: $dark;
                }
            }

            &-light {
                .slick-next:before,
                .slick-prev:before {
                    color: $light;
                }
            }
        }
    }
}
