﻿@import '../../../assets/plugins/svg-sprite/icons.scss';

.fa-hidden {
    color: transparent !important;
}

svg.icons.icon-google-pay {
    background: white;
    width: 1.5em;
}

svg.icons.icon-play {
    width: 34px;
    height: 32px;
}

svg.icons.flip {
    transform: rotate(180deg);
}

svg.icons.icons-brand-navigation {
    width: 1.75em;

    &.icon-Mach {
        width: 4em;
    }
}

svg.icons.icon-amazon-pay-large {
    width: 4em;
}

svg.icons.icon-paypal-large {
    width: 4em;
}
