﻿.tooltip.AlertTip.mini-cart-tip,
.tooltip.AlertTip.user-menu-tip {
    > .tooltip-inner {
        background: $light;
        color: $dark;
    }

    > .arrow::before {
        border-bottom-color: $light;
    }
}

.header__breadcrums {
    .breadcrumb {
        margin-bottom: 0px;
        padding: 0.75em 0px;
        @include font-size(14px);

        .breadcrumb-item + .breadcrumb-item::before {
            content: escape-svg($breadcrumb-divider);
        }

        .breadcrumb-item {
            &.active {
                color: inherit;
            }
        }
    }
}

.header__banner {
    display: block;
    text-transform: uppercase;
    padding: 0.5em;
    font-family: var(--primary-font);
    font-weight: $font-weight-bolder;
    text-align: center !important;

    @include media-breakpoint-down(md) {
        text-align: center !important;
    }

    &.noLink {
        pointer-events: none;
    }
}

.header__mast {
    .btn.search > i {
        letter-spacing: 0px;
        transform: rotate(0deg);
    }

    &__logo {
        height: 80px;
    }

    button {
        border: none;
    }

    button.header__minicart {
        background: transparent;
        color: var(--primary);
        padding-left: 15px;
        border: none;
    }

    button,
    [type='button'] {
        -webkit-appearance: none;
    }

    .header__user button,
    a.header__login {
        text-transform: none;
        letter-spacing: 0.29px;
    }

    .header__user i.fas,
    .header__login i.fas {
        @include font-size(18);
    }

    .header__user {
        a {
            @extend .paragraph-3;
        }

        button[aria-expanded='true'] {
            i.fa-chevron-down {
                transform: scaleY(-1);
                -moz-transform: scaleY(-1);
                -webkit-transform: scaleY(-1);
                -ms-transform: scaleY(-1);
            }
        }

        .dropdown-menu {
            border-radius: 0px;
        }
    }

    &__h1 {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 0px;
    }

    @include media-breakpoint-down(lg) {
        &__logo {
            height: 36px;
        }
    }

    @include media-breakpoint-down(md) {
        padding-top: 0px;

        .col-4 {
            height: 50px;
        }

        .header__user button,
        a.header__login,
        .header__minicart,
        .search {
            margin-top: 0px;
            margin-bottom: 0px;
        }

        .fa-bars {
            color: $white;
        }

        i.fa-user.logged_in,
        i.fa-user-circle.logged_in {
            color: var(--primary);
        }
    }
}

.header-static,
.header-float {
    width: 100%;
    z-index: 5;

    .headersearchbarblock {
        position: unset;
        //Transparent border necessary for iOS visible border
        border: 1px solid transparent;
    }
}

.header-static {
    position: relative;
}

.header-float {
    position: absolute;
}
