.PanelSection {
    display: grid;
    grid-template-columns: min-content auto;

    &__icon {
        grid-row: 1;
        grid-column: 1;
        padding: spacing(1);
        min-width: 2rem;
        display: flex;
        justify-content: center;
    }

    &__title {
        display: flex;
        align-items: center;
        flex-direction: row;
        grid-row: 1;
        grid-column: 2;
    }

    &__body {
        grid-row: 2;
        grid-column: 2;
    }

    &--iconPos-right {
        grid-template-columns: auto min-content;
        .PanelSection {
            &__icon {
                grid-row: 1;
                grid-column: 2;
            }

            &__title {
                justify-content: end;
                grid-row: 1;
                grid-column: 1;
            }

            &__body {
                text-align: right;
                grid-row: 2;
                grid-column: 1;
            }
        }
    }
}
