﻿@import '../../../../Common/src/scss/pages/_commonnode.scss';

.catalog-node {
    ul.nav-tabs {
        > li {
            a.nav-link {
                color: var(--primary);
            }
        }
    }

    h1 {
        @include font-size(zebcoToBootstrapFontSize(3.2rem));
    }
}
