﻿.catalog__product {
    &.product__grid,
    &.product__list {
        padding: 0 9rem;
        .ProductListView{
            margin-top: 5rem;
        }

        @include media-breakpoint-down(md) {
            padding: 0 1rem;
        }
    }

    .breadcrumb {
        margin-bottom: 0px;
        padding: 0.75em 0px;
        @include font-size(14px);

        .breadcrumb-item + .breadcrumb-item::before {
            content: escape-svg($breadcrumb-divider);
        }

        .breadcrumb-item {
            &.active {
                color: inherit;
            }
        }
    }

    h6 {
        line-height: 19px;
    }

    h3 {
        line-height: 0.97em;
        letter-spacing: -0.5px;
    }

    .default-options {
        & button {
            padding: 0px;
            width: 100%;
            text-align: left;
        }

        & .btn-toolbar button {
            padding: 5px 5px;
        }

        & .btn-toolbar button:focus {
            border: 2px solid var(--primary);
        }
    }

    button:disabled {
        opacity: 0.4;
    }

    .accordion {
        border: none;

        .card {
            border: none;

            h3 {
                word-wrap: normal;
            }

            .collapsing,
            .collapse {
                border-bottom: none;
            }

            .filters-container .free-text-search-input {
                padding: 0 35px 0 35px;
            }
        }

        .card-header {
            background: transparent;
            border-bottom: none;
            border-radius: 0;

            button[aria-expanded='true'] {
                i {
                    transform: scaleY(-1);
                    -moz-transform: scaleY(-1);
                    -webkit-transform: scaleY(-1);
                    -ms-transform: scaleY(-1);
                    font-size: 1em;
                }
            }
        }

        .main-widget .avg-score {
            width: 75px;
            height: 60px;
            overflow: visible;
        }

        .main-widget .rating-stars-container {
            margin-left: 0px;
        }
    }

    .ProductView {

        .ProductDetails {
            padding: 1rem 2rem;
        }
    }

    @include media-breakpoint-down(md) {
        .breadcrumb {
            padding: 10px 0px 10px 0px;
            display: flex;
            justify-content: center;
        }

        .ProductView .ProductDetails {
            padding: 1rem 0rem 1rem 2rem;
        }
    }

    @include media-breakpoint-down(sm) {
        .ProductView .ProductDetails {
            padding: 1rem 2rem;
        }
    }
}

.yotpo-main-widget-reviews,
.yotpo-main-widget-questions {
    .yotpo-mandatory-mark {
        display: inline-block !important;
        vertical-align: top;
    }

    .y-label {
        padding-top: 0 !important;
        display: inline-flex !important;
    }

    .form-input-error {
        float: unset !important;
        display: block !important;
        margin-left: 5px;
    }

    .form-group .form-input-error {
        margin-left: 10px !important;
        white-space: normal !important;
    }

    .connect-wrapper input {
        margin-bottom: 20px !important;
    }

    @include media-breakpoint-down(sm) {
        .y-label {
            max-width: 90%;
        }
    }
}
