@keyframes fade-in {
    0% {
        opacity: 0;
        visibility: hidden;
        transform: scale(0.8);
    }
    100% {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        visibility: hidden;
        transform: scale(0.8);
    }
}

.ScrollToTopFab {
    @extend .btn;
    @extend .btn-primary;

    /* position */
    position: fixed;
    right: 5em;
    bottom: 5em;

    @include media-breakpoint-down(md) {
        right: 1em;
        bottom: 1em;
    }

    /* padding */
    padding: 0.375rem 0.75rem;

    /* dimensions */
    width: 3em;
    height: 3em;

    border-radius: 3em;

    /* visibility */
    transition: opacity 200ms;
    opacity: 1;

    /* layer */
    z-index: 2;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.7);

    /* animations */
    animation-name: fade-in;
    animation-fill-mode: both;
    animation-duration: 0.5s;
    transform-origin: 50% 50%;

    /* modifiers */
    &--hidden {
        animation-name: fade-out;
        animation-fill-mode: both;
        animation-duration: 0.5s;
    }
}
