.SearchFacetBreadcrumbs {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;

    &__Title {
        text-align: center;
        font-family: var(--primary-font);
        font-size: 1rem;
        @include media-breakpoint-down(md) {
            margin-bottom: 0.5rem;
        }
    }

    .Section__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }

    &__Breadcrumb {
        border-radius: 0.5em;
        color: $light;
        background-color: $dark;
        font-size: 0.875rem;

        &__Icon {
            margin-right: 0.75rem;
        }
    }

    .Section__sidebarRight {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        white-space: nowrap;
    }

    &__ClearAll {
        text-decoration: underline;
    }
}
