@import '../../../assets/vendors/react-phone-number-input/style';

.form__field-required::after {
    content: ' *' !important;
    color: red;
    white-space: nowrap;
}

.FormImageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    > .Icon {
        font-size: 3rem;
        position: relative;
        margin: 1.5rem 0.5rem;
        transition: opacity 200ms;
        opacity: 0.33;
        &.active {
            opacity: 1;
        }
    }
}

.Field {
    input {
        width: 100%;
    }
    &.hidden {
        display: none !important;
    }
    &__Required {
        display: inline;
        position: relative;
        &::after {
            content: '*' !important;
            color: red;
            position: absolute !important;
            margin-left: 0.33em;
        }
    }
}

.ErrorDisplay {
    display: block;
    color: red;
}

select[name='RegionCode'],
select[name='CountryCode'] {
    height: 44px !important;
    width: 100% !important;
}

input.form-control {
    background-color: $input-bg !important;
    font-size: 24px;
    font-weight: bold !important;
    & + .invalid-feedback {
        display: block;
    }
}

.form-messagebox {
    margin-top: 10px;
}

input[type='checkbox'],
input[type='radio'],
div.input-checkbox,
div.input-radio {
    -webkit-appearance: none;
    background: white;
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 2px solid $black;
    height: 24px;
    min-height: 24px;
    width: 24px;
    min-width: 24px;
    margin-right: 10px;

    &:not([disabled]):not(.disabled) {
        cursor: pointer;
    }

    &::before {
        content: none !important;
        position: absolute;
        z-index: 1;
        width: 8px;
        height: 8px;
        left: calc(50% - 4px);
        top: calc(50% - 4px);
        background: black;
        user-select: none;
        pointer-events: none;
    }

    &:checked,
    &.checked {
        box-shadow: 0 0 1px 1px #0000004b !important;
        background: var(--checkbox-fill-color);

        &::before {
            content: ' ' !important;
        }
    }
}

input[type='radio'],
div.input-radio {
    border-radius: 24px;

    &::before {
        border-radius: 8px;
    }
}

.Form__Element {
    input:not([type='checkbox']):not([type='radio']) {
        width: 100%;
    }

    label {
        font-size: 12px;
        margin-top: 0.5rem;
        margin-bottom: 0;

        span::after {
            position: relative;
        }
    }

    div:last-child {
        label {
            display: flex;
            align-items: flex-end;
        }
    }

    &.ValidationRequired {
        .Form__Element__Caption {
            &::after {
                content: '*' !important;
                margin-left: 0.2em;
                color: $red;
            }
        }
    }

    .Form__Element__Caption {
        @include font-size(20px);
        font-weight: bold;
    }

    &.FormSelection {
        select {
            display: block;
        }
    }

    &.FormChoice {
        fieldset {
            label {
                @include font-size(16px);
            }
        }
    }
}

.Form__Element__List {
    table {
        select,
        input {
            width: 100%;
        }
    }
}
