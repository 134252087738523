﻿@import '../../../../../Common/src/scss/components/react/ColorSelector.scss';

.ColorSelector {
    .btn-toolbar {
        margin-top: 0px;
        .btn-group button {
            border: 2px solid transparent;
            background: $gray-ghost;
            margin-top: 5px;

            &.selected {
                img {
                    border: 2px solid $white;
                }

                border: 2px solid $black;
            }
        }

        button.dropdown {
            top: 6px;
        }
    }
}
