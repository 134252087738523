﻿.cartsummaryblock {
    @extend .order-lg-2
}

.cart__summary {
    padding: 40px 40px 0px 40px;


    &__support {
        a {
            @include font-size(30px);
            color: inherit;
            font-weight: $font-weight-bolder;
        }
    }

    @include media-breakpoint-down(lg) {
        &__support {
            a {
                @include font-size(25px);
            }
        }
    }

    @include media-breakpoint-down(md) {
        padding: 18px 20px;
    }
}
