.SearchFiltersMenu {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    transition: 300ms opacity;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .Stack__divider {
        display: none;
    }

    &__Open {
        @extend .btn-sm;
        font-size: 1.5rem;
        margin-left: 1rem;
        padding: 7px 0 !important;

        @include media-breakpoint-down(md) {
            font-size: 1.2rem;
        }

        @include media-breakpoint-down(sm) {
            margin-left: 0;
            padding-left: 0.5rem;
        }

        svg.Icon {
            stroke-width: 4px;
        }

        &.hidden {
            visibility: hidden;
        }
    }

    &__Header {
        padding: 1em 1em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &__Close {
            font-size: 1.5rem;
            padding: 0.5rem !important;
        }
    }

    .Stack {
        padding: 0 2rem;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .Dropdown__drawer {
        padding-left: 1rem;

        .SearchCheckboxFacet__option {
            margin-bottom: 10px !important;
        }

        .form-check-label {
            cursor: pointer;
            font-size: 1.2rem !important;
        }
    }
}
