body {
    @include font-size(zebcoToBootstrapFontSize(1.6rem));
}

.display,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--primary-font);
    font-weight: 500;
    line-height: 1.2;
}

h1 {
    font-family: $special-font;
    color: var(--secondary);
}

h2,
h3,
h3,
h4,
h5,
h6 {
    text-transform: none !important;
}

ul {
    font-family: var(--primary-font);
}

li {
    @include font-size(zebcoToBootstrapFontSize(1.6rem));
}

p {
    @include font-size(zebcoToBootstrapFontSize(1.6rem), true);
}

label {
    @include font-size(zebcoToBootstrapFontSize(1.8rem));
    font-weight: bold;
    margin-bottom: 0;
    vertical-align: middle;
    @include font-size(zebcoToBootstrapFontSize(1.2rem), true);
    color: var(--secondary);
}

.text__size_lg {
    @include font-size(zebcoToBootstrapFontSize(1.8rem), true);
}

.text__size_sm {
    @include font-size(zebcoToBootstrapFontSize(1.25rem), true);
}

//rs: ordinarily this would use var(--secondary-font), but we always need buttons to use $button-font, regardless of whether or not there is an embedded span
.btn > span {
    font-family: $button-font;
}
