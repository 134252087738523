.HeaderSearchResult {
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__image {
        width: 100%;
        margin-bottom: 1.1875rem;
        height: 160px; // height requested in image query set here as well to ensure height during drawer transition.
        object-fit: contain;
    }

    &__title > a {
        font-size: 1rem;
        letter-spacing: 0.0625em;
        line-height: 1.2em;
        font-family: var(--tertiary-font);
        color: $dark;
    }

    &__price {
        font-size: 1.2rem;
        font-family: var(--secondary-font);
        color: $dark;
    }

    &__fake {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        @include animate-flicker-light();

        &--image {
            height: 160px;
            width: 160px;
            background-color: $gray-ghost;
        }

        &--title {
            width: 100px;
            height: 20px;
            background-color: $gray-ghost;
        }
    }

    &.theme-dark {
        .HeaderSearchResult {
            &__image {
                border: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
            }

            &__title > a {
                color: $light;
            }

            &__price p {
                color: $light;
            }
        }
    }
}
