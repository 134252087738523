﻿@import '../../../../../Common/src/scss/components/react/OrderDetail.scss';

.OrderDetail {
    .OrderShipmentStatus {
        font-weight: bold;

        @media only screen and (min-width: 760px) {
            padding: 1px 0px 0px 8px !important;
        }

        @media only screen and (max-width: 600px) {
            padding: 0px 0px 0px 8px !important;
        }
    }
}
