.product__showroom,
.product__filter {
    .Locally {
        margin: 5rem 0;

        #lcly-button-0.lcly-pl-widget-parent {
            display: flex !important;
            flex-wrap: wrap;
            width: 100%;
            column-gap: 1rem;
            justify-content: center;
        }

        .lcly-dealers-wrap {
            display: flex;
            column-gap: 1rem;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
            margin: 0px;

            .lcly-dealer {
                width: 250px;
                font-size: 0.9rem;
                line-height: 1rem;
                .lcly-dealer-name {
                    font-size: 1.2rem;
                    line-height: 1rem;
                }

                .lcly-dealer-address {
                    font-family: var(--tertiary-font);
                }
            }
        }

        a.lcly-primary-trigger,
        button.lcly-primary-trigger {
            max-width: 270px;
            width: 100%;

            span {
                @extend .btn;
                cursor: pointer;
                line-height: 38px !important;
                width: 100%;
                background-color: var(--primary);
                color: var(--primary-contrast);
                font-size: 1.25rem !important;
                border-radius: 5px;
                padding: 12px 32px !important;
            }
        }
    }
}
