﻿@keyframes slidedown {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

.CatalogQuickView {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    animation-name: slidedown;
    animation-duration: 250ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    position: relative;
    padding: 2.5em 1em 2em 1em;
    margin-left: 0;

    .btn-close {
        position: absolute;
        top: 1em;
        right: 1em;

        @include media-breakpoint-up(md) {
            right: 0;
        }

        padding: 0;
        margin: 0;
    }

    &__flag {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0.25em 0.5em;

        &.primary {
            background: var(--primary);
        }

        &.secondary {
            background: var(--secondary);
        }

        > span {
            @include font-size(14px);
            font-family: var(--primary-font);
            font-weight: 900;
            text-transform: uppercase;
        }
    }

    &__buy {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        margin-top: 1em;

        > .btn {
            margin-left: 0.5em;
        }
    }

    &__image {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1 0 0%;
        margin: 0 2em;

        img {
            max-width: calc(100% - 2em);
            height: auto;
        }
    }

    &__details {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0 2em;
    }

    &__filters {
        flex: 1 0 0%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;

        .FacetFilter {
            margin-bottom: 1em;

            p {
                margin-top: 0 !important;
                margin-bottom: 0.5em !important;

                :not(:nth-child(1)) {
                    margin-left: 0.5em;
                }
            }

            .color-selector {
                .btn {
                    padding: 0;
                    margin-right: 1em;
                    border: 3px solid transparent;

                    &.selected {
                        border-color: var(--primary);
                    }
                }
            }

            .row.pt-2 {
                padding-top: 0 !important;
            }
        }
    }

    &__prices {
        color: black;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;

        > div:not(.CatalogQuickView__stock) {
            font-weight: bold;
        }
    }

    &__stock {
        font-weight: normal;
        margin-left: 1em;

        > span + span {
            padding-left: 0.75em;
            margin-left: 0.75em;
            border-left: 1px solid $black;
        }
    }

    &__price.strike {
        text-decoration: line-through;
        font-weight: normal;
    }

    &__reduced-price {
        color: $red;
        margin-left: 0.75em;
    }

    &__links {
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin-top: 1em;

        > li {
            display: block;

            > a {
                display: flex;
                align-items: center;

                > i {
                    margin-right: 0.5em;
                    font-size: 0.75em;
                }

                > span {
                    font-family: var(--tertiary-font);
                    @include font-size(16px);
                    font-weight: bold;
                    letter-spacing: 0.25px;
                }
            }

            &:not(:first-of-type) {
                margin-left: 1em;
            }
        }
    }

    .ProductView {
        
        margin-bottom: 10px;

        &__title {
            @include media-breakpoint-down('md') {
                justify-content: center;
                text-align: center;
            }
        }

        &__details {
            @include media-breakpoint-down('md') {
                flex-direction: column;
            }
        }

        .ProductDetails {
            .subheader {
                @include font-size(19px);
            }

            .ProductOptions {
                div.FacetFilter {
                    margin-top: 1rem;
                }
            }
        }

        .ProductTitle {
            p {
                text-align: start;

                @include media-breakpoint-down('md') {
                    text-align: center;
                }
            }
        }
    }
}

.FakeProductView {
    width: 100%;

    .FakeBlock {
        background: $gray-ghost;
    }

    .FakeImage {
        max-width: 100%;
        max-height: 50%;
        margin-bottom: 0.75rem;
        width: 550px;
        height: 450px;

        @include media-breakpoint-down(sm) {
            height: 200px;
        }
    }
}
