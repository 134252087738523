﻿.navigation__main {
    margin-bottom: 0;
    padding-top: 0px;
    padding-bottom: 0px;

    &.navbar-dark {
        .navbar-nav {
            .nav-link {
                color: var(--primary);
            }
        }
    }

    .navbar-nav .dropdown-menu {
        margin-top: 0;
        border: none;
        border-radius: 0px;

        .three-wide-grid .navigation__grid .dropdown-item {
            flex: 0 0 33%;
        }

        .four-wide-grid .navigation__grid .dropdown-item {
            flex: 0 0 25%;
        }
    }

    @media (hover: hover) {
        .dropdown:hover .dropdown-menu {
            display: block;
            border: 1px lightgray solid;
        }
        .dropdown-menu:hover {
            display: block;
        }
        .nav-item:hover .nav-link {
            background-color: var(--primary);
            color: $white;
        }
    }

    .dropdown-menu:focus {
        display: block;
    }

    .dropdown-toggle::after {
        content: none;
    }

    .nav-item {
        white-space: nowrap;
    }

    .nav-item.show,
    .nav-item:focus {
        .nav-link {
            background-color: var(--primary);
            color: $white;
        }
    }

    .navbar-container {
        flex: 1 0 0%;
        justify-content: center;
    }

    .empty-space {
        flex: 1 0 0%;
        margin-right: auto;

        &::after {
            content: ' ';
        }
    }

    .nav-item {
        margin-left: 0.4em;
        margin-right: 0.4em;
    }

    .nav-link {
        @extend .subheader;
        @include font-size(21);
        padding: 0.8em;
        padding-right: 0.6em !important;
        padding-left: 0.6em !important;
        line-height: 0.9em;

        &.has-children {
            pointer-events: none;
        }
    }

    .navbar__social {
        @include font-size(30);
        opacity: 0.5;
        flex: 1 0 0%;
        margin-left: auto;
    }

    @include media-breakpoint-up(lg) {
        .sub-nav.navbar-collapse {
            display: none !important;
        }
    }

    @include media-breakpoint-up(md) {
        .navbar-container {
            flex: 1 0 0%;
        }
    }

    @include media-breakpoint-down(md) {
        padding: 0px;
        z-index: 3;

        .nav-link {
            border-bottom: 2px solid transparent;
        }

        @media (hover: hover) {
            .nav-item:hover .nav-link {
                border-color: var(--primary);
                background-color: transparent;
            }
        }

        .nav-item.show,
        .nav-item:focus {
            .nav-link {
                border-color: var(--primary);
                background-color: transparent;
            }
        }

        .fa-chevron-right {
            font-size: 14px;
            float: right;
        }

        .navbar-collapse {
            position: fixed;
            top: 0;
            left: 0;
            padding: 40px;
            width: 100vw;
            height: 100%;
            z-index: 2;
            margin: 0px;
            overflow-y: scroll;

            .divider {
                height: 1px;
                background: $white;
                margin: 20px 0px;
            }

            h5 {
                font-size: 30px;
            }

            li {
                list-style: none;
            }

            button.navbar-toggler {
                float: right;
                top: 40px;
                right: 40px;
                border: none;
                color: $white;
            }

            .navbar-nav {
                margin-top: 60px;
            }

            .socials {
                p {
                    color: white;
                }

                a {
                    margin-right: 9px;
                }
            }
        }

        .navbar-collapse.collapsing {
            height: 100%;
            @include transition(left 0.2s linear);
            left: -100%;
        }

        .navbar-collapse.show {
            left: 0;
            @include transition(left 0.2s linear);
        }

        .sub-nav {
            right: -100%;
            padding: 40px;
            z-index: 4;

            .navbar-toggler {
                float: left;
                right: auto;
                left: 40px;
                position: absolute;

                i.fa {
                    transform: rotate(0deg);
                }
            }

            &.navbar-collapse {
                left: auto;
                right: 0;
            }

            &.navbar-collapse.collapsing {
                @include transition(right 0.2s ease-out);
                right: -100%;
            }

            &.navbar-collapse.show {
                right: 0;
                @include transition(right 0.2s ease-out);
            }

            .container {
                padding-bottom: 20px;
                padding-top: 20px;
            }

            .content {
                padding-top: 80px;
            }

            .menulink__item__mobile {
                p {
                    color: $white;
                }

                .container {
                    padding-right: 0px;
                    padding-left: 0px;
                }
            }
        }
    }
}
