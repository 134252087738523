﻿@keyframes dropin {
    from {
        max-height: 0px;
    }

    to {
        max-height: 250px;
    }
}

@keyframes fadeSlow {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.ShopByDropdown {
    z-index: 0;
    padding: 2rem 0rem;
    position: relative;
    transition: all 0.2s ease-out;

    .btn-close {
        position: absolute;
        right: 0px;
        top: 0px;
        padding: 2rem 0rem 2rem 2rem;
    }

    h5 {
        margin-bottom: 0px;
        animation: fadeSlow 0.3s ease-out;
    }

    .light {
        color: $light;
    }

    .dark {
        color: $dark;
    }

    .primary {
        color: var(--primary);
    }

    .secondary {
        color: var(--secondary);
    }

    ::-webkit-scrollbar {
        width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
        margin: 5px 0px;
        border-radius: 2.5px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $semi-dark;
        border-radius: 2.5px;
        transition: background 0.8s;
    }

    &__Values {
        margin: 2rem 0rem;
        overflow: auto;
        justify-content: center;
        align-items: center;
        max-height: 250px;
        animation: dropin 0.3s ease;
        margin-left: 8%;

        > div {
            overflow: auto;
            width: 200px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: flex-start;

            > label {
                display: flex;
                min-width: 0;
                width: 100%;
                animation: fadeSlow 0.3s ease-out;

                input[type='checkbox'] {
                    background: transparent;
                    border: 2px solid $light;
                    height: 20px;
                    min-height: 20px;
                    width: 20px;
                    min-width: 20px;
                    flex-shrink: 0;
                    .light {
                        border-color: $light;
                    }

                    .dark {
                        border-color: $dark;
                    }

                    &::before {
                        background: var(--primary);
                        left: calc(50% - 4px);
                        top: calc(50% - 3.9px);
                    }

                    &:checked,
                    &.checked {
                        background: transparent;
                    }
                }

                div {
                    font-size: 14px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.MobileShopBy.MobileFiltersMenu {
    padding: 1rem 2rem;
    .MobileFiltersMenu__btnClose {
        margin-right: 0px;
        i {
            float: right;
        }
    }

    .MobileFiltersMenu__list {
        label {
            div.input-checkbox {
                background: transparent;
                border: 2px solid $light;
                height: 20px;
                width: 20px;
                .light {
                    border-color: $light;
                }

                .dark {
                    border-color: $dark;
                }

                &::before {
                    background: var(--primary);
                    left: calc(50% - 4.1px);
                    top: calc(50% - 3.9px);
                }

                &:checked,
                &.checked {
                    background: transparent;
                }
            }
        }

        padding: 0px;
    }
}
