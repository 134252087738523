﻿.OrderConfirmationBlock {
    .divider {
        background: $black;
        height: 1px;
    }

    .btn-secondary {
        margin-bottom: 2.9rem;
        margin-top: 2.15rem;
    }
}
