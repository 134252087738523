.prostaffPage {
    .prostaffPage__protitle,
    .prostaffPage__title,
    .prostaffPage__awards {
        color: var(--secondary);
    }

    .prostaffPage__title {
        @include font-size($h2-font-size, true);
        letter-spacing: unset;
    }
}
