﻿[data-component='ProductView'] {
    @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.ProductView {
    animation: fade 0.3s linear;
    height: 100%;
    margin-bottom: 75px;
    padding: 0 !important;
    overflow: visible;

    &__details {
        display: flex;
        width: 100%;
    }

    .ProductTitle {
        overflow-wrap: break-word;
    }

    .ProductDetails {
        width: 100%;
        padding: 0;

        .subheader {
            @include font-size(19px);
        }

        .ProductOptions {
            .FacetFilter {
                margin-top: 1rem;

                .DropdownSelector > .btn > .row > * {
                    z-index: 1;
                }
            }

            .RadioSelector {
                .checkbox-group {
                    width: 100%;

                    > .row {
                        width: 100%;
                    }

                    @include media-breakpoint-down('md') {
                        > .row {
                            width: 95%;
                            margin: 0;
                        }
                    }
                }
            }

            .form-check-input {
                height: 18px;
                min-height: 18px;
                width: 18px;
                min-width: 18px;
            }
        }

        .clear-selection {
            padding: 0;
            font-family: var(--tertiary-font);
            text-transform: none;
            letter-spacing: 0.25px;
            font-size: 0.875rem;
            border: none;

            &:active {
                opacity: 0.7;
            }

            @media (hover: hover) {
                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;

        &__title {
            margin-top: 20px;
            justify-content: center;
            text-align: center;

            .ProductTitle {
                padding: 0px;
                justify-content: center;
            }
        }

        &__details {
            flex-direction: column;
        }
    }
}
