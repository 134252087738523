﻿@import '../../../../Common/src/scss/components/_user_menu.scss';

.user__menu {
    @include media-breakpoint-down(md) {
        .content {
            .user__menu__links {
                a {
                    color: var(--secondary);
                }
            }

            .contact {
                a {
                    color: $white;
                }
            }
        }

        .sub-nav {
            ul > li > a.nav-link {
                color: $white;
            }
        }
    }
}
