.Drawer {
    z-index: 50;
    pointer-events: none;
    position: fixed;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        transition: background-color 250ms ease-in-out;
        z-index: 0;
    }

    > .Drawer__slider {
        z-index: 1;
        pointer-events: none;
        background: $light;
        position: absolute;
        display: flex;
        align-items: 'center';
        justify-content: 'center';
        transition: transform 250ms ease-in-out;

        &.anchor-left,
        &.anchor-right {
            min-height: 100vh;
            max-height: 100vh;
            overflow-y: auto;
            overflow-x: hidden;
            flex-direction: column;
            top: 0;
            bottom: 0;
            @include media-breakpoint-down(sm) {
                min-width: 100vw;
                max-width: 100vw;
            }
            @include media-breakpoint-up(lg) {
                width: 25vw;
                min-width: 360px;
                max-width: 25vw;
            }
        }

        &.anchor-top,
        &.anchor-bottom {
            min-width: 100vw;
            max-width: 100vw;
            overflow-x: auto;
            overflow-y: hidden;
            flex-direction: row;
            left: 0;
            right: 0;
            @include media-breakpoint-down(sm) {
                min-height: 50vh;
                max-height: 50vh;
            }
            @include media-breakpoint-up(lg) {
                height: 25vh;
                min-height: 360px;
                max-height: 25vh;
            }
        }

        &.anchor-left {
            transform: translateX(-100%);
            left: 0;
            right: auto;
        }
        &.anchor-right {
            transform: translateX(100%);
            left: auto;
            right: 0;
        }
        &.anchor-top {
            transform: translateY(-100%);
            top: 0;
            bottom: auto;
        }
        &.anchor-bottom {
            transform: translateY(100%);
            top: auto;
            bottom: 0;
        }
    }

    &.open {
        &.backdrop {
            pointer-events: auto;
            &::before {
                background-color: rgba($dark, 0.5);
            }
        }
        > .Drawer__slider {
            pointer-events: auto;
            transform: translateX(0px) translateY(0px);
        }
    }
}
