﻿.Modal {
    .close-floating {
        @extend .close;
        position: absolute;
        top: 0rem;
        right: -30px;
        cursor: pointer;

        i {
            font-size: 30px;
            color: $white;
        }
    }

    &.modal {
        padding-right: 0px !important;
        .modal-content {
            border: none;
            border-radius: 0;
        }
    }

    @include media-breakpoint-down(md) {
        .close {
            display: none;
        }
    }
}
