﻿@import '../../../../../Common/src/scss/components/react/CatalogGrid.scss';

$border-color: $gray-light;

.CatalogGrid {
    margin-top: 1px;

    /* First row is product list container */
    > .row:first-child {
        /* Apply child borders. */
        > * {
            outline: 1px solid $border-color !important;

            @media (hover: hover) {
                &:hover {
                    outline: 1px solid $border-color !important;
                }
            }
        }

        .CatalogCard {
            /* Remove the child border, because we're applying it via the
               container now. */
            border: none;
        }
    }

    .CatalogGrid__load-more-container {
        > button {
            border: 1px solid $red;
            background-color: transparent;
            color: $red;
            @include font-size(zebcoToBootstrapFontSize(1.6rem));
            font-weight: bold;

            .fa-ellipsis-h {
                display: none;
            }

            .spinner-border {
                color: $red;
            }
        }
    }
}
