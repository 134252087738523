﻿.Table {
    p,
    h6 {
        margin: auto 0;
    }

    .display-4 {
        text-transform: uppercase;
    }

    td,
    th {
        vertical-align: middle;
        border: none !important;
        padding: 3px 0px;
        text-align: left;

        &[scope='col'] {
            border-top: none;
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
        width: 32px;
        height: 32px;
        padding: 0px;
        text-align: center;
    }

    .thumbnail {
        width: 82px;
        height: 49px;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    @include media-breakpoint-down(md) {
        th {
            min-width: 150px;
        }
    }
}
