﻿.registration__block {

    p.form__field-required.paragraph-2 {
        display: none;
    }

    .registration__block__title__border {
        display: none;
    }
}
