@import '../../../../../Common/src/scss/components/react/ContentSearchResults.scss';

.heading-space {
    height: 8rem;
    background: $dark;

    @include media-breakpoint-down(md) {
        height: 7rem;
    }
    @include media-breakpoint-down(xs) {
        height: 5rem;
    }
}

.ContentSearchResult {
    background-color: $black;

    &__excerpt {
        color: $light;
    }

    &__left-panel {
        background: $gray-light;

        .SearchFacets {
            border-bottom-color: lightgray;

            .FacetFilter {
                .subheader {
                    color: $semi-dark;
                    @include font-size(19px);
                }

                label {
                    span.subheader {
                        font-weight: 400;
                        @include font-size(1rem);
                        font-weight: 400;
                    }
                }
            }
        }

        .SearchLinks {
            &__link {
                .subheader {
                    color: $semi-dark;
                    @include font-size(1rem);
                    font-weight: 400;

                    &.header {
                        @include font-size(19px);
                        font-weight: bold;
                    }
                }
            }
        }
    }

    &__title {
        color: $light;
    }

    .desktop_button {
        button {
            border-color: $gray-light;
        }
    }

    .SearchResultTabs {
        .nav-link.active {
            border-bottom-color: var(--primary);
        }

        .mobile_buttons {
            border-color: $gray-light;

            .border-right {
                border-right-color: $gray-light !important;
            }
        }

        .slick-slider {
            .slick-track {
                .slick-slide {
                    border-color: transparent;
                }
            }

            button.right-arrow {
                background: $white;

                i {
                    @include font-size(13px);
                    color: $semi-dark;
                }
            }
        }

        .nav-tabs {
            a {
                &.active {
                    border-bottom-color: var(--primary);
                }
            }
        }

        &__content__result {
            a.subheader {
                span.subheader {
                    @include font-size(19px);
                }

                span.type {
                    @include font-size(15px);
                    border-color: $black;
                }
            }

            .excerpt {
                color: $gray-secondary;

                p {
                    @include font-size(1rem);
                }
            }
        }

        .SearchResultItem {
            .img-overlay {
                background: $black;

                .far.fa-play-circle {
                    font-size: 33px;
                    color: $white;
                }
            }
        }

        .SearchResultProduct {
            &__CrossSite {
                &__Site {
                    &.selected {
                        border-bottom-color: var(--primary);
                    }
                }
            }
        }

        .loadMore {
            @include font-size(19px);
            font-weight: 500;

            .spinner-border {
                border-color: currentColor;
                border-right-color: transparent;
                color: $white;
            }
        }
    }

    @media (hover: hover) {
        &:hover {
            .ContentSearchResult__title {
                h4 {
                    color: var(--primary);
                }
            }
        }
    }

    &.theme-dark {
        .heading-backdrop {
            background: $dark;
        }
        .result-count {
            color: $light;
        }
        .SearchBar {
            width: 100%;
        }

        .SearchResultTabs .nav-tabs a {
            color: $light !important;
            &.active {
                color: var(--primary) !important;
            }

            .Icon {
                color: var(--primary);
            }
        }

        .extra-container {
            .filter-drawer-link {
                a {
                    color: $light;
                }
            }
        }
    }

    .result-count {
        font-size: 0.875rem;
        margin-top: 0.15em;
    }

    .SearchResultProduct {
        .CatalogCard {
            &__links {
                flex-direction: column;
                li {
                    margin-left: 0px;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .SearchLinks {
            &__link {
                .subheader {
                    color: $semi-dark;
                    @include font-size(14px);

                    &.header {
                        @include font-size(1rem);
                    }
                }
            }
        }

        .SearchResultTabs {
            .excerpt {
                p {
                    @include font-size(14px);
                }
            }
        }
    }
}
