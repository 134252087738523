﻿.tabbed__content {
    padding-top: 90px;
    padding-bottom: 90px;

    & .subheader {
        color: var(--primary);
        margin-bottom: 0.6em;
    }

    & h1 {
        line-height: 0.83em;
    }

    & button.fa.fa-chevron-left,
    button.fa.fa-chevron-right {
        position: absolute;
        background-color: $light;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25) !important;
        border: none;
        width: 33px;
        height: 33px;
        z-index: 1;
    }

    & button.fa.fa-chevron-right {
        right: -15px;
        top: -4px;
    }

    & button.fa.fa-chevron-left {
        left: -31px;
        top: -4px;
    }

    & button.slick-disabled {
        display: none !important;
    }

    & .nav-tabs {
        border: 0;
        margin-top: 30px;
        margin-bottom: 10px;

        & .nav-item {
            width: auto !important;
        }

        & .nav-link {
            @extend .subheader;
            @include font-size(21);
            color: $black;
            border: 0;
            padding-left: 0;
            padding-right: 0;
            margin-left: 0.84em;
            margin-right: 0.84em;
        }

        & .nav-link.active {
            border-bottom: 2px solid var(--primary);
        }

        & .slick-list {
            padding: 0px !important;
        }

        & .slick-track {
            display: flex !important;
            justify-content: center !important;
        }

        @include media-breakpoint-down(sm) {
            & .nav-link {
                margin-left: 0.4em;
                margin-right: 0.4em;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        & h1 {
            font-size: 38px;
        }

        & p {
            font-size: 16px;
        }

        img {
            object-fit: contain;
            height: 300px !important;
            width: 300px !important;
        }
    }
}
