﻿.MediaConverter {
    &.Sirv {
        max-height: 85%;
        @include media-breakpoint-down(sm) {
            max-height: none;
        }
    }

    &.video {
        height: 100%;
        width: 100%;
        position: relative;
    }

    &.spin {
        position: relative;
        display: flex;
        justify-content: center;
    }

    .vid-thumb {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            font-size: 1rem;
            color: $white;
        }
    }
}
