.ProductSearchBar {
    @keyframes linkSpinner {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
    z-index: 3;
    position: absolute;
    left: 0;
    top: 0;
    margin: auto;
    width: 100%;
    padding-top: 110px;
    padding-bottom: 10px;
    animation: fade-in 0.2s linear;

    &__bar {
        width: 100%;

        input {
            background-color: transparent !important;
            border: none;

            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
                display: none;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
            }

            &::-ms-clear,
            &::-ms-reveal {
                display: none;
                width: 0;
                height: 0;
            }
        }

        input,
        input:focus,
        input:active {
            border: none !important;
        }
    }

    &__icon {
        margin-right: 1em;
        font-size: 1rem;

        .fa-spinner {
            animation: linkSpinner 0.5s linear 0s infinite;
            margin-right: 0.5em;
        }
    }

    .ProductSearchBarDrawer {
        transition: height 0.3s ease-in-out;
        overflow: hidden;

        > div {
            display: flex;
            align-items: flex-start;

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
        }

        &__links {
            width: 25%;
            margin: 0px;
        }

        &__results {
            padding-top: 1rem;
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            &__links {
                padding: 0px 20px;
                a {
                    margin-left: auto;
                    margin-right: 0;
                }

                @include media-breakpoint-down(sm) {
                    padding: 0px 10px;
                }
            }

            &__result {
                padding: 0px 20px;
                width: 25%;
                &.default {
                    width: 20%;

                    @include media-breakpoint-down(lg) {
                        width: 25%;
                    }

                    @include media-breakpoint-down(md) {
                        width: 33%;
                    }

                    @include media-breakpoint-down(sm) {
                        width: 50%;
                    }
                }

                @include media-breakpoint-down(md) {
                    width: 33%;
                }

                @include media-breakpoint-down(sm) {
                    width: 50%;
                    padding: 0px 10px;
                }

                &__image {
                    width: 100%;
                    margin-bottom: 1.1875rem;
                    height: 160px; // height requested in image query set here as well to ensure height during drawer transition.
                    object-fit: contain;
                }

                &__title {
                    margin-bottom: 0.8125em;
                }

                &__price {
                    margin-top: 0px;

                    .strike {
                        text-decoration: line-through;
                    }
                }
            }
        }

        &__links {
            &__link {
                margin-bottom: 1.7857em;

                a,
                p {
                    margin-bottom: 1.1785em;
                    cursor: pointer;
                }
            }
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;

            &__links {
                width: 100%;
            }
        }
    }
}

.background__color-primary {
    .ProductSearchBar {
        background: var(--primary);
    }
}

.background__color-secondary {
    .ProductSearchBar {
        background: var(--secondary);
    }
}

.background__color-tertiary {
    .ProductSearchBar {
        background: var(--tertiary);
    }
}

.background__color {
    &-light,
    &-transparent.navBg__color-light {
        .ProductSearchBar {
            background: $light;
        }
    }
}

.background__color {
    &-dark,
    &-transparent.navBg__color-dark  {
        .ProductSearchBar {
            background: $dark;
        }
    }
}
