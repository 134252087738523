.ProductAccordion {
    width: 100%;
    margin-top: 2em;

    > ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        font-size: 16px;

        > li {
            padding: 0.5em 0;
            border-top: 1px solid var(--primary);
            &:last-child {
                border-bottom: 1px solid var(--primary);
            }
            &.active {
                > .content {
                    margin-top: 1em;
                    margin-bottom: 1em;
                }
            }
            > a {
                text-align: left;
                color: var(--primary) !important;
                font-size: inherit;
                text-transform: uppercase;
                position: relative;
                display: block;
                width: 100% !important;
                height: 2em;
                line-height: 2em;
                > .Icon {
                    position: absolute;
                    right: 0;
                    top: 0.5em;
                    font-size: 14px;
                }
            }
            > .content {
                overflow: hidden;
                overflow-x: wrap;
                transition: 300ms max-height;
                max-height: 0;

                > div > ul:first-child > li:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}
