.CustomerPaymentManager {
    iframe:not(.gpay-card-info-iframe) {
        border-top: 1px solid black;
        margin-top: -1px;
        margin-bottom: -0.5rem;
        max-height: 3rem;
    }
    input {
        width: 100%;
    }

    .ErrorDisplay {
        color: red;
    }
}
