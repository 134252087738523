﻿@include media-breakpoint-up('lg') {
    .footer__links-row {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.footer__subscription {
    padding-top: 100px;
    padding-bottom: 100px;
    border-top: 1px solid $gray-light;

    & .alert-custom {
        color: $dark !important;
        background-color: $light !important;
        border-color: var(--primary) !important;
    }
}

.footer__social,
.footer__find-a-retailer-row {
    padding-top: 100px;
    padding-bottom: 100px;
    border-top: 1px solid $gray-light;
}

.footer__section {
    background-color: $gray-bg;
    color: var(--secondary) !important;

    .subheader {
        color: var(--secondary);
        padding-bottom: 10px;
        @include font-size(zebcoToBootstrapFontSize(2.2rem), true);
    }

    .copyright {
        p,
        a {
            color: var(--secondary);
        }
    }

    .footer__links-row {
        border-bottom: 1px solid #ccc;
    }

    @include media-breakpoint-down('md') {
        .container {
            margin-left: 0px;
            margin-right: 0px;
            max-width: 100%;
            width: 100%;
        }
    }

    .subscription__form {
        flex-flow: column wrap !important;
        width: auto;

        .subheader {
            max-width: unset;
            justify-content: center !important;
            margin-bottom: 1rem;
        }

        div {
            justify-content: center !important;
        }

        input {
            background-color: transparent !important;
            border: none !important;
            border-bottom: 1px solid var(--secondary) !important;
            float: left !important;
            color: $dark !important;

            &::placeholder {
                color: var(--secondary) !important;
                opacity: 60% !important;
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: var(--secondary) !important;
                opacity: 60% !important;
            }

            &::-webkit-input-placeholder {
                /* Microsoft Edge */
                color: var(--secondary) !important;
                opacity: 60% !important;
            }
        }

        button {
            border: none !important;
            border-bottom: 1px solid var(--secondary) !important;
        }
    }

    .social__icon {
        vertical-align: middle;

        .icon-tiktokicon {
            height: 45px;
            margin-left: -10px;
            margin-right: -10px;
            width: 45px;
        }
    }
}

@include media-breakpoint-down('sm') {
    .footer__subscription {
        padding-bottom: 0px;
    }

    .footer__social {
        padding-top: 50px;
    }
}

.footer__navgroup {
    & .navgroup__links {
        list-style: none;
        padding-left: 0;
        padding-bottom: 5px;
        @include font-size(14);

        @include media-breakpoint-down('md') {
            height: 0;
            overflow: hidden;
            transition: height 0.5s;
        }
    }

    .toggles {
        .expand {
            display: inline;
        }

        .collapse {
            display: none;
        }

        color: $red;
    }

    &.show {
        .navgroup__links {
            height: auto !important;
        }

        .toggles {
            .expand {
                display: none;
            }

            .collapse {
                display: inline;
            }
        }
    }

    & .navgroup__link a {
        font-weight: normal !important;
        line-height: 1.5;
        color: var(--secondary) !important;

        @include font-size(zebcoToBootstrapFontSize(1.6rem));

        @include media-breakpoint-down('md') {
            @include font-size(zebcoToBootstrapFontSize(2.8rem));
        }
    }

    .link-column-header {
        letter-spacing: unset;
        font-weight: 700;
        font-size: 2.2rem;
        line-height: 4rem;

        @include media-breakpoint-down('md') {
            border-bottom: 2px solid #ccc;
            @include font-size(zebcoToBootstrapFontSize(3.8rem), true);
        }

        a {
            color: var(--secondary);
        }

        a:hover {
            text-decoration: none;
        }
    }

    .btn {
        padding: 0px;
    }

    .accordion {
        margin-bottom: 1.5em;
    }
}
.footer__address {
    font-weight: normal;
    font-size: 16px;
}

.footer__email {
    font-size: 16px;
    color: var(--primary);
}

.footer__social i {
    margin-right: 3px;
    font-size: 36px;
    color: var(--secondary);
    vertical-align: middle;
}

.footer__bottom {
    font-size: 14px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10%;
    padding-left: 10%;
    font-family: var(--tertiary-font);
}

.footer__bottom a {
    color: $white;
    padding-left: 5px;
}

.footer__bottom-links {
    text-align: center;
    .footer__bottom-links-list {
        padding-left: 0;
        list-style-type: none;
        li {
            display: inline;
            + li::before {
                content: '|';
                margin-left: 1em;
                margin-right: 1em;
            }
        }
    }
}
