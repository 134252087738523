.BackgroundVideo {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    &--hideOnMobile {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__video {
        position: absolute;
        object-fit: cover;

        &.contain {
            object-fit: contain;
        }

        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;

        transform: translate(-50%, -50%);

        &::-webkit-media-controls {
            display: none !important;
        }
    }

    &__actions {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
        z-index: 2;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
    }

    &__toggleVolume {
        background-color: transparent;
        border: none;
        color: $white;
        font-size: 32px;
        cursor: pointer;
    }

    &__play {
        background: black;
        opacity: 0;
        border-width: 0;
        border-radius: 999px;
        border: none;
        color: $white;
        font-size: 92px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }
}
