.login__block {
    margin-top: 0;
    margin-bottom: 0;

    .login__block-heading {
        border-bottom: 1px solid $gray-light;
    }

    a.btn-lg {
        padding: 0.5em 2em;
        width: 100%;
        margin-top: 1em !important;
    }
}
