@import '../../../../../Common/src/scss/components/react/ProductSearchResults.scss';

.ProductSearchResults {
    &__products {
        &__info {
            &__pageinfo {
                font-size: 0.7368em;
                letter-spacing: 0.0714em;
                line-height: 1.428em;
                margin-bottom: 0px;
                color: var(--primary);
            }

            .ProductSearchResultsFilter {
                button.dropdown-btn {
                    @extend .btn-outline-primary;
                }

                &__dropdown {
                    background-color: $black;
                    color: $light;
                    box-shadow: 0 4px 8px 0 rgba($black, 0.2), 0 6px 20px 0 rgba($black, 0.19);

                    button {
                        color: var(--primary);
                        &:hover {
                            color: $light;
                        }
                    }
                }
            }

            &__breadcrumb {
                &__title {
                    font-size: 0.7368em;
                    letter-spacing: 0.0714em;
                    line-height: 1.428em;
                    margin-bottom: 0px;
                }
                button {
                    &.reset-filters {
                        color: $white;
                        text-decoration: underline;
                    }

                    &.filter {
                        @extend .btn-outline-light;
                    }
                }
            }
        }
        &__product {
            padding: 20px;
            > div {
                border: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
                padding: 20px;
            }

            p {
                color: var(--primary);
            }

            &.active {
                > div {
                    border: 1px solid var(--primary);
                    &::after {
                        content: ' ';
                        border: 1em solid var(--primary);
                        border-left-color: transparent;
                        border-right-color: transparent;
                        border-bottom: none;
                        position: absolute;
                        bottom: 1px;
                        left: 50%;
                        transform: translateX(-1em);
                    }
                }
            }
        }

        &__quickview {
            &__container {
                border-bottom: 1px solid var(--primary);
            }

            &__header {
                font-size: 1.1875rem;
                line-height: 1.8947em;
                letter-spacing: 0.1052em;
                color: var(--primary);
                margin-bottom: 1.57em;
            }

            &__close {
                color: var(--primary);

                .Icon {
                    height: 1.3125em;
                    width: 1.3125em;
                }

                &:hover {
                    color: var(--primary);
                }
            }

            .ProductBadge {
                .badge {
                    font-family: var(--secondary-font);
                    letter-spacing: 0.1em;
                    font-weight: bold;
                    font-size: 0.725rem;
                    border: none;
                    color: var(--primary);
                }
            }

            .ProductPrice {
                p {
                    color: $light;
                    font-family: var(--tertiary-font);
                    font-weight: 200;
                    letter-spacing: 0.1em;
                }
            }

            .ProductInput {
                .btn.btn-primary[disabled] {
                    color: $dark !important;
                }
            }

            .ProductDetailCard {
                font-size: 0.725rem;

                &__name {
                    h6 {
                        font-size: 0.875rem;
                        font-family: var(--primary-font);
                        letter-spacing: 0.15em;
                    }
                }

                table {
                    th,
                    td {
                        font-size: 0.725rem;
                        letter-spacing: 0.1em;
                    }
                    th {
                        font-family: var(--secondary-font);
                    }
                    td {
                        font-family: var(--tertiary-font);
                    }
                }

                button.NumberInput-btn {
                    color: $light !important;
                }
            }

            .ProductListTable {
                font-size: 0.85rem;

                &__input {
                    width: 10.98em;
                    height: 1.875em;
                    margin-top: 1.56em !important;
                    margin-left: 0px;

                    button {
                        font-size: 0.75rem;
                    }
                }

                table {
                    thead {
                        th {
                            font-family: var(--secondary-font);
                            font-weight: bold;
                            letter-spacing: 0.1em;
                            font-size: 0.725rem;
                        }
                    }

                    td {
                        letter-spacing: 0.1em;
                        font-family: var(--tertiary-font);
                        font-weight: 200;
                    }
                }
                &__head {
                    p {
                        font-family: var(--tertiary-font);
                        font-weight: 200;
                        letter-spacing: 0.1em;
                    }
                }
                &__tail {
                    .num-input {
                        position: relative;
                        font-size: 0.875rem !important;
                        width: 7rem;

                        .NumberInput {
                            box-sizing: border-box;
                            background: #ffffff20 !important;
                            margin: 0;
                            width: 100%;
                            font-size: inherit !important;
                            font-weight: 200;
                            padding: 0.5em 3em !important;
                            text-align: center !important;
                            &.disabled,
                            &:disabled,
                            &[disabled] {
                                background: none;
                                color: $gray-medium !important;
                                pointer-events: none;
                                + .btn {
                                    opacity: 0.33;
                                    pointer-events: none;
                                }
                            }
                        }

                        input + .btn {
                            right: 6px;
                            left: auto;
                        }
                        .btn {
                            position: absolute;
                            left: 6px;
                            padding: 0 0.5em;
                            bottom: 2px;
                            top: 2px;
                            background: none;
                            height: auto;
                            font-size: inherit !important;
                            color: $light;
                            &.disabled,
                            &:disabled,
                            &[disabled] {
                                opacity: 0.33;
                            }
                        }
                    }
                }
            }
        }
    }
}
