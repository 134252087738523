.content_card_carousel {
    .slick-slider {
        .slick-disabled {
            opacity: 0.5;
        }

        .slick-prev,
        .slick-next {
            &::before {
                content: none;
            }
            height: 30px;
            width: 45px;
            bottom: -58px;
            left: unset;
            top: unset;

            svg {
                color: var(--primary);
                height: 100%;
                width: 100%;
            }
        }

        .slick-prev {
            right: 50px;
            svg {
                transform: rotate(180deg);
            }
        }

        .slick-next {
            right: 0px;
        }

        .slick-dots {
            display: flex;
            bottom: -30px;
            width: 90%;
            li {
                &:only-child {
                    display: none;
                }

                opacity: 0.5;
                width: 100%;
                height: 1px;
                margin: 0px;
                button {
                    padding: 0px;
                    width: 100%;
                    height: 1px;
                    background-color: var(--primary);
                    &::before {
                        content: none;
                    }
                }

                &.slick-active {
                    opacity: 1;
                }
            }
            @include media-breakpoint-down(lg) {
                width: 80%;
            }

            @include media-breakpoint-down(sm) {
                width: 70%;
            }
        }

        .slick-track {
            display: flex !important;

            .slick-slide {
                height: inherit !important;
            }
        }
    }
}

.width__full-vw {
    .content_card_carousel {
        padding: 0px 25px;
    }
}
