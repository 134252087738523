﻿.CheckoutPage {
    .divider {
        height: 1px;
        background: $black;
    }

    .btn.btn-primary {
        width: 25vw;
    }

    h3 {
        margin-bottom: 0;
    }

    h6 {
        margin-top: 1rem;
    }

    p {
        margin-bottom: 0;
    }

    .btn[disabled],
    .btn.disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    iframe:not(.gpay-card-info-iframe) {
        border-top: 1px solid black;
        margin-top: -1px;
        margin-bottom: -0.5rem;
        max-height: 3rem;
    }

    .CheckoutSection {
        .PaymentForm {
            flex: 1;
        }

        &__header {
            margin-top: 2rem;

            &__actions {
                display: flex;
                flex-direction: row;
                align-items: flex-end;

                .btn {
                    padding: 1em;
                    height: 3em;
                }
            }
        }
    }

    fieldset {
        display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox; /* TWEENER - IE 10 */
        display: -webkit-flex; /* NEW - Chrome */
        display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 1rem;

        .Field {
            &.inline label {
                display: flex;
                align-items: center;
            }

            width: calc(100% - 1rem);
            display: flex;
            flex-direction: column;

            .ErrorDisplay {
                color: red;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        fieldset {
            .Field {
                &.inline input {
                    &[type='checkbox'] {
                        padding: 10px;
                    }
                }
            }
        }
    }
}
