.CatalogSort {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__label {
        font-family: var(--tertiary-font);
        color: $black;
        text-transform: uppercase;
        margin-right: 0.5em;
    }

    &__options {
        border: 1px solid $gray-medium;
        padding: 0;
        button {
            padding: 7px 7px !important;

            span {
                font-family: var(--tertiary-font) !important;
                font-weight: normal !important;
                font-size: 0.75rem;
            }
        }

        &-list {
            position: absolute;
            z-index: 2;
            display: flex;
            flex-direction: column;
            left: unset !important;
            right: 20px;
            background: $light;
            border: 1px solid $gray-medium;
            border-radius: 5%;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            button > span {
                font-family: var(--tertiary-font) !important;
                font-weight: normal !important;
            }
        }
    }

    @include media-breakpoint-down(md) {
        display: none;
    }
}
