.ProductChildTable {
    display: flex;
    justify-content: center;
    width: 100%;
    font-family: var(--tertiary-font);
    font-weight: normal;

    .Grid {
        
        &:not(.ProductChildTable__Grid--QuickView) {
            .Row:hover *{
                background-color: color-mix(in srgb, var(--primary), transparent 80%);
                i {
                    background: none !important;
                }
            }
        }

        &__cell {
            &--header {
                font-size: 1.25rem;
                font-weight: 700;
                text-transform: uppercase;
                color: var(--light);
                padding: 0.5rem 0;
            }

            &:not(.Grid__cell--header, .Grid__cell--interrupter) {
                font-size: 1rem;
                padding: 0.25rem;

                @include media-breakpoint-down(lg) {
                    padding-left: .5rem;
                }


                &:not(.Grid__cell--interrupter) {
                    cursor: pointer;
                }

                @include media-breakpoint-down(sm) {
                    font-size: .85rem;
                }
            }

            i {
                background-color: rgba(0,0,0,0);
            }
        }

        &.ProductChildTable__Grid--QuickView {
            .Grid {
                &__cell {
                    cursor: default !important;
                }
            }
        }
    }
}
