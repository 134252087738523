﻿@import '../../../../../Common/src/scss/components/react/DropdownSelector.scss';

.DropdownSelector {
    .btn-toolbar {
        button {
            background-color: white !important;
            border: 1px solid $red !important;

            &.selected {
                background-color: $red !important;
                color: $white;
            }
        }
    }
}
