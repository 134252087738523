﻿.CartSummary {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    a.btn {
        width: 100%;
        margin-bottom: 1rem;

        &[disabled] {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .sub {
        font-size: 0.9rem;
        margin: 2px 0px;
    }

    .separator {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        width: 100%;
        height: 2px;
        background-color: currentColor;
        opacity: 0.5;
    }

    .payment-method-container {
        width: 100%;
        overflow: hidden;
        transition: 200ms max-height;

        > .payment-method-contents {
            width: 100%;

            > .payment-method {
                > img {
                    width: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }

    .btn-toggle-payments {
        padding: 0.5em;
        text-decoration: none !important;
        color: inherit;
        > i {
            font-size: 0.9em;
            margin-right: 0.33em;
        }
    }
}
