.ProductDetails {
    &__sharepolicy,
    .DetailsLinks {
        a {
            color: $gray-primary;

            svg {
                font-size: 1.2rem;
                color: $light;
            }

            &:hover {
                text-decoration: none;
            }
        }

        .ToolTip {
            margin-right: unset !important;
        }
    }

    .DetailsLinks {
        align-items: center;
        gap: 3rem;
        justify-content: start;

        .ToolTip {
            display: flex;
            align-items: flex-start;
        }

        @include media-breakpoint-down(md) {
            justify-content: center;
            gap: 1rem;
        }
    }

    .ProductCompareLabel {
        color: $gray-primary;
        font-weight: 900;
        font-size: 1rem !important;
    }

    &__description {
        color: $gray-primary;
        margin-left: 1rem;

        @include media-breakpoint-down(md) {
            width: 90%;
        }
    }

    &__sharepolicy {
        margin-top: 1rem;
        justify-content: space-between;
        text-align: left;

        span {
            margin-right: 1rem;
        }

        @include media-breakpoint-down(sm) {
            gap: 0;

            span {
                margin-right: .5rem;
            }
        }
    }

    .ProductPrice {
        p {
            font-size: 1.25rem !important;
        }
    }

    .ProductTitle {
        p{
            font-size: calc(1.325rem + 0.9vw) !important;
        }
    }
}
