.shopby_carousel {

    &__title {
        flex: 1;
    }

    &__subtitle {
        flex: 1;
        font-weight: 300;
        letter-spacing: 1px;
        line-height: 24px;
    }

    @include media-breakpoint-up(xl) {
        &__subtitle {
            flex: 1.3;
        }
    }

    &__tabs {
        padding-top: 30px;
        padding-bottom: 20px;
        &__tab {
            margin-right: 5%;
            a {
                opacity: 0.5;

                &.text__color {

                    &-primary {
                        text-decoration-color: var(--primary);
                    }

                    &-secondary {
                        text-decoration-color: var(--secondary);
                    }

                    &-tertiary {
                        text-decoration-color: var(--tertiary);
                    }

                    &-light {
                        text-decoration-color: var(--light);
                    }

                    &-dark {
                        text-decoration-color: var(--dark);
                    }
                }

                &.active {
                    text-decoration: underline;
                    opacity: 1;

                    
                }
            }
        }
    }

    &__panels {
        position: relative;
        padding-bottom: 1em;
        &__panel {
            margin-bottom: 50px !important; //overriding .slick-dotted.slick-slider margin
            &__slide {
                align-items: center;
                display: flex !important;
                flex-direction: column;
                img {
                    width: 90%;
                    padding: 20px;
                    border: 1px solid;

                    &.card__color {
                        
                        &-primary {
                            border-color: var(--primary);
                        }

                        &-secondary {
                            border-color: var(--secondary);
                        }

                        &-tertiary {
                            border-color: var(--tertiary);
                        }

                        &-light {
                            border-color: var(--light);
                        }

                        &-dark {
                            border-color: var(--dark);
                        }
                    }
                }

                &__title {
                    padding-top: 15px;

                    span.strike {
                        text-decoration: line-through;
                    }

                    span.sale {
                        color: $red;
                    }
                }
                
                .btn-outline-secondary {
                    &:hover {
                        border-color: var(--secondary);
                    }
                }

                &__shopbtn {
                    top: 20px;
                    letter-spacing: 1.5px;
                    padding-bottom: 5px;
                }
            }
        }

        .slick-slider {
            .slick-disabled {
                opacity: 0.5;
            }

            .slick-prev,
            .slick-next {
                &::before {
                    content: none;
                }
                height: 30px;
                width: 45px;
                bottom: -58px;
                left: unset;
                top: unset;

                svg {
                    color: var(--primary);
                    height: 100%;
                    width: 100%;
                }
            }

            .slick-prev {
                right: 50px;
                svg {
                    transform: rotate(180deg);
                }
            }

            .slick-next {
                right: 0px;
            }

            .slick-dots {
                display: flex;
                bottom: -30px;
                width: 90%;
                li {
                    &:only-child {
                        display: none;
                    }

                    opacity: 0.5;
                    width: 100%;
                    height: 1px;
                    margin: 0px;
                    button {
                        padding: 0px;
                        width: 100%;
                        height: 1px;
                        background-color: var(--primary);
                        &::before {
                            content: none;
                        }
                    }

                    &.slick-active {
                        opacity: 1;
                    }
                }
                @include media-breakpoint-down(lg) {
                    width: 80%;
                }

                @include media-breakpoint-down(sm) {
                    width: 70%;
                }
            }
        }
    }
}

.width__full-vw .shopby_carousel {
    padding: 0 20px;
}
