﻿.CartTable {
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
    font-size: 1rem;

    .faux-item-bg {
        background-color: #ffffff;
        border-right: 10px #eaeaea solid;
        border-left: 10px #eaeaea solid;
    }

    .faux-item-bg:last-child {
        border-bottom: 20px #eaeaea solid !important;
    }

    .faux-head {
        border-top: 20px #eaeaea solid !important;
        border-left: 20px #eaeaea solid !important;
        border-right: 20px #eaeaea solid !important;
        border-bottom: 5px #eaeaea solid !important;
        padding: 0px !important;
    }

    > table {
        width: 100%;

        > thead > tr > td > span {
            font-family: var(--secondary-font), sans-serif;
            text-transform: uppercase;
        }

        tr {
            width: 100%;

            &.dirty {
                > td.total,
                td.qty,
                td.price {
                    background-color: lightgray;
                }
            }

            td {
                padding: 1rem 0;
                vertical-align: top;
                min-width: 4.5rem;
                border-bottom: 2px solid lightgray;

                > div.price {
                    font-weight: $font-weight-bolder;

                    > span + span {
                        color: red;
                    }

                    > span.strike {
                        text-decoration: line-through;
                    }
                }

                > div {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                }

                &.img > img {
                    min-height: 124px;
                    max-height: 124px;
                    width: auto;
                }

                &.qty {
                    text-align: center;

                    > input {
                        width: 3em;
                        padding: 0.5em 0;
                        text-align: center;
                        -webkit-appearance: textfield;

                        &::-webkit-inner-spin-button,
                        &::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }
                }

                &.total {
                    text-align: right;
                }

                span.subheader {
                    @include font-size(19px);
                }

                &.name {
                    width: 100%;
                }
            }
        }
    }

    .table {
        width: 100%;

        .tablehead {
            border-bottom: 2px solid lightgray;
            padding: 1rem 0;

            > div > span {
                font-family: var(--secondary-font), sans-serif;
                text-transform: uppercase;
            }
        }

        .tablerow {
            padding: 1rem 0;
            border-bottom: 2px solid lightgray;

            p {
                margin-bottom: 0px;
            }

            &.dirty {
                .subtotal,
                .quantity,
                .price {
                    background-color: lightgray;
                }
            }

            .tablecell {
                &.item,
                &.image {
                    img {
                        height: 124px;
                        width: 100%;
                        object-fit: contain;
                    }

                    .name {
                        width: 100%;
                    }
                }

                &.price {
                    font-weight: $font-weight-bolder;

                    > p + p {
                        color: red;
                    }

                    > p.strike {
                        text-decoration: line-through;
                    }
                }

                &.quantity {
                    text-align: center;

                    > input {
                        width: 3em;
                        padding: 0.5em 0;
                        text-align: center;
                        -webkit-appearance: textfield;

                        &::-webkit-inner-spin-button,
                        &::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }
                }

                &.options {
                    text-align: center;

                    &.option-line {
                        &.btn-nopadding {
                            padding: 0 !important;
                        }
                    }

                    .btn.btn-link > a {
                        padding: 0 !important;

                        strong {
                            font-weight: normal;
                        }
                    }
                }

                &.subtotal {
                    text-align: right;
                }
            }

            .quickview {
                padding: 0;
            }

            @include media-breakpoint-down(sm) {
                .tablecell {
                    margin-top: 8px;
                    padding-right: 0px;

                    &.price {
                        font-weight: normal;
                    }

                    &.quantity {
                        text-align: left;

                        span {
                            margin-right: 5px;
                        }

                        input {
                            width: 36px;
                            padding: 4px 0;
                        }
                    }
                }
            }
        }

        span.subheader {
            @include font-size(19px);
            word-break: break-word !important;
        }

        .update-btns {
            .btn {
                padding: 10px;
            }
        }

        .EditProductView {
            &__submit-btns {
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: $spacer;
                flex-wrap: wrap;

                div {
                    flex-grow: 1;
                }
            }
        }

        .CatalogQuickView {
            margin-right: 5px;
            border-bottom: none;

            .ProductDetails {
                padding-right: 1rem !important;
            }
        }

        @include media-breakpoint-down(md) {
            .update-btns {
                .CatalogQuickView {
                    padding-left: 5px;
                    padding-right: 5px;

                    .submit-btns {
                        .btn {
                            padding: 10px 20px;
                        }
                    }
                }
            }
        }
    }
}
