@import '../../../../../Common/src/scss/components/react/SearchTabbedFacet.scss';

.SearchTabbedFacet {
    .TabbedContent {
        &__tab {
            font-size: 1.2rem;

            border-bottom-color: transparent;

            &.selected {
                border-color: var(--primary);
            }

            @media (hover: hover) {
                &:hover {
                    border-color: var(--primary);
                }
            }

            @include media-breakpoint-down(md) {
                font-size: 1rem;
            }
        }
    }
}
