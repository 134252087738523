@import '../../../../../Common/src/scss/components/react/ProductCompare.scss';

.ProductCompare {

    &__table__product-link {
        height: 100%;
    }

    &__table__out-of-stock {
        display: flex;
        flex: 1 1 100%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
        align-content: center;
        height: 100%;
        width: 100%;
        text-align: center;
        border: 1px solid $primary;
        position: relative;
        max-height: 2.65rem;
        min-height: 2.65rem;
        
        button.disabled {
            border: none;
            
            opacity: 1;
        }

        .msg-outOfStock {
            background: $white;
            color: $primary;
            font-weight: bold;
            font-family: $button-font;
            font-size: .875rem;
            height: 100%;
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            align-self: stretch;
            padding-top: 7px;
            padding-bottom: 7px;
            vertical-align: middle;
            text-wrap: nowrap;

            @include media-breakpoint-down(sm) {
                font-size: .8rem;
            }
        }
    }

    .ProductInput {
        margin-top: 0;
        height: 100%;

        button.active {
            filter: brightness(100%) !important;
            padding-right: 7px !important;
            padding-left: 7px !important;

            @include media-breakpoint-down(sm) {
                font-size: .8rem;
                padding: 0 !important;
            }

        }

        .PureTip {
            
            &.top {
                top: 10px !important;
            }
        }
    }
}
