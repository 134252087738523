.content_card_row {
    @extend .col;

    .content_card_learn {
        &:first-child {
            padding-left: 0px;
        }

        &:last-child {
            padding-right: 0px;
        }
    }
}

.width__full-vw {
    .content-card-row {
        @extend .px-5;
    }
}
