﻿.VideoPlayerModal {
    .modal {
        padding-right: 0px !important; //override styling applied on bootstrap modal.
    }

    .modal-dialog {
        .modal-content {
            border-radius: 0px;
            border: none;
            background: $black;

            .close {
                position: absolute;
                right: 0px;
                top: 0px;
                color: $black;
                @include font-size(40px);
                padding: 1rem;
                z-index: 2;
            }
        }

        .VideoPlayer {
            height: 100%;
            width: 100%;
            video {
                outline: 0px;
            }
        }

        .modal-footer {
            border: none;
            padding-top: 0px;
        }
    }
}
