$timeline-vertical-bar-width: 10px;
$timeline-tick-width: 25px;
$timeline-image-left-margin: 15px;
$timeline-image-size: 278px;
$timeline-popout-horizontal-offset: 100px;
$timeline-popout-width: 400px;
$timeline-popout-height: 100px;
$timeline-popout-connector-height: 10px;
$timeline-year-tick-left-offset: 100px;

$zindex-scroller-base: 100;

.timelinescroller {
    .timelinescroller__ticks {
        list-style-type: none;
        position: absolute;
        left: 0;
        top: 0;
        padding-left: 0;

        li {
            position: absolute;
            color: $white;
            left: ($timeline-image-size * .5) - ($timeline-vertical-bar-width * .5) + $timeline-image-left-margin -
                $timeline-year-tick-left-offset;

            //the "ticks" themselves
            &::after {
                position: absolute;
                background-color: $white;
                width: $timeline-tick-width;
                height: 5px;
                left: $timeline-year-tick-left-offset + ($timeline-vertical-bar-width * .5) - ($timeline-tick-width * .5);
                top: 10px;
                content: ' ';
            }
        }
    }

    .timelinescroller__imagebackdrop {
        position: absolute;
        width: $timeline-image-size;
        height: $timeline-image-size;
        border-radius: 50%;
        left: $timeline-image-left-margin;
        content: ' ';
        background-color: $white;
        z-index: $zindex-scroller-base;
    }

    .timelinescroller__timeline {
        position: relative;
        list-style-type: none;
        padding-left: 0;

        li {
            position: absolute;
            width: $timeline-image-size;
            height: $timeline-image-size;
            left: $timeline-image-left-margin;
            border-radius: 50%;

            .timelinescroller__imagewrap {
                border-radius: 50%;
                overflow: hidden;
                width: $timeline-image-size;
                height: $timeline-image-size;

                .timelinescroller__imageyear {
                    color: $white;
                    position: absolute;
                    left: 50px;
                    top: 50px;
                    z-index: $zindex-scroller-base + 2;
                }

                img {
                    max-width: 100%;
                    max-height: 100%;
                    border-radius: 50%;
                    position: relative;
                    z-index: $zindex-scroller-base + 1;
                }
            }
            .timelinescroller__popout-wrap {
                position: absolute;
                left: 0.5 * $timeline-image-size;
                top: ($timeline-image-size * .5) - ($timeline-popout-height * .5) +
                    ($timeline-popout-connector-height * .5);
                width: $timeline-popout-horizontal-offset + $timeline-popout-width + 0.5 * $timeline-image-size;
                min-height: $timeline-popout-height * 2;

                .timelinescroller__popout {
                    position: absolute;
                    background-color: $white;
                    width: $timeline-popout-width;
                    min-height: $timeline-popout-height;
                    opacity: 0.9;
                    border-radius: 10px;

                    p {
                        margin-bottom: 0;
                    }

                    //the connector to the popout
                    &:before {
                        background-color: $white;
                        position: absolute;
                        width: 100%;
                        height: $timeline-popout-connector-height;
                        top: ($timeline-popout-height * .5) - ($timeline-popout-connector-height * .5);
                        left: -$timeline-popout-horizontal-offset;
                        content: ' ';
                    }
                }
            }
        }

        //vertical bar
        &:after {
            position: absolute;
            width: $timeline-vertical-bar-width;
            left: $timeline-image-left-margin + ($timeline-image-size * .5) - ($timeline-vertical-bar-width * .5);
            height: 100%;
            background-color: $white;
            content: ' ';
        }
    }
}

.timelinescroller__mobile {
    .timelinescroller__mobilecard {
        .timelinescroller__mobilecard-body {
            h3 {
                @include font-size(3.2rem, true);
                font-weight: normal;
                @if (variable-exists(special-font)) {
                    font-family: $special-font;
                }
            }

            border-bottom: 1px solid $gray-medium;
        }
    }
}
