﻿.mast__section {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $light;

    &__image {
        width: 100%;
        height: var(--mastImageHeight, 250px);
        min-height: 100px;
        z-index: -1;
        object-fit: cover;
        background-color: var(--secondary);
    }

    &__text {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-title,
        &-subtitle {
            text-align: center;
            margin: 0;
            padding: 0;
        }

        &-subtitle {
            @include font-size(19px);
            font-family: var(--secondary-font);
            font-weight: 200;
            letter-spacing: 2px;
        }
    }

    @include media-breakpoint-down(sm) {
        &__image {
            height: auto;

            &.no-image {
                height: 150px;
            }
        }
    }
}
