﻿.CartItemListing {
    div.toolbar {
        display: flex;
        flex-direction: row;
        width: 100%;

        > .span {
            flex: 1 0 0%;
            user-select: none;
        }

        > button {
            padding: 0.5rem 2rem;
            margin-right: 1rem;
            transition: opacity 250ms, background-color 250ms, color 250ms;
            border: none;

            > span {
                font-size: 1rem;
            }

            &:last-of-type {
                margin-right: 0;
            }

            &[disabled] {
                opacity: 0.5;
                pointer-events: none;
            }

            &:not(.btn-link) {
                background: black;
                color: white;

                @media (hover: hover) {
                    &:hover {
                        color: white;
                    }

                    &:not(.disabled):not([disabled]):hover {
                        background-color: var(--primary);
                    }
                }
            }

            @include media-breakpoint-down(md) {
                &.btn-small {
                    padding: 0.5rem 1rem;
                    margin: 5px 2px;
                }
            }

            @include media-breakpoint-down(sm) {
                width: 80%;
            }
        }
    }
}
