@import '../../../Common/src/scss/blocks/_locationsearchblock.scss';

.locationsearchblock {
    &__header {
        font-size: 1.5rem;
        color: var(--primary);
    }

    &__input-container:focus-within,
    &__input-container:hover {
        display: flex;
        border: 2px solid var(--primary);
    }
}

