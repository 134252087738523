﻿.navigation__list {
    padding-top: 40px;
    padding-bottom: 40px;

    & .dropdown-item {
        font-family: var(--secondary-font);
        font-size: 24px;
        letter-spacing: 2px;
        color: black;
        flex: 1 1 10px;

        @media (hover: hover) {
            &:hover {
                background-color: var(--primary);
                color: var(--secondary);
            }
        }

        &:focus {
            background-color: var(--primary);
            color: var(--secondary);
        }
    }
}
