﻿@import '../../../../Common/src/scss/blocks/_shopbycarousel.scss';

.shopby_carousel {
    &__panels {
        &__panel {
            &__slide {
                &__shopbtn {
                    padding: 0.75rem;
                }

                &__title {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
    }

    .SvgIcon {
        color: var(--primary);
    }

    &__title {
        font-size: 3.1rem !important;
    }
}

h2,
h3,
h3,
h4,
h5,
h6 {
    text-transform: uppercase !important;
}
