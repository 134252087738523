﻿@import '../../../../../Common/src/scss/components/react/MobileFiltersMenu.scss';

.MobileFiltersMenu {
    &__list > ul > li {
        background: inherit;
        color: var(--secondary);
        text-transform: uppercase !important;

        button.selected {
            color: var(--primary) !important;
        }

        &__head {
            text-transform: uppercase !important;
        }
    }

    &__btnBack {
        text-transform: none;
    }

    &__btnApply {
        font-weight: 400;
        color: black;

        &:disabled {
            background-color: $gray-primary;
            color: $gray-ghost;
        }
    }
}
