.Section {
    @include make-row();

    & > header {
        @include make-col(12);
    }

    &.sidebars {
        > .Section__sidebarLeft,
        > .Section__sidebarRight {
            @include make-col(12);

            @include media-breakpoint-up(md) {
                @include make-col(2);
            }
        }

        > .Section__content {
            @include make-col(12);

            @include media-breakpoint-up(md) {
                @include make-col(8);
            }
        }
    }

    &.sidebar {
        > .Section__sidebarLeft,
        > .Section__sidebarRight {
            @include make-col(12);

            @include media-breakpoint-up(md) {
                @include make-col(3);
            }
        }

        > .Section__content {
            @include make-col(12);

            @include media-breakpoint-up(md) {
                @include make-col(9);
            }
        }
    }

    &.no-sidebar {
        > .Section__content {
            @include make-col(12);
        }
    }

    .Section {
        margin: auto;
    }
}
