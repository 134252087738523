﻿@import '../../../../../Common/src/scss/components/react/ProductGallery.scss';

.ProductGallery {
    .slick-slide button {
        border: 2px solid $black;

        &.selected {
            border: 2px solid var(--primary);
        }

        img {
            padding: 5px;
        }
    }

    .Carousel .CarouselItem {
        font-size: 1rem;
        width: 5em;
        height: 5em;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        .MediaConverter,
        img {
            height: 100% !important;
            width: 100% !important;
            object-fit: cover !important;
        }

        @include media-breakpoint-down(sm) {
            font-size: 0.67rem;
        }
    }

    @include media-breakpoint-down(sm) {
        .slick-slide button {
            border: none !important;
        }

        .slick-dots {
            li button:before {
                background: none;
                border: 1px solid $black;
            }

            li.slick-active button:before {
                background: $black;
            }
        }
    }
}
