.socialbannerblock {

    .banner__block {
        margin: 0 auto;
        flex-wrap: wrap;
    }

    .title {
        &-text-sm {
            font-family: var(--primary-font);
            font-weight: bold;
            font-size: 48px;
            line-height: $line-height-base;
            text-transform: uppercase;

            span {
                display: inline;
                width: auto;
            }

            @include media-breakpoint-down('md') {
               font-size: 32px;
            }
        }

        &-text-lg {
            font-family: var(--primary-font);
            font-weight: 900;
            font-size: 62px;
            line-height: $line-height-base;
            text-transform: uppercase;

            @include media-breakpoint-down('md') {
                font-size: 54px;
            }
        }
    }
}
