$animationDelay: 400ms;

.PagedCarousel {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 90vw;
    margin: 0 auto;

    &__slider {
        transition: $animationDelay margin-left;
        display: flex;
        flex-direction: row;

        & > * {
            transition: $animationDelay opacity;
            flex-shrink: 0;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        &--gap {
            @for $i from 1 through 15 {
                &-#{$i} {
                    gap: $i * 10px;
                }
            }
        }
    }

    &__item > div > img {
        height: unset !important;
        width: unset !important;
    }

    &__item {
        cursor: pointer;
        .MediaConverter.fit-image-nav.spin {
            height: 220px;
            width: 220px;

            .spin-thumb {
                top: 50%;
                left: 50%;
                right: unset;
                transform: translate(-50%, -50%);
                z-index: 2;

                svg {
                    z-index: 2;
                    opacity: .8;
                    height: 200px;
                    width: 200px;
                }
            }

            img {
                opacity: .7;
                height: 220px;
                width: 220px;
            }
        }
    }
}
