﻿.MobileFiltersMenu {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: white;
    transition: 300ms opacity;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &.shown {
        pointer-events: auto;
        opacity: 1;
    }

    &.hidden {
        pointer-events: none;
        opacity: 0;
    }

    .btn {
        color: inherit;
        text-align: left;
    }

    &__toolbar {
        padding: 1em 1em;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    &__btnBack,
    &__btnClose {
        padding: 1em;

        > i + span {
            margin-left: 0.33em;
        }

        > span + i {
            margin-left: 0.33em;
        }
    }

    &__btnClose {
        margin-right: 1em;
        > i {
            font-size: 1.2em;
        }
    }

    .MobileFiltersMenu__btnApply {
        padding: 0.5em 1em;
        color: white;
        background-color: var(--primary);
        transition: 300ms background-color, 300ms color;
        width: 100%;
        text-align: center;
        border: none;
        margin-top: 2em;

        &:disabled {
            background-color: $gray-secondary;
            pointer-events: none;
        }
    }

    &__list {
        padding: 0 2em;
        flex: 1 0 0%;
        overflow-y: scroll;

        > ul {
            width: 100%;
            padding: 0;
            margin: 0;
            margin-top: 0.5em;

            > li {
                width: 100%;
                padding: 0;
                border: none;

                > .Selector {
                    &.button {
                        justify-content: space-between;
                    }
                    width: 100%;
                    margin: 0.7em 0;
                    padding: 0.3em 0;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    &.selected {
                        color: var(--secondary);
                    }
                }
            }
        }
    }
}
