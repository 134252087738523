.block.multipagecheckoutblock {
    position: relative;
    width: 100vw;
    left: calc(-1 * (100vw - 100%) / 2);
    min-width: 100vw;
    max-width: 100vw;
    padding: 0;
}

.__overtaken__ {
    display: none !important;
}

.MultiPageCheckout {
    display: flex;
    position: relative;
    @include media-breakpoint-up(lg) {
        padding: 0 2rem;
    }

    &__mobile-header {
        height: 5rem;
        position: absolute;
        justify-content: center;
        align-items: center;
        width: 100%;
        z-index: 2;
        top: 0;
        left: 0;

        .icon-logo {
            font-size: 3rem;
        }
    }

    &__header {
        .icon-logo {
            font-size: 6rem;
        }
    }

    &__nav {
        margin-top: 1.5em;

        &__link {
            margin-left: 0.5em;

            .Icon {
                margin-right: 0.5em;
            }

            &:first-child {
                margin-left: 0;
                .Icon {
                    margin: 0;
                    display: none;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        flex-direction: column-reverse;

        &__col.col-left {
            width: 100%;
            padding: 2rem;
        }

        &__col.col-right {
            width: 100%;
            padding: 1rem 2rem;
            margin-top: 5rem;
        }
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;

        &__col.col-left {
            flex: 5 1 0;
            padding: 4rem;
        }

        &__col.col-right {
            flex: 3 1 0;
            min-width: 22rem;
            max-width: 40rem;
            padding: 11.5rem 2rem 2rem 2rem;
            min-height: 100vh;
        }
    }

    &__PaymentInformation {
        .PayPalLoading,
        .AmazonLoading,
        .ApplePayLoading {
            width: 100%;
            padding: 0 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .PayPalButtonsContainer,
        .AmazonPayButtonContainer,
        .google-pay-button-container,
        .apple-pay-button {
            margin: 0 auto;
        }

        .btn-clear {
            flex: 1 1 0%;
            min-width: 18rem;
            padding: 1em;
        }

        .PaymentDisplay {
            font-size: 1rem;
            display: flex;

            .icon {
                font-size: 3.5em;
                margin-right: 0.2em;
                opacity: 0.75;
                line-height: 1em;
            }
            .details {
                font-size: 0.75em;
                position: relative;

                span {
                    display: block;
                    text-align: left;
                    &.exp {
                        text-align: left;
                        float: left;
                    }
                    &.cvv {
                        text-align: right;
                        float: right;
                        flex: 1;
                    }
                }
            }
        }

        .FormContainer > .PaymentDisplay {
            font-size: 1.2rem;
            margin: auto;
            .icon {
                opacity: 1;
            }
        }

        button {
            // @media (hover: hover) {
            //     &:not([disabled]):not(:disabled):not(.disabled):hover .payment-logo {

            //     }
            // }
            .payment-logo h6 {
                font-size: 1rem;
                text-transform: none;
                font-family: var(--secondary-font);
                font-weight: 300;
                margin: 0;
                min-width: 9rem;
                max-width: 9rem;
                height: 3rem;
                line-height: 3rem;
                border: 1px solid $light;
                text-align: center;
            }
            .payment-logo:not(.CreditCard) {
                overflow: hidden;
                flex: 0 0 0%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 2.5rem;
                min-width: 9rem;
                max-width: 9rem;
                height: 3rem;
                background: $white;
                color: $black;
                &.GooglePay .Icon {
                    font-size: 4rem !important;
                    color: $white;
                    margin-top: -0.5rem;
                }
                &.ApplePay .Icon {
                    font-size: 3.25rem;
                    color: $white;
                    margin-top: -0.125rem;
                }
                &.AmazonPay .Icon {
                    margin-top: 0.25rem;
                }
            }
            .payment-logo.CreditCard {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }

            .CreditCardIssuers {
                font-size: 2.3rem;
                i {
                    padding: 0 2px;
                    color: $dark;
                    background: rgba($light, 0.5);
                    transition: 250ms background-color ease-in-out;
                    &.active {
                        background-color: var(--primary);
                    }
                    & + i {
                        margin-left: 0.2em;
                    }
                }
            }
        }

        .PaymentForm {
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;
            padding: 0 0.8rem;
            @include media-breakpoint-down(xs) {
                padding: 0;
            }

            .PaymentSelector {
                flex: 1 1 0%;
                min-width: 18rem;
            }

            .FormContainer {
                flex: 5 5 0;
                min-width: 20rem;

                .PaymentBlock {
                    margin: 1em;
                }
            }

            &.error {
                border: 2px dotted $danger;
                padding: 0.5em;
            }
        }
    }

    &__content {
        &__page {
            display: none;

            &.active {
                display: block;
            }

            button[disabled],
            button:disabled,
            button.disabled {
                opacity: 1 !important;

                > * {
                    opacity: 0.65 !important;
                }

                input[disabled],
                input:disabled,
                input.disabled {
                    opacity: 1 !important;
                }
            }

            .Recap.checkout-grid > button > * {
                opacity: 1 !important;
            }

            .checkout-buttons {
                margin-top: 1.5em;
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                justify-content: space-between;

                .btn-primary {
                    @include media-breakpoint-down(xs) {
                        padding: 1em;
                        width: 100%;
                    }
                    padding: 1.15em 5.7em;
                }
                .btn-inverted {
                    padding: 1.15em 2em;
                }

                @include media-breakpoint-down(lg) {
                    flex-direction: column;
                }
            }

            .checkout-grid {
                border-bottom: none;
                position: relative;

                &.checkout-section > .checkout-section__title {
                    position: absolute;
                    top: -1.33em;
                }

                > button,
                > .AccordionDropdown > .card > .card-header > .btn {
                    font-size: 1rem;
                    background: none;
                    border: none;
                    padding: 1.5em;
                    width: 100%;
                    display: block;

                    .heading {
                        font-size: 0.875em;
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        > div,
                        > div > div {
                            text-align: left;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        .radio {
                            margin-right: 1em;
                        }
                        > :first-child:not(.radio),
                        .radio + div {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            align-items: center;
                            flex: 1;
                            gap: 0.5em 1em;

                            > * {
                                float: left;
                                &.right {
                                    float: right;
                                }
                            }
                        }
                        // > :not(:first-child) {
                        // }
                    }

                    input[type='radio'],
                    input[type='checkbox'] {
                        margin: 0;
                    }

                    label {
                        line-height: 1em;
                        display: flex;
                        align-items: center;
                    }
                }

                > .AccordionDropdown > .card > .drawer > .card-body {
                    margin: 0 !important;
                    font-size: 0.875rem;
                    padding: 0.5rem;
                }
            }

            .checkout-section {
                margin-top: 2rem;

                &__fieldset {
                    margin-top: 0.875em;
                    .Field:first-child {
                        margin-top: 0;
                        &.col-6 + .Field.col-6 {
                            margin-top: 0;
                        }
                    }
                }

                .email-links {
                    margin: 1em 0;
                    p {
                        width: auto;
                        display: block;
                        float: left;
                        margin: 0 0.75rem;
                        span {
                            margin-right: 0.75rem;
                        }
                        a {
                            font-size: inherit;
                            letter-spacing: 2px;
                        }
                        &:first-child {
                            margin-left: 0;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }

                .CharCounter {
                    margin: 0;
                    position: absolute;
                    right: 1.25rem;
                    bottom: 0.5rem;
                    transform: translateX(-100%);
                    transform: translateY(-100%);
                    font-size: 0.67rem;
                    color: $gray-medium;
                    &.invalid {
                        color: $danger;
                    }
                }
                input[disabled] + .CharCounter {
                    opacity: 0.33;
                }
            }
        }
    }

    .CheckoutSummary {
        font-size: 1rem;
        overflow: hidden;
        transition: max-height 350ms;
        padding: 0;
        min-height: 100%;
        display: flex;
        flex-direction: column;

        .recap-container {
            padding-top: 0.5em;
            > .recap:first-child {
                margin-top: 0;
            }
        }

        .recap {
            color: inherit;
            margin-top: 0.5em;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: none;
            border: none;
            padding: 0.33em 0.5em;
            font-size: 0.875rem;
            * {
                text-align: left;
                .edit-action {
                    color: var(--primary);
                }
            }

            @include media-breakpoint-down(xs) {
                align-items: flex-end;
                > div {
                    flex-direction: column;
                }
            }

            > div:first-child {
                display: flex;
                > div:first-child {
                    margin-right: 1em;
                }
            }

            + .recap {
                margin-top: 0;
            }
        }

        .Tag {
            display: flex;
            flex-direction: row;
            font-size: 0.75em;
            padding: 0.5em 1.25em;
            width: auto;
            position: relative;
            float: left;
            height: auto;

            &__content {
                display: flex;
                align-items: center;
            }

            button {
                padding: 0;
                margin-left: 1em;
                padding-bottom: 0.25em;
            }
        }

        .PromoCode,
        .GiftCardGateway {
            .alert {
                margin-bottom: 0;
            }
        }

        .Promotions,
        .GiftCards {
            margin-top: 0.75em;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 0.5em;
        }

        &__head {
            font-size: 1rem;
            > div {
                display: flex;
                flex-direction: column;
            }

            > div:first-child {
                flex-direction: row;
                justify-content: space-between;
                button {
                    padding-top: 0;
                    padding-bottom: 0;
                    .Icon {
                        margin-left: 0.5em;
                    }
                }
            }

            padding-bottom: 1em;
        }

        .CartListing {
            padding: 0 !important;
            display: flex;
            flex-direction: column;
            flex: 1 1 0%;

            &__item-count {
                font-size: 0.875em;
                margin: 1em 0;
            }

            &__price {
                margin-top: 1em;
                padding-top: 1em;
                user-select: none;
            }

            &__total {
                margin-top: 1em;
            }

            &__inputs {
                padding-top: 1em;
                display: flex;
                flex-direction: column;
                > form + div {
                    margin-top: 1em;
                }
            }

            &__footer {
                color: var(--primary);
                font-size: 0.75em;
                width: 100%;
                margin: 1rem 0 1rem 0;
                flex: 1 1 0%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
            }

            .CartItem {
                padding-bottom: 1rem;
                &__price {
                    justify-content: flex-end;
                }
            }

            .recap + .CartItem {
                margin-top: 0.5em;
            }

            .price-detail {
                padding: 0 0 0 1em;
                margin-top: 0.75em;
            }
        }
    }
}
