@import '../../../../../Common/src/scss/components/react/MultiPageCheckout.scss';

.MultiPageCheckout {
    background: $dark;
    color: $light;

    input:not([type='checkbox']):not([type='radio']):not([type='submit']),
    select {
        background-color: rgba($light, 0.1);
        border: 2px solid color-mix(in srgb, var(--primary), transparent 80%);
        color: $light;
        font-weight: normal;
        padding: 0.5rem;

        > option {
            background: $dark;
            color: var(--primary);
        }

        &:focus,
        &:active {
            border-color: var(--primary);
        }

        &:-webkit-autofill {
            -webkit-text-fill-color: $light !important;
            background-color: rgba($light, 0.1) !important;
            transition: background-color 50000s ease-in-out 0s;
        }
    }

    .PromoCode,
    .GiftCardGateway {
        display: flex;
        flex-direction: column-reverse;
        padding-top: 0 !important;

        .subheader {
            display: none;
        }

        .apply .dropdown .drawer {
            padding-bottom: 0.5rem;
            padding-right: 0;
        }
    }

    .PromoCode .dropdown,
    .GiftCardGateway .dropdown,
    .AccordionDropdown .card {
        transition: border-color 0.15s ease-in-out;
        border: 2px solid color-mix(in srgb, var(--primary), transparent 80%);

        .btn {
            font-weight: normal;
        }

        @media (hover: hover) {
            &:hover {
                border-color: var(--primary);
            }
        }
    }

    .AccordionDropdown .card {
        .btn {
            color: $light;
        }

        @media (hover: hover) {
            .btn:hover {
                color: var(--primary);
            }
        }
    }

    .Field label {
        color: $light;
        display: flex;
        margin-bottom: .5rem;
    }

    input[type='checkbox'],
    input[type='radio'],
    div.input-checkbox,
    div.input-radio {
        background-color: rgba($light, 0.1);
        border-color: color-mix(in srgb, var(--primary), transparent 80%);

        &:checked {
            background-color: var(--primary);
        }
    }

    @media (hover: hover) {
        .btn.btn-inverted:hover {
            color: $light;
        }
    }

    &__mobile-header {
        background: $dark;

        .icon-logo {
            transition: font-size 200ms, max-height 200ms;
            font-size: var(--header-logo-height, 9rem);
            max-height: var(--header-logo-height, 2em);
            max-width: 100%;
            height: 100vh;
            object-fit: contain;
            object-position: center center;

            @include media-breakpoint-down(md) {
                max-height: calc(var(--header-logo-height, 9rem) * 7 / 9);
            }

            @include media-breakpoint-down(xs) {
                max-height: calc(var(--header-logo-height, 9rem) * 5 / 9);
            }
        }
    }

    &__header {
        .icon-logo {
            font-size: 9rem;
            height: 0.3em;
        }
    }

    &__col.col-right {
        background: $light;
    }

    &__nav {
        &__link {
            opacity: 1 !important;
            color: color-mix(in srgb, var(--primary), transparent 50%);
            font-family: var(--secondary-font);
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 1px;
            transition: color 250ms;

            &.active {
                text-decoration: underline;
                text-decoration-color: currentColor;
                text-decoration-thickness: 1px;
            }

            &.active,
            &:active,
            &:focus,
            &:focus-visible {
                color: var(--light);
            }

            &.disabled,
            &[disabled],
            &:disabled {
                color: color-mix(in srgb, var(--light), transparent 30%);
                opacity: 1 !important;
            }


            &:not(.disabled):not([disabled]):not(:disabled) {
                &.active,
                &:active,
                &:focus,
                &:focus-visible {
                    color: var(--primary);
                }

                @media (hover: hover) {
                    &:hover {
                        color: var(--primary);

                        &:not(.active) {
                            text-decoration: none;
                        }
                    }
                }
            }

            .Icon {
                color: color-mix(in srgb, var(--primary), transparent 50%);
                pointer-events: none;
            }
        }
    }

    &__CustomerInformation {
        a {
            transition: color 0.15s ease-in-out;
            color: var(--primary);

            @media (hover: hover) {
                &:hover {
                    color: $light;
                }
            }
        }
    }

    &__PaymentInformation {
        .PaymentSelector > .card > .drawer > .card-body {
            @include branded-scroll-y($size: 0.5rem);
            max-height: 12.16rem;
        }

        button {
            .payment-logo h6 {
                font-size: 0.6rem;
            }
        }
    }

    &__content {
        &__page {
            .checkout-grid {
                border: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
                border-bottom: none;

                > .AccordionDropdown {
                    > .card {
                        border: none;
                        border-bottom: 1px solid color-mix(in srgb, var(--primary), transparent 80%) !important;

                        > .card-header > button {
                            border: none !important;
                            font-family: var(--tertiary-font);
                            font-weight: 300;
                            letter-spacing: 1px;
                            color: $light;

                            @media (hover: hover) {
                                &:not(:disabled):not([disabled]):not(.disabled):hover {
                                    color: var(--primary) !important;
                                }
                            }
                        }
                    }
                }

                > button {
                    color: $light;
                    font-family: var(--tertiary-font);
                    font-weight: 300;
                    letter-spacing: 1px;
                    border-bottom: 1px solid color-mix(in srgb, var(--primary), transparent 80%);
                }
            }

            .checkout-section {
                &__title {
                    color: $light;
                    font-family: var(--tertiary-font);
                    font-size: 1rem;
                    font-weight: 600;
                    letter-spacing: 0.132em;
                    text-transform: uppercase;
                }

                &__subtitle {
                    color: $light;
                    font-family: var(--tertiary-font);
                    font-size: 0.875rem;
                    font-weight: 300;
                    letter-spacing: 1px;
                    margin-top: 0.5em;
                }

                p {
                    font-size: 14px;
                    font-family: var(--tertiary-font);
                    font-weight: 300;
                    color: $light;
                    letter-spacing: 1px;
                }
            }

            .checkout-buttons {
                .btn {
                    font-size: 0.875rem;

                    > span {
                        font-family: var(--primary-font);
                        letter-spacing: 1px;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .CheckoutSummary {
        background: $light;
        font-family: var(--tertiary-font);

        &__head {
            background: $light;
            border-bottom: 2px solid var(--primary);

            &__collapse-btn {
                line-height: 1;
            }

            @include media-breakpoint-down('md') {
                margin-top: 0.3rem;
                line-height: 1;
            }

            > div:first-child {
                flex-direction: row;
                justify-content: space-between;

                .Icon {
                    stroke-width: 3px;
                }

                button {
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-right: 10px !important;
                    padding-left: 10px !important;

                    .Icon {
                        margin-left: 0.4em;
                        font-size: 1rem;
                        padding-bottom: 4px;
                    }
                }
            }

            .title {
                font-family: var(--tertiary-font);
                font-size: 1rem;
                font-weight: 600;
                letter-spacing: 0.132em;
                text-transform: uppercase;

                @include media-breakpoint-down('md') {
                    display: flex;
                    flex: none;
                }
            }

            button {
                font-size: 0.875em;
                color: $dark;
            }
        }

        .Tag {
            font-family: var(--primary-font);
            font-weight: bold;
            background: $dark;
            color: $light;
            border-radius: 1.25em;
            text-transform: uppercase;

            button {
                color: $light;

                @media (hover: hover) {
                    &:hover {
                        color: $danger;
                    }
                }
            }
        }

        .alert-danger {
            width: 100%;

            p {
                margin: 0;
            }
        }

        .CartListing {
            &__price,
            &__inputs {
                border-top: 2px solid var(--primary);
                color: $dark;

                input:not([type='checkbox']):not([type='radio']):not([type='submit']),
                select {
                    color: $dark !important;

                    &:-webkit-autofill {
                        -webkit-text-fill-color: $dark !important;
                    }
                }
            }

            &__price {
                font-family: var(--tertiary-font);

                > div {
                    .value {
                        font-weight: 300;
                    }

                    &:not(:first-child) {
                        margin-top: 1em;
                    }
                }
            }

            &__item-count {
                color: var(--primary);
                font-family: var(--tertiary-font);
                font-size: 0.875em;
                font-weight: 300;
                letter-spacing: 1px;
            }

            &__total {
                border-top: 2px solid var(--primary);
            }

            &__footer {
                color: $dark;
            }

            .price-detail .label {
                font-weight: 300 !important;
            }
        }
    }
}

.MultiPageCheckout.dark {
    .MultiPageCheckout__col.col-right {
        background: $gray-dark;
    }

    .CheckoutSummary {
        background: none;
        font-size: 1rem;

        &__head {
            background: none;
            border-bottom: 1px solid color-mix(in srgb, var(--primary), transparent 80%);

            button {
                color: $light;
            }

            .title {
                color: $light;
            }
        }

        .recap {
            * {
                .edit-action {
                    color: var(--primary) !important;
                }
            }
        }

        .recap-container {
            color: $light;
            font-family: var(--tertiary-font);
        }

        .CartListing {
            &__price,
            &__inputs {
                border-top: 1px solid var(--primary);
                color: var(--primary);
                font-family: var(--tertiary-font);

                input:not([type='checkbox']):not([type='radio']):not([type='submit']),
                select {
                    color: $light !important;

                    &:-webkit-autofill {
                        -webkit-text-fill-color: $light !important;
                    }
                }
            }

            &__item-count {
                color: var(--primary);
                font-family: var(--tertiary-font);
            }

            &__total {
                border-top: 2px solid var(--primary);
            }

            &__footer {
                color: var(--primary);
            }
        }

        .recap {
            &-value {
                color: var(--primary);
            }
        }
    }

    .PromoCode .dropdown,
    .GiftCardGateway .dropdown {
        .btn {
            color: $light;
        }

        @media (hover: hover) {
            .btn:hover {
                color: var(--primary);
            }
        }
    }
}
