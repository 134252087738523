.Carousel {
    width: 100%;
    padding: 0 2em;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    > .btn {
        z-index: 2;
        position: absolute;
        font-size: inherit;
        width: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        bottom: 0;

        > i {
            font-size: 30px;
        }

        &.left {
            left: 0;
        }
        &.right {
            right: 0;
        }
    }

    &__container {
        position: relative;
        height: 100%;
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        &__list {
            z-index: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: auto;
            max-width: none;
            top: 0;
            left: 0;
            position: absolute;
            outline: none !important;

            > .CarouselButton {
                width: auto;
                height: auto;
                flex-shrink: 0;
                margin-left: 1em;
                z-index: 0;

                &:last-child {
                    margin-right: 1em;
                }
            }
        }
    }
}
