﻿$filter-font-family: var(--tertiary-font);

.FilterDropdown {
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: column;

    .btn > span {
        font-family: var(--primary-font);
    }

    & > .btn {
        display: flex;

        span {
            font-weight: bolder;
            flex-grow: 1;
            text-align: start;
        }
    }

    .filterdropdown__header {
        @include font-size(zebcoToBootstrapFontSize(1.6rem));
        font-weight: bold;
    }

    .filterdropdown__option {
        @include font-size(zebcoToBootstrapFontSize(1.6rem));
        &:hover {
            background-color: $gray-light;
        }

        &.selected {
            background-color: $gray-medium;
            border: 1px solid $gray-dark;
        }
    }

    .FilterDropdown__button-list {
        display: flex;
        flex-direction: column;
        align-items: start;

        button {
            width: 100%;
            text-align: start;

            &.selected {
                background-color: #d4d4d4;
                border: 1px solid #1d1b1c;
            }
        }

        label {
            span {
                vertical-align: middle;
                font-weight: normal;
                @include font-size(zebcoToBootstrapFontSize(1.6rem));
            }
        }
    }
}
