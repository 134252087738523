﻿@import '../../../../Common/src/scss/sections/_header.scss';

.header__banner {
    text-align: center !important;
    @include font-size(zebcoToBootstrapFontSize(1.2rem));
    background: var(--secondary);
    color: $white;
    text-align: center;

    @media (hover: hover) {
        &:hover {
            color: $white;
        }
    }
}

.user-menu-list {
    list-style-type: none;
    margin-right: 20px;

    li {
        display: inline;

        a {
            @include font-size(zebcoToBootstrapFontSize(2.5rem));
            color: var(--secondary);
        }
    }
}

.navbar-toggler .fa-bars {
    color: var(--secondary) !important;
    background-color: transparent;
    @include font-size(zebcoToBootstrapFontSize(1.8rem));
}

.header__mast {
    background-color: $white;
    border-bottom: 1px solid $gray-light;
    @include font-size(zebcoToBootstrapFontSize(1.6rem));

    a {
        color: var(--secondary);
    }

    .header__mast__logo {
        @include media-breakpoint-down(md) {
            max-width: 102.5px;
        }

        @include media-breakpoint-up(lg) {
            max-width: 180px;
        }

        height: auto;
    }

    .header__user button,
    a.header__login {
        @extend .paragraph-3;
        font-family: var(--tertiary-font);
    }

    .header_user {
        button {
            i.fa-chevron-down {
                font-size: 14px;
            }
        }
    }

    .dropdown-menu:after {
        content: '';
        position: absolute;
        right: 0;
        top: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 2px 10px 10px 10px;
        border-color: transparent transparent $white transparent;
        z-index: 9999;
    }

    .search {
        font-family: var(--tertiary-font);
        @include font-size(14);
        letter-spacing: 0.25px;
        text-transform: none !important;
    }

    button.header__minicart {
        padding: 0 0 5px 0 !important;
    }

    @include media-breakpoint-down(md) {
        padding: 7px 0px;
    }
}

.header__search,
.header__usermenu {
    height: 72px;
}

.header__usermenu {
    .dropdown-menu {
        z-index: $zindex-popover;
    }
}

.header__search {
    .search-icons {
        right: 0;
        top: 0;
    }
}

.education-button {
    width: 260px;
    background-color: var(--primary);
    border: none;
    @include font-size(zebcoToBootstrapFontSize(1.3rem));
    font-family: $special-font;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;

    &.active {
        background-color: $gray-light;

        .education-button-text {
            color: var(--primary);
        }
    }

    .education-button-text {
        text-align: center;
        flex-grow: 1;
        color: $white;
    }

    .education-button-logo {
        height: 100%;
        color: $white;
    }

    &.mobile {
        width: 100%;
    }

    @media (hover: hover) {
        &:hover {
            text-decoration: none;
        }
    }
}

.tooltip.AlertTip.mini-cart-tip,
.tooltip.AlertTip.user-menu-tip {
    .arrow,
    .arrow::before {
        background-color: $light;
        border-left-color: $light !important;
    }
}

.header__minicart {
    font-size: 1.5625rem;
    color: #584446;

    small {
        padding-left: 4px;
        padding-right: 4px;
        background-color: $red;
        color: $white;
        position: absolute;
        right: -7px;
        top: 0px;
        border-radius: 16px;
        height: 16px;
        text-align: center;
        line-height: 18px;
        @include font-size(zebcoToBootstrapFontSize(1.6rem));
    }
}
