/*
=========================== 
BOOTSTRAP OVERRIDES:
Since we are moving away from bootstrap scss variables and relying on css custom properties, 
we wan to override certain bootstrap classes in which a css variable should be used instead of sass variable. 
Ex. var(--primary) instead of $primary. Since this is an override of bootstrap classes, we should only 
need one version in Common and not an override per site. 
===========================
*/

/* Buttons Classes */
.btn-primary,
.btn-primary:disabled {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--primary-contrast);

    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        background-color: var(--primary);
        border-color: var(--primary);
        filter: brightness(95%);
    }
}

.btn-secondary {
    background-color: var(--secondary);
    border-color: var(--secondary);
    color: var(--secondary-contrast);

    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        background-color: var(--secondary);
        border-color: var(--secondary);
        filter: brightness(95%);
    }
}

.btn-outline-primary {
    border-color: var(--primary);
    color: var(--primary);

    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        color: var(--primary-contrast);
        background-color: var(--primary);
        border-color: var(--primary);
    }
}

.btn-outline-secondary {
    border-color: var(--secondary);
    color: var(--secondary);

    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        color: var(--secondary-contrast);
        background-color: var(--secondary);
        border-color: var(--primary);
    }
}

.btn-outline-tertiary {
    border-color: var(--tertiary);
    color: var(--tertiary);

    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        color: var(--light);
        background-color: var(--tertiary);
        border-color: var(--tertiary);
    }
}

.btn-outline-dark {
    border-color: var(--dark);
    color: var(--dark);

    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        color: var(--light);
        background-color: var(--dark);
        border-color: var(--dark);
    }
}

.btn-outline-light {
    border-color: var(--light);
    color: var(--light);

    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        color: var(--dark);
        background-color: var(--light);
        border-color: var(--light);
    }
}

/* Link Classes */
.btn-link {
    color: var(--link-color);
}

a {
    color: var(--link-color);
}
