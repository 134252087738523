.ProductLinkQuickview {
    //position: relative;
    a.link {
        cursor: pointer;
    }
}

.ProductViewPopup {
    position: absolute;
    //left: 50vw;
    //transform: translateX(-50%);
    top: 0;
    left: 0;
    border: 1px solid black;
    width: auto;
    max-width: calc(50vw);
    background-color: white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    padding: 1rem;

    @include media-breakpoint-down(lg) {
        min-width: 75vw;
    }

    @include media-breakpoint-down(md) {
        min-width: 100vw;
    }

    &.popup {
        .close-btn {
            height: auto;
            width: auto;
            padding: 5px;
            position: absolute;
            right: 10px;

            i {
                font-size: 24px;
                color: $black;
            }
        }

        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
        z-index: 1;
        background: $white;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s ease-out;

        .ProductView,
        .ProductDetails {
            width: 100%;
        }

        &.show {
            visibility: visible;
            opacity: 1;
        }
    }
}
