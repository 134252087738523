﻿@import '../../../../../Common/src/scss/components/react/CartItemListing.scss';

.CartItemListing {
    div.toolbar {
        > button:not(.btn-link) {
            background-color: transparent;
            color: $red;
            border: 1px solid $red;

            &:hover {
                background-color: $gray-light !important;
                color: $red !important;
            }
        }
    }
}
