﻿.width__full-vw {
    min-width: 100vw !important;
    max-width: 100vw !important;
    position: relative;
    left: calc(-1 * (100vw - 100%) / 2);
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.width__full-vw--no-pad-override {
    min-width: 100vw !important;
    max-width: 100vw !important;
    position: relative;
    left: calc(-1 * (100vw - 100%) / 2);
}

html,
body {
    font-family: var(--font-family-base);
    padding: 0 !important;
    overflow-x: hidden;
    scroll-behavior: smooth;
    .tooltip {
        //used to hide default bootstrap tooltip until we apply our custom styling to it which starts with AlertTip below.
        visibility: hidden;
    }

    .AlertTip.show {
        visibility: visible;
    }

    .tooltip.AlertTip.user-menu-tip.mobile {
        left: 1px !important;
        top: 5px !important;
    }

    @include media-breakpoint-down(md) {
        .tooltip.AlertTip.mini-cart-tip {
            width: 25%;
        }
    }
}

body {
    @media (hover: hover) {
        input:not([type='checkbox']):hover,
        button:hover,
        a:hover,
        textarea:hover {
            outline: 0px !important;
            -webkit-appearance: none;
            box-shadow: none !important;
        }
    }

    textarea:active,
    input:not([type='checkbox']):active,
    textarea:focus,
    input:focus,
    button:focus,
    button:active,
    a:focus,
    a:active,
    label:focus,
    .btn:active,
    .btn.active {
        outline: 0px !important;
        -webkit-appearance: none;
        box-shadow: none !important;
    }

    .title {
        font-size: 62px;
        font-weight: bold;
    }

    .subtitle {
        font-size: 23px;
        font-weight: bold;
    }
}

.col-0 {
    display: none;
}
.flex-1 {
    flex: 1 1 0% !important;
}
.flex-2 {
    flex: 2 1 0% !important;
}
.flex-3 {
    flex: 3 1 0% !important;
}
.flex-4 {
    flex: 4 1 0% !important;
}
.flex-5 {
    flex: 5 1 0% !important;
}
.flex-6 {
    flex: 6 1 0% !important;
}
.flex-7 {
    flex: 7 1 0% !important;
}
