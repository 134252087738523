﻿label,
input,
div.input-checkbox,
div.input-radio,
select,
option {
    font-family: var(--tertiary-font) !important;
    @include font-size(16);
    letter-spacing: 0.25px;
    line-height: 24px;
}

input:not([type='checkbox']):not([type='radio']):not([type='submit']),
select {
    box-sizing: border-box;
    border: 2px solid $black;
    font-weight: bold;
    letter-spacing: 0.1px;

    &.invalid,
    &[aria-invalid='true'] {
        border-width: 2px;
        border-color: rgba($red, 0.4) !important ;
        color: $danger;

        &::placeholder {
            color: rgba($danger, 0.5);
        }
    }
}

input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='number']) {
    padding: 0.5rem 0.7rem;
}

@media (hover: hover) {
    input[type='checkbox']:not(.disabled):not(:disabled):not([disabled]) + p,
    input[type='radio']:not(.disabled):not(:disabled):not([disabled]) + p {
        cursor: pointer;
    }
}

// To make disabled input Label text opaque, use label[for="*InputId*"]
input:disabled,
select:disabled,
input.disabled,
select.disabled,
input[disabled],
select[disabled],
div.input-checkbox.disabled,
div.input-checkbox[disabled],
div.input-checkbox:disabled,
div.input-radio.disabled,
div.input-radio[disabled],
div.input-radio:disabled,
select:disabled + .PhoneInputCountryIcon,
select:disabled + .PhoneInputCountrySelectArrow,
select[disabled] + .PhoneInputCountryIcon,
select[disabled] + .PhoneInputCountrySelectArrow,
select.disabled + .PhoneInputCountryIcon,
select.disabled + .PhoneInputCountrySelectArrow,
input[type='checkbox']:disabled + p,
input[type='checkbox'].disabled + p,
input[type='checkbox'][disabled] + p,
input[type='radio']:disabled + p,
input[type='radio'].disabled + p,
input[type='radio'][disabled] + p {
    opacity: 0.25;
    cursor: default;
}

input:not([type='checkbox']):not([type='radio']):not([type='submit']):focus {
    border: 2px solid var(--primary);
    box-shadow: inset 0 -1px 0 transparent;
}

.CheckoutSection__select {
    select {
        height: 42px !important;
        width: 100% !important;
    }
}

.btn-inverted {
    background: transparent;
    border-color: transparent;
    color: var(--primary);
}

.Field {
    small[role='alert']:not(.ErrorDisplay) {
        display: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
}

.btn.btn-angled {
    position: relative;
    background: none !important;
    z-index: 1;
    border: none !important;

    &::before {
        content: '';
        z-index: -1;
        background: var(--primary);
        transform: skew(-15deg, 0);
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

.btn.btn-half-pad {
    padding: 0.5em 1em;

    &.btn-offset-top {
        padding-top: 0.6em;
    }
}

.btn.btn-offset-top {
    padding-top: 18px;
}

.num-input {
    position: relative; /* without this, the number input buttons are not aligned properly */
}
