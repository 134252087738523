﻿.CheckoutSummary {
    padding: 2.5em;

    &__Costs, &__Total {
        .row {
            padding: .2em 0px;
        }
    }

    &__Total {
        p > strong {
            letter-spacing: .25px;
        }
    }

    &__Divider {
        border-bottom: 1px solid;
        margin-top: .2em;
        margin-bottom: .5em;
    }

    &__LineItems {
        .card-header {
            padding: 0px;

            button.btn {
                width: 100%;
                margin-top: 1em;
                padding: 0px;
                padding-top: 10px;
                text-transform: none;
            }

            button[aria-expanded="true"] {
                i {
                    transform: scaleY(-1);
                    -moz-transform: scaleY(-1);
                    -webkit-transform: scaleY(-1);
                    -ms-transform: scaleY(-1);
                    font-size: 1em;
                }
            }
        }

        &__Items {
            padding-top: .5rem;

            p {
                @extend .paragraph-2;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 1.5em;
    }
}
