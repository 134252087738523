﻿.AddressForm {
    input:not([type='checkbox']) {
        width: 100%;
    }

    select {
        width: 100%;
    }

    label {
        font-size: 12px;
        margin-top: 0.5rem;
        margin-bottom: 0;

        span::after {
            position: relative;
        }
    }

    div:last-child {
        label {
            display: flex;
            align-items: flex-end;
        }
    }

    small {
        color: red;
    }
}

.FormAddressElement {
    select {
        width: 100%;
    }
}

.AddressSelector {
    margin-bottom: 1rem;
}
