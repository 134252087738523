﻿.prostaffPage {
    padding-top: 60px;
    padding-bottom: 60px;

    & .breadcrumb {
        font-family: var(--secondary-font);
        color: $dark;
    }

    &__protitle {
        color: #232a2d;
        font-family: var(--secondary-font);
        font-size: 23px;
        font-weight: 500;
        letter-spacing: 2px;
        line-height: 19px;
        margin-bottom: 20px;
    }

    &__title {
        color: #000000;
        font-family: var(--primary-font);
        font-size: 83px;
        letter-spacing: -2.67px;
        line-height: 60px;
        text-align: center;
        text-transform: uppercase;
    }

    &__awards {
        color: #232a2d;
        font-family: var(--secondary-font);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.88px;
        line-height: 36px;
        text-align: center;
    }

    .big-banner {
        background-size: cover;
        background-repeat: no-repeat;
        padding: 105px 0px 15px;
        margin-left: 0;
        margin-right: 0;
    }

    .quote-box {
        position: relative;
        margin-bottom: 40px;
        width: 80%;
        display: block;

        &:before {
            content: open-quote;
            display: block;
            position: absolute;
            left: -64px;
            font-size: 150px;
            top: -44px;
            color: var(--primary);
        }

        h2 {
            color: $white;
            @include font-size(51px);
            font-weight: bold;
            letter-spacing: 0.67px;
            line-height: 33px;
            margin-bottom: 23px;
        }

        p {
            color: $white;
            @include font-size(19px);
            letter-spacing: 0.25px;
            line-height: 28px;
            margin-bottom: 0px;
        }

        &:after {
            content: ' ';
            display: block;
            position: absolute;
            vertical-align: middle;
            height: 3px;
            width: 60px;
            background-color: var(--primary);
            left: 0;
            bottom: -20px;
        }
    }

    .slick-slide {
        opacity: 0.5;
        transition: opacity 0.3s linear;
        margin-right: 1rem;

        display: flex !important;
        flex-direction: column;
        align-items: center;

        &.slick-active {
            opacity: 1;
        }

        .CatalogCard {
            padding: 0.5rem;

            img {
                height: 127px !important;
            }

            &__links {
                margin-top: 0.5rem;

                div.paragraph-2 {
                    @include font-size(14px);
                }
            }

            .text-m {
                display: none;
            }
        }
    }

    & .slick-next:before,
    & .slick-prev:before {
        font-size: 40px;
        color: var(--primary);
    }

    & .slick-prev {
        left: 8px;
        z-index: 9999999;
    }

    & .slick-next {
        right: 10px;
    }

    .ProStaffQuickView > .CatalogQuickView {
        @keyframes openQuick {
            from {
                height: 0;
            }

            to {
                height: 100%;
            }
        }

        transform: translateY(0);
        animation-name: openQuick;
        animation-duration: 0.3s;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;

        .ProductGallery {
            display: flex;
            justify-content: center;
        }
    }

    @include media-breakpoint-down(md) {
        .quote-box {
            width: 50%;
            float: right;
            margin-right: 1rem;

            &::before {
                left: -5px;
                font-size: 109px;
                top: -38px;
            }

            h2 {
                @include font-size(20px);
                line-height: 24px;
                margin-left: 40px;
                margin-bottom: 14px;
            }

            p {
                @include font-size(14px);
                letter-spacing: 0.29px;
                line-height: 17px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .prostaffPage {
        &__title {
            font-size: 62px;
        }
    }

    .prostaffQuoteBlock {
        .title {
            font-size: 62px;
        }
    }
}
