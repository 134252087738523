@import '../../../../../Common/src/scss/components/react/Stack.scss';

.Stack {
    &__divider {
        border-top-color: $gray-light;

        &--direction-row {
            border-left-color: $gray-light;
        }
    }
}
