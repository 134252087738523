﻿.CheckoutShipment {
    .ReadOnlyShipment {
        p {
            @extend .paragraph-2;
        }
    }

    .CheckoutSection__body .dropdown {
        .card-header {
            padding: 0px;
            background: transparent;

            .btn {
                @include font-size(16px);
                text-transform: none;
                font-weight: $font-weight-bolder;
                padding: 0.5rem 0.7rem;
            }

            button[aria-expanded='true'] {
                i {
                    transform: scaleY(-1);
                    -moz-transform: scaleY(-1);
                    -webkit-transform: scaleY(-1);
                    -ms-transform: scaleY(-1);
                    font-size: 1em;
                }
            }
        }

        .drawer {
            max-height: 100px;
            overflow-y: auto;

            .btn {
                text-transform: none;
            }
        }

        border: 2px solid var(--secondary);
    }
}
