﻿@import '../../../../Common/src/scss/components/_navigation_main.scss';

.navigation__main {
    margin-left: 6.25%;

    background-color: $white;

    .navigation__links {
        padding-left: 0;
        list-style-type: none;

        li {
            display: inline;
        }
    }
}

.navigation__panels {
    @include font-size(zebcoToBootstrapFontSize(1.6rem));

    .navigation-panels__overlay {
        z-index: $zindex-sticky;
        background-color: $white;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.19);
    }

    .navigation-panel__items {
        padding-left: 0;
        list-style-type: none;

        li {
            display: inline;
        }
    }

    .category-panel-featured-product {
        .category-panel-featured-product__title {
            font-weight: bold;
        }

        .category-panel-featured-product__image img {
            max-height: 180px;
            max-width: 180px;
        }
    }

    a {
        color: var(--secondary);
    }
}

.navigation__mobile-overlay {
    display: none !important;
    top: 0;
    left: 0;
    z-index: $zindex-fixed;
    color: var(--secondary);

    .collapse-mobile-nav-button-wrapper {
        left: 0;
        top: 0;

        .collapse-mobile-nav-button {
            border: none;
            background-color: transparent;
        }
    }

    .navigation__mobile-logo {
        max-width: 102.5px;
        height: auto;
    }

    &.show {
        display: flex !important;
    }

    .navigation__mobile-overlay-content {
        background-color: $white;
        height: 100%;
    }

    .navigation__mobile-overlay-backdrop {
        background-color: $gray-dark;
        opacity: 0.5;
        width: 54px;
    }

    .navigation__mobile-link-list {
        list-style-type: none;
        padding-left: 0;

        .navigation__mobile-link-list__item {
            border-top: 1px solid $gray-light;

            a {
                display: block;
                width: 100%;
                text-align: center;
                @include font-size(zebcoToBootstrapFontSize(1.6rem));
                color: var(--secondary);
            }
            @media (hover: hover) {
                &:hover {
                    text-decoration: none !important;
                }
            }

            .navigation__mobile-link-list__item__toggle {
                top: 0;
                right: 0;

                a {
                    color: var(--primary);
                }
            }
        }
    }

    .navigation__mobile-category {
        display: none;

        &.show {
            display: block;
        }

        .navigation__mobile-category-item-list {
            list-style-type: none;
            padding-left: 0;
            border-top: 1px solid $gray-light;

            .navigation__mobile-category-item-list__item {
                a {
                    display: block;
                    width: 100%;
                    color: var(--secondary);
                }
            }
        }
    }

    .navigation__mobile-bottom-links {
        .navigation__mobile-bottom-link-item {
            border-top: 1px solid $gray-light;
        }
    }

    .mobile-cart-button {
        border-left: 1px solid $gray-light;
    }
}
